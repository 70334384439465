import React, { useEffect, useState } from 'react'
import { useData } from '../InvoiceView/DataProvider'
import { Checkbox } from '@toasttab/buffet-pui-checkbox'
import { useInvoice } from '../InvoiceView/InvoiceProvider'
import { NumberInput } from '@toasttab/buffet-pui-text-input'
import { EnrollmentRequest } from '../../types/DataProviderTypes'
import { SparkleIcon } from '@toasttab/buffet-pui-icons'
import { Alert } from '@toasttab/buffet-pui-alerts'

export default function LoyaltyEnrollment(props: {
  setLoyaltyValid: React.Dispatch<React.SetStateAction<boolean>>
  setLoyaltyEnrollmentRequest: React.Dispatch<
    React.SetStateAction<EnrollmentRequest | undefined>
  >
}) {
  const { setLoyaltyValid, setLoyaltyEnrollmentRequest } = props
  const { invoiceSettings } = useData()
  const loyaltySettings = invoiceSettings?.loyaltySettings

  const { invoice } = useInvoice()

  const [enrollInLoyalty, setEnrollInLoyalty] = useState<boolean>(false)
  const [signupKey, setSignupKey] = useState<string | undefined>(undefined)

  const canEnroll =
    loyaltySettings &&
    !loyaltySettings?.enrolled &&
    loyaltySettings.canEnroll &&
    // if an order gets created and then a loyalty account gets disabled, we cannot attach a new loyalty account to the order
    !invoice.order.checks[0].appliedLoyaltyInfo

  const customer = invoice.customer
  const needsPhone =
    canEnroll &&
    enrollInLoyalty &&
    loyaltySettings!!.signUpMethod === 'PHONE_NUMBER' &&
    (!customer.phoneNumber || customer.phoneNumber.trim().length === 0)

  useEffect(() => {
    if (canEnroll) {
      if (loyaltySettings.signUpMethod === 'PHONE_NUMBER') {
        setSignupKey(customer.phoneNumber)
      } else {
        setSignupKey(customer.email)
      }
    }
  }, [
    loyaltySettings,
    needsPhone,
    customer.phoneNumber,
    customer.email,
    canEnroll
  ])

  useEffect(() => {
    if (needsPhone) {
      if (!signupKey || signupKey.length < 10) {
        setLoyaltyValid(false)
      } else {
        setLoyaltyValid(true)
      }
    } else {
      setLoyaltyValid(true)
    }
  }, [needsPhone, setLoyaltyValid, signupKey])

  useEffect(() => {
    if (enrollInLoyalty && signupKey) {
      setLoyaltyEnrollmentRequest({
        customerGuid: invoice.customerGuid,
        orderGuid: invoice.order.guid,
        signupMethod: loyaltySettings!!.signUpMethod,
        signupKey: signupKey!!
      })
    }
  }, [
    enrollInLoyalty,
    signupKey,
    canEnroll,
    setLoyaltyEnrollmentRequest,
    invoice.customerGuid,
    invoice.order.guid,
    loyaltySettings
  ])

  if (loyaltySettings?.enrolled) {
    return (
      <div className={'mb-5'}>
        <Alert className={'w-full'} showIcon={false}>
          <SparkleIcon size={'sm'} />
          <span className={'ml-2'}>
            You will receive loyalty points for this order!
          </span>
        </Alert>
      </div>
    )
  }

  if (!canEnroll) {
    return <></>
  }

  return (
    <div className={'mb-5'}>
      <div className={'ml-2'}>
        <Checkbox
          label={'Enroll in loyalty program to start earning rewards'}
          checked={enrollInLoyalty}
          onChange={() => {
            setEnrollInLoyalty(!enrollInLoyalty)
          }}
        ></Checkbox>
      </div>
      {enrollInLoyalty && needsPhone && (
        <>
          <NumberInput
            format='(###) ###-####'
            allowEmptyFormatting
            mask='_'
            label='Phone number*'
            onChange={(values) => {
              setSignupKey(values.value)
            }}
            value={signupKey}
          />
        </>
      )}
    </div>
  )
}
