import React from 'react'
import LabelItem, { LabelItemAlign } from '../LabelItem/LabelItem'
import { Skeleton } from '@toasttab/buffet-pui-loading-indicators'
import InvoiceTipInput from './InvoiceTipInput'
import { useInvoice } from './InvoiceProvider'
import { useData } from './DataProvider'
import { taxIdentificationNames } from '../../utils/utils'
import type { I18n } from '../../types/RestaurantTypes'
import { useBuffetContext } from '@toasttab/buffet-pui-context-provider'
import { formatCurrency } from '@toasttab/buffet-pui-number-utilities'
import { format, Formats, parseISO } from '@toasttab/buffet-pui-date-utilities'

export default function InvoiceOverview() {
  const {
    invoice,
    paymentAmount,
    invoiceOrder,
    isDeposit,
    isPaid,
    isVoid
  } = useInvoice()
  const { previousPayments } = useData()

  const { currency, locale } = useBuffetContext()
  const { invoiceSettings, restaurant } = useData()

  if (isPaid || isVoid) {
    return null
  }

  const { country } = restaurant.i18n ? restaurant.i18n : ({} as I18n)
  const { taxIdentificationNumber } = invoiceSettings

  const taxIdentificationName = taxIdentificationNames[country]

  const dueDate = parseISO(invoiceOrder?.dueDate || invoice.paymentDueDate)
  const sentDate = parseISO(invoiceOrder?.sentDate || invoice.sentDate)

  const totalAmountEl = (
    <LabelItem label={'Amount Due'} align={LabelItemAlign.Right}>
      {paymentAmount ? (
        <span className={'type-headline-4 sm:type-headline-1'}>
          {formatCurrency({ amount: paymentAmount, currency }, locale)}
        </span>
      ) : (
        <Skeleton className={'mt-1'} height={'32px'} width={'180px'} />
      )}
    </LabelItem>
  )

  return (
    <>
      <div className={'px-8 py-6 bg-gray-0 flex'}>
        <div className={'w-1/2 space-y-3'}>
          <LabelItem label={'Billed To'}>
            {`${invoice.customer.firstName} ${invoice.customer.lastName}`}
          </LabelItem>
          <LabelItem label={'Invoice Number'}>
            {invoice.invoiceNumber}
          </LabelItem>
          {taxIdentificationNumber && (
            <LabelItem label={taxIdentificationName}>
              {taxIdentificationNumber}
            </LabelItem>
          )}
          {sentDate && (
            <LabelItem label={'Invoice Date'}>
              {format(sentDate, Formats.date.short, locale)}
            </LabelItem>
          )}
        </div>
        <div className={'w-1/2 space-y-3 flex flex-col justify-between'}>
          {dueDate && (
            <LabelItem
              label={
                isDeposit && !previousPayments.length
                  ? 'Deposit Due By'
                  : 'Due By'
              }
              align={LabelItemAlign.Right}
            >
              {format(dueDate, Formats.date.short, locale)}
            </LabelItem>
          )}
          {(!invoice.paymentDetails?.allowTipping || isDeposit) &&
            totalAmountEl}
        </div>
      </div>
      {invoice.paymentDetails?.allowTipping && !isDeposit && (
        <div className={'px-8 py-6 bg-gray-0'}>
          <h3 className={'type-large mb-3'}>Tip</h3>
          <InvoiceTipInput />
          {totalAmountEl}
        </div>
      )}
    </>
  )
}
