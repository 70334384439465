import React from 'react'
import { formatPhone } from '@toasttab/buffet-pui-phone-utilities'
import { useData } from './DataProvider'
import { printAddressBranded } from '../../utils/utils'

export default function InvoiceRxInfo() {
  const { restaurantBranding, restaurant } = useData()
  const country = restaurant.i18n ? restaurant.i18n.country : 'US'

  return (
    <div className={'px-8 py-6'}>
      {restaurantBranding.logo && (
        <img
          className={'block mb-4'}
          style={{ maxWidth: '180px', maxHeight: '180px' }}
          src={restaurantBranding.logo}
          alt={restaurantBranding.name}
        />
      )}
      <h2 className={'type-headline-4 mb-2'}>{restaurantBranding.name}</h2>
      <p className={'type-default text-secondary'}>
        {printAddressBranded({
          restaurantBranding: { ...restaurantBranding, country },
          singleLine: false
        })}
        <br />
        {formatPhone(restaurantBranding.phone, country)}
      </p>
    </div>
  )
}
