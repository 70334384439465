export const getRestaurantQuery = () => `
  query getRestaurantQuery($restaurantId: ID!) {
    restaurantV2(guid: $restaurantId) {
      ...on Restaurant {
        guid
        shortUrl
        name: whiteLabelName
        logoUrls {
          xs
        }
        creditCardConfig {
          amexAccepted
        }
        location {
          address1
          address2
          city
          state
          zip
          phone
        }
        i18n {
          country
          currency
          locale
        }
        timeZoneId
      }
    }
  }
`

export const getPayForCheckMutation = () => `
  mutation payForCheck($input: PayForCheckInput!) {
    payForCheck(input: $input) {
      __typename
      ...on PayForCheckResponse {
        remainingChecks {
          guid
        }
      }
      ...on PayForCheckError {
        code
        message
      }
    }
  }
`
