import React, { useMemo } from 'react'
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary'
import { useEvent } from './EventProvider'
import { FormFieldType } from '../../types/EventTypes'
import { useEventType } from '../../api/invoices'

interface TableEntries {
  key: string
  label: string
  value?: string
}

interface EventFormFieldsTable {
  showEventType?: boolean
}

const EventFormFieldsTable: React.FC<EventFormFieldsTable> = ({
  showEventType
}) => {
  const { event } = useEvent()
  const { eventType } = useEventType(event.restaurantGuid, event.eventTypeGuid)

  const formEntries = useMemo(() => {
    return event.formValues
      .filter(({ formField: { type } }) => type !== FormFieldType.EMPLOYEE)
      .map(({ guid, formField: { label }, value }) => {
        // some fields contain JSON arrays, so try to parse else fall back to string value
        try {
          value = JSON.parse(value)?.join(', ')
          // eslint-disable-next-line no-empty
        } catch (e: any) {}

        return {
          key: `field_${guid}`,
          label: label ?? 'Unnamed Menu Item',
          value
        }
      })
  }, [event.formValues])

  const entries = useMemo<TableEntries[]>(() => {
    return showEventType && eventType && eventType.name
      ? [
          {
            key: `event_${eventType.guid}_name`,
            label: 'Type of event',
            value: eventType.name
          },
          ...formEntries
        ]
      : formEntries
  }, [formEntries, showEventType, eventType])

  if (!entries.length || (showEventType && !eventType))
    return <div className={'px-4 md:px-8 pb-8 mt-4'} />

  return (
    <div className={'px-4 md:px-8 pb-8 mt-4'}>
      <ErrorBoundary
        fallbackMessage={
          'Sorry, we are unable to load the order summary for this estimate.'
        }
      >
        <table className={'w-full border-collapse border-0'}>
          <tbody>
            {entries.map(({ key, label, value }, i) => {
              return (
                <tr
                  key={key}
                  className={`border-b border-DEFAULT border-color-ds ${
                    i === 0 ? 'border-t' : ''
                  }`}
                >
                  <td
                    style={{ lineHeight: 2 }}
                    className={
                      'w-48 align-top py-2 pr-2 type-overline text-secondary font-700'
                    }
                  >
                    {label}
                  </td>
                  <td className={'align-top py-2 pr-2 type-default'}>
                    {value ? (
                      <div dangerouslySetInnerHTML={{ __html: value }} />
                    ) : (
                      'N/A'
                    )}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </ErrorBoundary>
    </div>
  )
}

export default EventFormFieldsTable
