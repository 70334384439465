import { useEffect, useState } from 'react'
import { apiGet, formatQueryParams } from './util'
import {
  DeliveryInfo,
  DiningOptionBehavior,
  FulfillmentQueryParams,
  Location
} from '../types/CateringLocationTypes'
import { Address } from '@toasttab/buffet-patterns-address-lookup'

const cateringApiGet = (path: string, headers = {}) => {
  return apiGet(`catering/v1${path}`, headers)
}

const getCateringOOLocations = async (
  mgmtGuid: string,
  params?: {
    latitude?: number
    longitude?: number
  }
): Promise<{
  locations: Location[]
}> => {
  const res = await cateringApiGet(
    `/public/locations${params ? formatQueryParams(params) : ''}`,
    {
      'Toast-Management-Set-Guid': mgmtGuid
    }
  )
  return res
}

const addressLookupToDeliveryInfo = (addressInput?: Address): DeliveryInfo => {
  const deliveryAddress = {
    ...addressInput,
    state: addressInput?.stateCode
  }
  delete deliveryAddress.stateCode
  delete deliveryAddress.country
  return deliveryAddress
}

export const navigateToCateringOO = (
  shortUrl: string,
  diningOptionBehavior: DiningOptionBehavior,
  address?: Address
) => {
  const fulfillmentInfo: FulfillmentQueryParams = {
    mode: 'fulfillment',
    behavior: diningOptionBehavior,
    ...addressLookupToDeliveryInfo(address)
  }

  window.location.assign(
    `${window.location.origin}/catering/${encodeURIComponent(
      shortUrl
    )}/${formatQueryParams(fulfillmentInfo)}`
  )
}

export const useCateringOOLocations = (
  mgmtGuid: string | undefined,
  address: Address | undefined,
  enabled: boolean
) => {
  const [locations, setLocations] = useState<Location[] | undefined>(undefined)
  const [error, setError] = useState<Error | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!mgmtGuid || !enabled) return
    setIsLoading(true)
    setError(undefined)
    getCateringOOLocations(mgmtGuid, {
      latitude: address?.latitude,
      longitude: address?.longitude
    })
      .then((response) => setLocations(response.locations))
      .catch((err) => {
        setLocations(undefined)
        setError(err)
      })
      .finally(() => setIsLoading(false))
  }, [mgmtGuid, address])

  return { locations, isLoading, error }
}
