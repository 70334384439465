import { TextInput } from '@toasttab/buffet-pui-text-input'
import { Textarea } from '@toasttab/buffet-pui-text-area'
import { Select, SelectMultiple } from '@toasttab/buffet-pui-select'
import React from 'react'
import { TimePicker } from '@toasttab/buffet-pui-time-picker'
import { RadioGroup } from '@toasttab/buffet-pui-radio-group'
import { TimePoint } from '@toasttab/buffet-pui-date-utilities'
import { FormFieldType } from '../../types/EventTypes'

interface FormFieldProps {
  type: FormFieldType
  guids: string[]
  onChange: (value: string, guids: string[]) => void
  value: string | undefined
  options?: string[]
  label: string
  canSelectMultiple?: boolean
  requiredOnLead?: boolean
}

export const stringToTimePoint = (time?: string): TimePoint => {
  if (!time) {
    return {
      hours: 0,
      minutes: '00',
      isPm: false
    }
  }
  const parts = time.split(':')

  return {
    hours: parseInt(parts[0], 10) || 12,
    minutes: parts[1].substring(0, 2) || '00',
    isPm: parts[1].substring(2) === 'PM'
  }
}

export const RenderFormField = ({
  type,
  guids,
  onChange,
  value,
  options,
  label,
  requiredOnLead
}: FormFieldProps) => {
  switch (type) {
    case FormFieldType.GUEST_COUNT:
      return (
        <div>
          <TextInput
            label={label}
            value={value || ''}
            type='number'
            onChange={(event) => onChange(event.target.value, guids)}
            required={requiredOnLead}
          />
        </div>
      )
    case FormFieldType.TEXT:
      return (
        <div key={guids[0]}>
          <TextInput
            label={label}
            value={value}
            onChange={(event) => onChange(event.target.value, guids)}
            required={requiredOnLead}
          />
        </div>
      )
    case FormFieldType.TEXT_AREA:
      return (
        <div key={guids[0]}>
          <Textarea
            style={{ height: '96px' }}
            placeholder='Type something'
            label={label}
            value={value}
            onChange={(event) => {
              onChange(event.target.value, guids)
            }}
            required={requiredOnLead}
          />
        </div>
      )
    case FormFieldType.YES_NO:
      return (
        <div key={guids[0]}>
          <RadioGroup
            label={label}
            options={[
              {
                checked: value === 'Yes',
                label: 'Yes',
                value: 'Yes'
              },
              {
                checked: value === 'No',
                label: 'No',
                value: 'No'
              }
            ]}
            onChange={(event) => {
              onChange(event.target.value, guids)
            }}
            required={requiredOnLead}
          />
        </div>
      )
    case FormFieldType.SELECT:
      if (!options) {
        return null
      }

      return (
        <div style={{ width: '300px' }} key={guids[0]}>
          <Select
            label={label}
            onChange={(value) => {
              onChange(value, guids)
            }}
            options={options}
            value={value}
            required={requiredOnLead}
          />
        </div>
      )
    case FormFieldType.TIME:
      return (
        <div style={{ width: '300px' }} key={guids[0]}>
          <TimePicker
            required={requiredOnLead}
            label={label}
            onChange={(newTime: TimePoint) => {
              if (!newTime.hours) {
                onChange('', guids)
              } else {
                onChange(
                  `${newTime.hours}:${newTime.minutes || '00'}${
                    newTime.isPm ? 'pm' : 'am'
                  }`,
                  guids
                )
              }
            }}
            value={value ? stringToTimePoint(value) : undefined}
          />
        </div>
      )
    case FormFieldType.MULTI_SELECT:
      if (!options) return null
      return (
        <div>
          <SelectMultiple
            label={label}
            onChange={(value) => {
              onChange(JSON.stringify(value), guids)
            }}
            options={options}
            placeholder={'Select multiple'}
            value={JSON.parse(value || '[]') || []}
            required={requiredOnLead}
          />
        </div>
      )
    default:
      return null
  }
}
