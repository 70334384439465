import React from 'react'
import { BodyBackgroundColor } from '../BodyBackgroundColor'

export interface ThemedLayoutProps {
  logoImageUrl?: string
  name?: string
  headerImageUrl?: string
  subheader?: React.ReactNode
}

export const ThemedLayout: React.FC<
  React.PropsWithChildren<ThemedLayoutProps>
> = ({ logoImageUrl, name, headerImageUrl, subheader, children }) => {
  return (
    <div className={'w-full flex flex-col gap-6'}>
      <BodyBackgroundColor colorVar={'ds-surface-default'} />
      <header className={'min-h-[60px] flex flex-col justify-center'}>
        {logoImageUrl && (
          <img
            className={'block'}
            style={{ maxWidth: '180px', maxHeight: '180px' }}
            src={logoImageUrl}
            alt={name}
          />
        )}
        {subheader && <div className={'max-w-6xl'}>{subheader}</div>}
      </header>
      <div className={'max-w-6xl flex flex-col gap-6 mx-auto'}>
        {headerImageUrl && (
          <div
            className='h-56 md:h-80 transition-[height,margin] bg-cover bg-center md:rounded-md -mx-8 md:mx-0'
            style={{
              backgroundImage: `url("${headerImageUrl}")`
            }}
          ></div>
        )}
        <div
          className={'flex flex-col md:flex-row gap-x-14 gap-y-4 md:gap-y-6'}
        >
          {children}
        </div>
      </div>
    </div>
  )
}
