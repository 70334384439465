import { LoyaltySettings } from './DataProviderTypes'
import { Address } from './CateringLocationTypes'
import { SiteTheme } from '@toasttab/sites-components'

export interface InvoiceResponse {
  status: 'VALID' | 'INVALID' | 'EXPIRED'
  invoice?: Invoice
  menuItemMap?: MenuItemMap
  invoiceSettings?: PublicSettings
  restaurantBranding?: RestaurantBranding
  availableRedemptions: AvailableRedemption[]
  applicableDiscounts: ApplicableDiscount[]
  discounts: DiscountsEntity[]
  isTestMode: boolean
}

export interface AvailableRedemption {
  availability: string
  itemId: string
  name: string
  percent: any
  quantity: number
  referenceId: string
  unit: string
  integrationData?: IntegrationData
}

export interface IntegrationData {
  amount: number
  selectionType: SelectionType
  appliedDiscountGuid: string
}

export enum SelectionType {
  ITEM = 'ITEM',
  CHECK = 'CHECK',
  MULTI_ITEM = 'MULTI_ITEM' // not supporting for now
}

export interface ApplicableDiscount {
  discount: ExternalReference
  applicableChecks: ExternalReference[]
  applicableSelections: ExternalReference[]
}

export interface ExternalReference {
  guid: string
  externalId?: string
  entityType?: string
}

export enum InvoiceOrdersType {
  /* eslint-disable no-unused-vars */
  DEPOSIT = 'DEPOSIT',
  BALANCE = 'BALANCE'
  /* eslint-enable no-unused-vars */
}

export enum InvoiceOrdersStatus {
  /* eslint-disable no-unused-vars */
  DRAFT = 'DRAFT',
  OPEN = 'OPEN',
  PAID = 'PAID',
  VOID = 'VOID',
  DELETED = 'DELETED'
  /* eslint-enable no-unused-vars */
}

export interface InvoiceOrdersEntity {
  guid?: string
  invoiceGuid?: string
  orderGuid?: string
  type: InvoiceOrdersType
  status?: InvoiceOrdersStatus
  amount?: number
  dueDate: string
  sentDate?: string
  paidDate?: string
  order: Order
}

export interface Invoice {
  invoiceGuid: string
  restaurantGuid: string
  order: Order
  status: string
  createdDate: string
  modifiedDate: string
  sentDate: string
  customer: Customer
  customerGuid: string
  paymentDueDate: string
  paymentDetails: PaymentDetails
  invoiceNumber: string
  paidDate?: string
  message?: null
  pastDue: boolean
  invoiceOrders: InvoiceOrdersEntity[]
  hideUnitInfo: boolean
  paymentSchedule: PaymentScheduleItem[]
  requestCardOnFile: boolean
  requireCardOnFile: boolean
}

export enum PaymentScheduleItemStatus {
  DRAFT = 'DRAFT',
  SCHEDULED = 'SCHEDULED',
  SENT = 'SENT',
  PAST_DUE = 'PAST_DUE',
  PAID = 'PAID',
  VOID = 'VOID',
  PARTIALLY_REFUNDED = 'PARTIALLY_REFUNDED',
  REFUNDED = 'REFUNDED',
  INVALID = 'INVALID'
}

export const enum GuestOrderStatus {
  ALMOST_READY = 'ALMOST_READY',
  APPROVED = 'APPROVED',
  AUTO_CLOSED = 'AUTO_CLOSED',
  CLOSED = 'CLOSED',
  FUTURE = 'FUTURE',
  IN_PREPARATION = 'IN_PREPARATION',
  READY_FOR_PICKUP = 'READY_FOR_PICKUP',
  RECEIVED = 'RECEIVED',
  UNFULFILLED = 'UNFULFILLED',
  VOIDED = 'VOIDED'
}

export interface PaymentScheduleItem {
  createdDate: string
  dueDate: string | null
  guid: string
  invoiceGuid: string
  invoiceOrderGuid: string
  firstSentDate: string | null
  lastSentDate: string | null
  modifiedDate: string
  payment: PaymentEntity | null
  requestedAmount: number | null
  sendDate: string | null
  shouldAutoSend: boolean
  amount: number
  date: string
  status: PaymentScheduleItemStatus
}

export interface Order {
  guid: string
  entityType: string
  externalId?: null
  revenueCenter?: null
  server?: null
  lastModifiedDevice: LastModifiedDeviceOrCreatedDevice
  source: string
  guestOrderStatus?: GuestOrderStatus | null
  voidDate?: string
  duration?: null
  businessDate: string
  paidDate?: string
  restaurantService?: null
  voided: boolean
  estimatedFulfillmentDate: string
  table?: null
  requiredPrepTime: string
  approvalStatus: string
  deliveryInfo?: null
  serviceArea?: null
  curbsidePickupInfo?: null
  numberOfGuests: number
  diningOption: DiningOption
  marketplaceFacilitatorTaxInfo?: null
  initialDate?: string
  deliveryServiceInfo?: null
  openedDate: string
  voidBusinessDate?: null
  checks: ChecksEntity[]
  deleted: boolean
  createdDevice: LastModifiedDeviceOrCreatedDevice
  createdDate: number
  closedDate?: null
  deletedDate?: null
  modifiedDate: number
  promisedDate: number
  pricingFeatures?: null[] | null
}

export interface LastModifiedDeviceOrCreatedDevice {
  id?: null
}

export interface DiningOption {
  guid: string
  entityType: string
  externalId?: null
}

export interface PaymentEntity {
  amount: number
  amountTendered?: number
  cardEntryMode?: string
  cardHolderFirstName?: string
  cardHolderLastName?: string
  cardPaymentId?: string
  cardType?: string
  cashDrawer?: string
  checkGuid: string
  entityType: string
  guid: string
  orderGuid: string
  paidDate: string
  otherPayment?: {
    guid: string
    externalId?: string
    entityType?: string
  }
  paymentStatus: PaymentStatus
  receiptToken: string
  refundStatus: string
  tipAmount: number
  type: string
  voidInfo?: string
  refund?: {
    refundAmount: number
    tipRefundAmount: number
    refundDate: string
    refundBusinessDate: number
    refundTransaction: ExternalReference
  }
}

export enum PaymentStatus {
  OPEN = 'OPEN',
  PROCESSING = 'PROCESSING',
  AUTHORIZED_AT_RISK = 'AUTHORIZED_AT_RISK',
  AUTHORIZED = 'AUTHORIZED',
  ERROR = 'ERROR',
  ERROR_NETWORK = 'ERROR_NETWORK',
  DENIED = 'DENIED',
  PROCESSING_VOID = 'PROCESSING_VOID',
  VOIDED_AT_RISK = 'VOIDED_AT_RISK',
  CANCELLED = 'CANCELLED',
  CAPTURE_IN_PROGRESS = 'CAPTURE_IN_PROGRESS',
  CAPTURED = 'CAPTURED',
  VOIDED = 'VOIDED'
}

interface AppliedLoyaltyInfo {
  guid: string
}

export interface ChecksEntity {
  guid: string
  entityType: string
  externalId?: null
  displayNumber: string
  payments?: PaymentEntity[] | null
  appliedDiscounts: AppliedDiscount[]
  lastModifiedDevice: LastModifiedDeviceOrCreatedDevice
  voidDate?: null
  appliedPreauthInfo?: null
  paidDate?: null
  appliedLoyaltyInfo?: AppliedLoyaltyInfo
  voided: boolean
  paymentStatus: string
  amount: number
  tipAmount: number
  tabName?: null
  taxExempt: boolean
  openedDate: number
  amountDue: number | null
  totalAmount: number
  selections: SelectionsEntity[]
  voidBusinessDate?: null
  createdDate: number
  deleted: boolean
  createdDevice: LastModifiedDeviceOrCreatedDevice
  closedDate?: null
  deletedDate?: null
  modifiedDate: number
  taxAmount: number
  appliedServiceCharges?: ServiceChargeEntity[]
  customer?: null
}

export enum DiscountSelectionType {
  /* eslint-disable no-unused-vars */
  CHECK = 'CHECK',
  ITEM = 'ITEM',
  BOGO = 'BOGO'
  /* eslint-enable no-unused-vars */
}

export enum DiscountAmountType {
  /* eslint-disable no-unused-vars */
  PERCENT = 'PERCENT',
  FIXED = 'FIXED',
  OPEN_PERCENT = 'OPEN_PERCENT',
  OPEN_FIXED = 'OPEN_FIXED',
  BOGO = 'BOGO',
  FIXED_TOTAL = 'FIXED_TOTAL'
  /* eslint-enable no-unused-vars */
}

export interface AppliedDiscount {
  id?: string
  guid: string
  entityType?: string
  createdDate?: string
  modifiedDate?: string
  deleted?: boolean
  applyVersion?: number
  name: string
  shortName?: null
  active?: boolean
  autoApply?: boolean
  selectionType?: DiscountSelectionType
  amountType?: DiscountAmountType
  discountPercent?: number | null
  discountAmount?: number | null
  fixedTotal?: null
  itemPickingPriority?: string
  taxApplicationStrategy?: string
  permissionRequired?: number
  nonExclusive?: boolean
  locked?: boolean
  color?: number
  rewardRequiresManager?: boolean
  reasonShouldPrompt?: boolean
  promoCodes?: null[] | null
  discountExclusions?: null[] | null
  writeAccessible?: boolean
  discountType?: DiscountAmountType
  discount: ExternalReference
  loyaltyDetails?: LoyaltyDetails | null
  processingState?: DiscountProcessingState | null
}

export type DiscountProcessingState =
  | 'PENDING_APPLIED'
  | 'APPLIED'
  | 'VOID'
  | 'PENDING_VOID'

export interface ServiceChargeEntity {
  name: string
  chargeAmount: number
  taxable: boolean
  guid: string
  gratuity: boolean
  appliedTaxes: AppliedTaxesEntity[]
}

export interface SelectionsEntity {
  guid: string
  entityType: string
  externalId?: null
  deferred: boolean
  preDiscountPrice: number
  voidReason?: null
  optionGroup?: null
  displayName: string
  appliedDiscounts?: AppliedDiscount[]
  externalPriceAmount?: null
  modifiers: SelectionsEntity[]
  seatNumber: number
  voidDate?: null
  fulfillmentStatus: string
  optionGroupPricingMode?: null
  salesCategory?: null
  selectionType: string
  price: number
  voided: boolean
  appliedTaxes?: (AppliedTaxesEntity | null)[] | null
  itemGroup?: OptionGroupOrItemOrItemGroup
  item?: OptionGroupOrItemOrItemGroup
  taxInclusion: string
  quantity: number
  receiptLinePrice: number
  unitOfMeasure: string
  refundDetails?: RefundDetails
  tax: number
  diningOption: DiningOption
  openPriceAmount?: null
  voidBusinessDate?: null
  createdDate: number
  preModifier?: null
  modifiedDate: number
}

export interface RefundDetails {
  refundAmount: number
  taxRefundAmount: number
  refundTransaction: ExternalReference
}

export interface OptionGroupOrItemOrItemGroup {
  guid: string
  entityType: string
  externalId?: null
  multiLocationId: string
}

export interface AppliedTaxesEntity {
  guid: string
  entityType: string
  taxRate: TaxRate
  rate: number
  name: string
  taxAmount: number
  type: string
  facilitatorCollectAndRemitTax: boolean
}

export interface TaxRate {
  guid: string
  entityType: string
}

export interface Customer {
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
}

export interface PaymentDetails {
  paymentTypes: string[]
  invoiceSendOption: string
  allowTipping: boolean
}

// This is a stripped-down version of MenuItemV2 from the menu API
export interface MenuItem {
  name: string
  guid: string
  masterId?: number
  description?: string
  image?: string
}

export type MenuItemMap = {
  [key: string]: MenuItem
}

//Only get required Invoice Settings
export interface PublicSettings {
  tipPercentages?: string[]
  defaultTipPercentage: string
  loyaltySettings?: LoyaltySettings
  taxIdentificationNumber?: string
}

export interface RestaurantBranding extends Address {
  guid: string
  locationName: string
  name: string
  logo?: string
  shortURL: string
  phone: string
  backgroundColor?: string
  primaryColor?: string
  textColor?: string
  fontFamily?: { name: string; url?: string } | string
  headerFontFamily?: { name: string; url?: string } | string
  cateringBrandingEnabled: boolean
  removeCateringName: boolean
  // When our lead pages are embedded in Sites, they always need to use Site's brand info.
  // So these are being passed through regardless of if the Rx has branding turned on
  sitesPrimaryColor?: string
  sitesTextColor?: string
  sitesBackgroundColor?: string
  sitesFontFamily?: { name: string; url?: string } | string
  sitesHeaderFontFamily?: { name: string; url?: string } | string
  theme?: SiteTheme
}

export interface LoyaltyDetails {
  vendor?: string
  referenceId?: string
}

export interface DiscountsEntity {
  active: boolean
  discountAmount?: number | null
  entityType: string
  fixedTotal: any
  guid: string
  itemPickingPriority: string
  name: string
  nonExclusive: boolean
  discountPercent?: number | null
  selectionType: DiscountSelectionType
  type: DiscountAmountType
}
