import React from 'react'
import Main from '../Main/Main'

export default function NotFoundView() {
  return (
    <Main>
      <div className={'p-8'}>
        <h1 className={'type-headline-4'}>Invoice not found</h1>
      </div>
    </Main>
  )
}
