import React from 'react'
import { useBuffetContext } from '@toasttab/buffet-pui-context-provider'
import { formatCurrency } from '@toasttab/buffet-pui-number-utilities'
import InvoiceTotalRow from './InvoiceTotalRow'
import { useData } from '../InvoiceView/DataProvider'

export default function ServiceChargeTotalRows(props: {
  taxable: boolean
  gratuity: boolean
}) {
  const { check } = useData()
  const { currency, locale } = useBuffetContext()
  const { taxable, gratuity } = props
  const serviceCharges = check?.appliedServiceCharges

  if (!serviceCharges) return null

  return (
    <>
      {serviceCharges
        .filter((serviceCharge) => {
          return (
            serviceCharge.taxable === taxable &&
            serviceCharge.gratuity === gratuity
          )
        })
        .map((serviceCharge, i) => (
          <InvoiceTotalRow
            key={'serviceCharge-' + i}
            label={serviceCharge.name}
            value={formatCurrency(
              { amount: serviceCharge.chargeAmount, currency },
              locale
            )}
          />
        ))}
    </>
  )
}
