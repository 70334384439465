import React from 'react'

export default function InvoiceTipButton(props: {
  title: string
  subtitle?: string
  selected?: boolean
  onClick: React.MouseEventHandler<HTMLButtonElement>
}) {
  const { title, subtitle, selected = false, onClick } = props
  return (
    <li className={'flex-1 h-full'} role='option' aria-selected={selected}>
      <button
        className={`w-full h-16 ${
          selected ? 'border-primary-100' : 'border-gray-30'
        } border-solid border-2 rounded`}
        onClick={onClick}
      >
        <span className={'block type-default font-semibold'}>{title}</span>
        {subtitle && (
          <span className={'block type-caption text-secondary'}>
            {subtitle}
          </span>
        )}
      </button>
    </li>
  )
}
