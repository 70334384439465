import React from 'react'
import classNames from 'classnames'

export interface CardProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  brandingEnabled?: boolean
  shadowDisabled?: boolean
}

export const Card: React.FC<CardProps> = ({
  className,
  children,
  brandingEnabled,
  shadowDisabled,
  ...containerProps
}) => (
  <div
    {...containerProps}
    className={classNames(
      `rounded-md overflow-hidden`,
      {
        'text-color-ds bg-color-ds-surface-secondary': brandingEnabled,
        'bg-white': !brandingEnabled,
        'shadow-xl': !shadowDisabled
      },
      className
    )}
  >
    {children}
  </div>
)
