import React from 'react'
import LabelItem from '../LabelItem/LabelItem'
import { Skeleton } from '@toasttab/buffet-pui-loading-indicators'

export default function EventLoadingView() {
  const defaultSkeleton = (
    <Skeleton className={'mt-1'} height={'16px'} width={'180px'} />
  )

  return (
    <div className={'bg-white rounded'}>
      <div className={'px-8 py-6'}>
        <Skeleton className={'mb-6'} width={'80px'} height={'80px'} />
        <Skeleton className={'mb-3'} width={'180px'} height={'28px'} />
        <Skeleton width={'320px'} height={'40px'} />
      </div>

      <div className={'px-8 space-y-6 py-6 bg-gray-0'}>
        <div>
          <div className={'space-x-8 flex'}>
            <LabelItem label={'Estimate For'}>{defaultSkeleton}</LabelItem>
            <LabelItem label={'Order Date'}>{defaultSkeleton}</LabelItem>
          </div>
          <Skeleton className={'mt-6'} width={'320px'} height={'40px'} />
        </div>
      </div>

      <div className={'px-8 space-y-4 py-6'}>
        <Skeleton height={'16px'} width={'180px'} />
        <Skeleton height={'16px'} width={'180px'} />
        <Skeleton height={'16px'} width={'180px'} />
      </div>
    </div>
  )
}
