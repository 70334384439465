import { DiscountAmountType, AppliedDiscount } from '../../types/InvoiceTypes'

export function computeDiscountAmount(
  total: number,
  discount: AppliedDiscount | null | undefined
): number {
  if (!discount) return 0
  if (
    discount.amountType === DiscountAmountType.PERCENT ||
    discount.amountType === DiscountAmountType.OPEN_PERCENT
  ) {
    return total * (discount.discountPercent!! / 100)
  } else {
    return discount.discountAmount!!
  }
}

export function getDiscountLabel(discount: AppliedDiscount) {
  // @ts-ignore
  const type = discount.amountType || discount.discountType
  return type === DiscountAmountType.PERCENT
    ? `${discount.name} (${discount.discountPercent}%)`
    : discount.name
}
