import React from 'react'
import { useBuffetContext } from '@toasttab/buffet-pui-context-provider'
import {
  formatCurrency,
  formatPercent
} from '@toasttab/buffet-pui-number-utilities'
import { useData } from '../InvoiceView/DataProvider'
import { collateTaxesFromCheck } from '../../utils/payments'
import InvoiceTotalRow from './InvoiceTotalRow'

export default function IntlTaxRow() {
  const { check } = useData()
  const { currency, locale } = useBuffetContext()
  const taxesArray = collateTaxesFromCheck(check)
  return (
    <>
      {taxesArray.map(({ amount, name, rate }) => (
        <InvoiceTotalRow
          key={`${name} ${rate})`}
          label={`${name} (${formatPercent(rate, locale, { precision: 2 })})`}
          value={formatCurrency({ amount, currency }, locale)}
        />
      ))}
      <InvoiceTotalRow
        className='font-bold'
        label={`Tax total`}
        value={formatCurrency(
          { amount: check?.taxAmount || 0, currency },
          locale
        )}
      />
    </>
  )
}
