import React from 'react'
import LabelItem, { LabelItemAlign } from '../LabelItem/LabelItem'
import { Skeleton } from '@toasttab/buffet-pui-loading-indicators'

export default function InvoiceLoadingView() {
  const defaultSkeleton = (
    <Skeleton className={'mt-1'} height={'16px'} width={'180px'} />
  )

  return (
    <div className={'bg-white rounded'}>
      <div
        className={
          'px-8 py-6 border-gray-30 border-solid border-t-0 border-l-0 border-r-0 border-b'
        }
      >
        <Skeleton className='w-56 h-8' />
      </div>

      <div className={'px-8 py-6'}>
        <Skeleton className={'mb-6'} width={'80px'} height={'80px'} />
        <Skeleton className={'mb-3'} width={'180px'} height={'28px'} />
        <Skeleton width={'320px'} height={'40px'} />
      </div>

      <div className={'px-8 py-6 bg-gray-0 flex'}>
        <div className={'w-1/2 space-y-3'}>
          <LabelItem label={'Billed To'}>{defaultSkeleton}</LabelItem>
          <LabelItem label={'Invoice Number'}>{defaultSkeleton}</LabelItem>
          <LabelItem label={'Invoice Date'}>{defaultSkeleton}</LabelItem>
        </div>
        <div className={'w-1/2 space-y-3 flex flex-col justify-between'}>
          <LabelItem label={'Due By'} align={LabelItemAlign.Right}>
            {defaultSkeleton}
          </LabelItem>
          <LabelItem label={'Amount Due'} align={LabelItemAlign.Right}>
            <Skeleton className={'mt-1'} height={'32px'} width={'180px'} />
          </LabelItem>
        </div>
      </div>

      <div className={'mx-8 py-6'}>
        <Skeleton height={'16px'} width={'180px'} />
        <Skeleton className={'mt-4'} height={'16px'} width={'180px'} />
        <Skeleton className={'mt-4'} height={'16px'} width={'180px'} />
      </div>
    </div>
  )
}
