import React from 'react'
import { PublicLocationsResponse } from '../../types/EventTypes'
import { ChevronRightIcon } from '@toasttab/buffet-pui-icons'
import { Link } from 'react-router-dom'
import LocationCard from './LocationCard'
import { getLocationPanelId } from '../Map/LocationMap'

interface Props {
  locations?: PublicLocationsResponse[]
  setSelectedGuid: (guid: string) => void
  selectedGuid: string | undefined
}

const LeadLocationSelect = ({
  locations,
  setSelectedGuid,
  selectedGuid
}: Props) => {
  return (
    <>
      {locations?.map((location) => {
        return (
          <LocationCard
            key={location.guid}
            id={getLocationPanelId(location.guid)}
            onClick={() => setSelectedGuid(location.guid)}
            restaurantName={location.branding.name}
            locationName={location.branding.locationName}
            address={location.branding}
            distanceMiles={location.distance}
            isSelected={selectedGuid === location.guid}
          >
            <div className={'space-y-2'}>
              {location.orderTypes.map((orderType) => (
                <Link
                  key={orderType.guid}
                  to={`/invoice/lead?rx=${location.guid}&ot=${orderType.guid}`}
                  className={
                    'type-default rounded-lg w-full px-4 py-3 bg-white shadow cursor-pointer flex justify-between hover:bg-darken-4'
                  }
                >
                  {orderType.name}
                  <ChevronRightIcon
                    accessibility='decorative'
                    className={'text-link'}
                  />
                </Link>
              ))}
            </div>
          </LocationCard>
        )
      })}
    </>
  )
}

export default LeadLocationSelect
