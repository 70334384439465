import React from 'react'
import { useBuffetContext } from '@toasttab/buffet-pui-context-provider'
import {
  formatCurrency,
  getCurrencySymbol
} from '@toasttab/buffet-pui-number-utilities'
import InvoiceTipButton from './InvoiceTipButton'
import { useData } from './DataProvider'
import { Modal } from '@toasttab/buffet-pui-modal'
import { NumberInputField } from '@toasttab/buffet-pui-forms'
import { Button } from '@toasttab/buffet-pui-buttons'
import { Formik, Form } from 'formik'
import { useInvoice } from './InvoiceProvider'
import { getCheckBalance } from '../../utils/payments'

function calculateTipAmount(totalAmount: number, percent: number) {
  return Number(((totalAmount * percent) / 100).toFixed(2))
}

export default function InvoiceTipInput() {
  const { check, invoiceSettings } = useData()
  const { setTipAmount, tipAmount } = useInvoice()
  const { currency, locale } = useBuffetContext()

  const [isOpen, setIsOpen] = React.useState(false)
  const closeModal = () => setIsOpen(false)

  let tippableAmount = check.totalAmount - check.taxAmount - check.tipAmount
  if (check.tipAmount > 0) {
    tippableAmount = getCheckBalance(check)
  }

  const getPresets = () => {
    if (
      invoiceSettings.tipPercentages &&
      invoiceSettings.tipPercentages.length > 0
    ) {
      return invoiceSettings.tipPercentages?.filter(Boolean)
    }
    return ['20', '15', '10']
  }
  const preset = getPresets()
  const [selected, setSelected] = React.useState(
    invoiceSettings.defaultTipPercentage ?? preset[0] ?? 'custom'
  )

  React.useEffect(() => {
    if (selected === 'custom') {
      setTipAmount(0)
    } else {
      const percent = Number(selected)
      const tip = calculateTipAmount(tippableAmount, percent)
      setTipAmount(tip)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  return (
    <>
      <ul role='listbox' className={'flex space-x-4 mb-8'}>
        {preset.map((percent) => {
          const calculatedTip = calculateTipAmount(
            tippableAmount,
            Number(percent)
          )
          const percentString = `${percent}%`
          if (percent !== '0') {
            return (
              <InvoiceTipButton
                key={percentString}
                title={percentString}
                subtitle={formatCurrency(
                  { amount: calculatedTip, currency },
                  locale
                )}
                selected={selected === String(percent)}
                onClick={() => setSelected(String(percent))}
              />
            )
          } else {
            return (
              <InvoiceTipButton
                key={percentString}
                title={'No Tip'}
                selected={selected === String(percent)}
                onClick={() => setSelected(String(percent))}
              />
            )
          }
        })}
        <InvoiceTipButton
          title={'Custom'}
          selected={selected === 'custom'}
          subtitle={
            selected === 'custom'
              ? formatCurrency({ amount: tipAmount, currency }, locale)
              : undefined
          }
          onClick={() => {
            setSelected('custom')
            setIsOpen(true)
          }}
        />
      </ul>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        size='xxl'
        className='min-h-8'
        parentSelector={() => {
          const element = document.querySelector('#banquetPortalsContainer')
          element?.setAttribute('nvs-inv-pay', 'true')
          return element
        }}
      >
        <Modal.Body>
          <h3 className={'type-large text-default mb-4'}>Enter a tip amount</h3>
          <Formik
            initialValues={{ customTipAmount: '' }}
            onSubmit={(val) => {
              setTipAmount(Number(val.customTipAmount))
              closeModal()
            }}
          >
            {() => (
              <Form className={'mb-1 max-w-sm'} id={'tipForm'}>
                <NumberInputField
                  name='customTipAmount'
                  label='Amount'
                  decimalScale={2}
                  thousandSeparator
                  prefix={getCurrencySymbol(currency)}
                  autoFocus
                />
              </Form>
            )}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className='flex-grow sm:flex-none'
            variant='link'
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            className='flex-grow sm:flex-none'
            type='submit'
            form={'tipForm'}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
