import React from 'react'
import { handleLoyaltyEnrollment } from '../../api/invoices'
import { RestaurantBranding } from '../../types/InvoiceTypes'
import { Restaurant } from '../../types/RestaurantTypes'
import { AutorenewIcon } from '@toasttab/buffet-pui-icons'
import { useSentry } from 'banquet-runtime-modules'
import { EnrollmentRequest } from '../../types/DataProviderTypes'
import { useInvoice } from './InvoiceProvider'
import { useData } from './DataProvider'
import LoyaltyEnrollment from '../Loyalty/LoyaltyEnrollment'
import { Button } from '@toasttab/buffet-pui-buttons'
import { useGetSpiSdk } from '../../utils/spi-payments'
import { format, Formats, parseISO } from '@toasttab/buffet-pui-date-utilities'
import {
  createPaymentIntent,
  postPayment,
  updatePaymentIntent
} from '../../api/paymentIntents'
import {
  ConfirmPaymentResultEvent,
  CreatePaymentMethodResultEvent,
  PaymentIntentResponse
} from '../../types/SPI'
import { getSPILineItems } from '../../utils/payments'
import { Checkbox } from '@toasttab/buffet-pui-checkbox'
import { useBuffetContext } from '@toasttab/buffet-pui-context-provider'
import { formatCurrency } from '@toasttab/buffet-pui-number-utilities'

export default function SPICreditCardForm(props: {
  restaurant: Restaurant
  restaurantBranding: RestaurantBranding
  setLoyaltyError: React.Dispatch<React.SetStateAction<string | null>>
  loyaltyError: string | null
  currency: string | 'USD'
}) {
  const { restaurant, setLoyaltyError, loyaltyError } = props
  const {
    invoice,
    setIsPaid,
    tipAmount,
    setEnrolledInLoyalty,
    paymentAmount,
    checkBalance,
    isDeposit
  } = useInvoice()

  const amountToPay = isDeposit ? paymentAmount : checkBalance
  const [isMit, setIsMit] = React.useState(false)
  const { invoiceSettings } = useData()
  const { captureException } = useSentry()
  const [
    paymentIntent,
    setPaymentIntent
  ] = React.useState<PaymentIntentResponse | null>(null)
  const [paymentIntentValid, setPaymentIntentValid] = React.useState(false)
  const [paymentError, setPaymentError] = React.useState<string | null>(null)
  const [loading, setLoading] = React.useState(false)
  const [loyaltyValid, setLoyaltyValid] = React.useState(true)
  const [
    loyaltyEnrollmentRequest,
    setLoyaltyEnrollmentRequest
  ] = React.useState<EnrollmentRequest | undefined>()
  const [paymentComplete, setPaymentComplete] = React.useState(false)

  const paymentFrameAnchorId = 'spi-frame-anchor'
  const spiSdk = useGetSpiSdk()
  React.useMemo(() => {
    createPaymentIntent(
      {
        amount: paymentAmount,
        currency: 'USD',
        email: invoice.customer.email,
        customerId: invoice?.requestCardOnFile
          ? invoice.customerGuid
          : undefined
      },
      invoice.restaurantGuid
    )
      .then((response) => setPaymentIntent(response))
      .catch((err) => {
        console.log(err)
        captureException(err)
        setPaymentError('Something went wrong. Failed to create payment')
      })
  }, [
    captureException,
    invoice.customer.email,
    invoice.customerGuid,
    invoice?.requestCardOnFile,
    invoice.restaurantGuid,
    paymentAmount
  ])

  React.useEffect(() => {
    let frame: HTMLIFrameElement | undefined
    if (spiSdk && paymentIntent) {
      frame = spiSdk.initialize(
        (initEvent) => {
          console.log('Successful initialization callback')
          setPaymentIntentValid(initEvent.content.isValid)
          spiSdk.monitor((e) => {
            if (e.content.selectedPaymentMethod) {
              setPaymentIntentValid(e.content.isValid)
            }
          })
        },
        () => {
          console.log('Failed initialization callback')
          captureException(new Error('Failed credit card form initialization'))
        },
        {
          domElementId: paymentFrameAnchorId,
          merchantId: invoice.restaurantGuid,
          sessionSecret: paymentIntent?.sessionSecret!!,
          acceptAmex: restaurant?.creditCardConfig
            ? restaurant.creditCardConfig.amexAccepted
            : false,
          zipRequired: false,
          oauthToken: paymentIntent?.oauthToken!!
        },
        {
          fontFamily: 'Source-Sans-Pro, Helvetica Neue, Arial',
          fontSize: '14px',
          fontWeight: '500'
        }
      )
    }

    return () => {
      try {
        if (frame && frame.parentNode) {
          frame.parentNode.removeChild(frame)
        }
      } catch (e) {
        captureException(new Error('Error removing SPI frame'))
        setPaymentError('Something went wrong.')
      }
    }
  }, [spiSdk, paymentIntent])

  const { currency, locale } = useBuffetContext()

  React.useEffect(() => {
    if (spiSdk && paymentIntent) {
      updatePaymentIntent(
        paymentIntent?.paymentIntentId!!,
        {
          amount: paymentAmount,
          email: invoice.customer.email,
          oAuthToken: paymentIntent?.oauthToken,
          tipAmount: tipAmount,
          customerId: invoice?.requestCardOnFile
            ? invoice.customerGuid
            : undefined,
          standingInstructionType: isMit ? 'INSTALLMENT' : undefined
        },
        invoice.restaurantGuid
      )
        .then(() => {
          console.log('Updated Payment Intent')
        })
        .catch((err) => {
          console.log(err)
          captureException(err)
          setPaymentError('Something went wrong. Failed to update payment')
        })
    }
  }, [tipAmount, isMit])

  React.useEffect(() => {
    if (paymentComplete && !paymentError && !loyaltyError) {
      setIsPaid(true)
    }
  }, [paymentComplete])

  const confirmPayment = async () => {
    await spiSdk?.confirmPayment(
      async (e: ConfirmPaymentResultEvent) => {
        postPayment(
          invoice.order.guid,
          {
            paymentGuid: e.content.payment.externalReferenceId,
            paymentMethodId: e.content.payment.paymentMethodId,
            paidDate: new Date().toISOString(),
            amount: amountToPay,
            tipAmount: tipAmount,
            paymentTypeEnum: 'CREDIT'
          },
          invoice.restaurantGuid
        )
          .catch((error) => {
            console.log('Error in Post Payment' + error.message)
            setPaymentError('Something went wrong.')
            captureException(error.message)
            setLoading(false)
          })
          .finally(() => {
            setPaymentComplete(true)
            setLoading(false)
          })
      },
      async (e: ConfirmPaymentResultEvent) => {
        console.log('ConfirmPaymentResultEvent' + e.content)
        captureException(new Error('Error confirming payment' + e.content))
        setPaymentError('Something went wrong.')
        setLoading(false)
      },
      {}
    )
  }

  const handleSubmit = () => {
    setLoading(true)
    setPaymentError(null)
    setLoyaltyError(null)
    handleLoyaltyEnrollment(
      loyaltyEnrollmentRequest,
      invoice,
      invoiceSettings.loyaltySettings?.enrolled,
      setEnrolledInLoyalty,
      setLoyaltyError,
      captureException
    )

    spiSdk?.createPaymentMethod(
      async () => {
        await confirmPayment()
      },
      async (e: CreatePaymentMethodResultEvent) => {
        captureException(
          new Error('Error in Create Payment Method' + e.content)
        )
        setPaymentError('Something went wrong.')
      },
      getSPILineItems(isDeposit ? paymentAmount : checkBalance, tipAmount)
    )
  }

  return (
    <>
      <div id={paymentFrameAnchorId}></div>
      <LoyaltyEnrollment
        setLoyaltyValid={setLoyaltyValid}
        setLoyaltyEnrollmentRequest={setLoyaltyEnrollmentRequest}
      />
      {invoice?.requestCardOnFile && (
        <div className={'ml-3'}>
          <Checkbox
            label={
              <div>
                *
                <strong>
                  I authorize use of this card for the final balance
                </strong>{' '}
                for {restaurant.name} to charge the card selected above in the
                amount of{'  '}
                {formatCurrency({ amount: amountToPay, currency }, locale)} for
                a deposit on {format(new Date(), Formats.date.shorter, locale)},
                and to charge the remaining balance for this order to this card.
                I understand that the remaining balance for this order could
                vary and I must contact {restaurant.name} at least 3 business
                days before the due date to cancel payment for it.
              </div>
            }
            checked={isMit}
            onChange={() => {
              setIsMit(!isMit)
            }}
          />
        </div>
      )}
      <Button
        className={'w-full'}
        disabled={
          !paymentIntentValid ||
          Boolean(paymentError) ||
          loading ||
          !loyaltyValid
        }
        id='submitPayment'
        type='submit'
        onClick={handleSubmit}
        iconLeft={
          loading ? (
            <AutorenewIcon aria-label='loading' className='animate-spin' />
          ) : undefined
        }
      >
        Pay now
      </Button>
      {paymentError && (
        <p className={'pb-3 text-center type-default text-error mt-2'}>
          {paymentError}
        </p>
      )}
      {loyaltyError && (
        <p className={'pb-3 text-center type-default text-error mt-2'}>
          {loyaltyError}
        </p>
      )}
    </>
  )
}
