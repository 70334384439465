import React from 'react'
import { printAddressBranded } from '../../../utils/utils'
import { formatPhone } from '@toasttab/buffet-pui-phone-utilities'
import { PublicEventTypeResponse } from '../../../types/EventTypes'
import { RestaurantBranding } from '../../../types/InvoiceTypes'
import { CountryIsoCode } from '@toasttab/buffet-pui-country-utilities'
import { LinkIcon, LocationIcon, PhoneIcon } from '@toasttab/buffet-pui-icons'
import classNames from 'classnames'

export interface LeadContactInfoProps {
  eventType: PublicEventTypeResponse | null
  className?: string
}

export const LeadContactInfo: React.FC<LeadContactInfoProps> = ({
  eventType,
  className
}) => {
  const restaurantBranding: RestaurantBranding | undefined =
    eventType?.restaurantBranding

  if (!restaurantBranding) {
    return null
  }

  const leadWebsiteUrl = eventType?.leadWebsiteUrl

  const country: CountryIsoCode = eventType?.restaurantCountry
    ? (eventType?.restaurantCountry as CountryIsoCode)
    : 'US'

  return (
    <ul className={classNames('flex flex-col gap-4', className)}>
      <li className={'flex'}>
        <LocationIcon accessibility={'decorative'} className={'p-2 pl-0'} />
        <p>
          {printAddressBranded({
            restaurantBranding: { ...restaurantBranding, country },
            singleLine: true
          })}
        </p>
      </li>
      {leadWebsiteUrl && (
        <li className='flex'>
          <LinkIcon accessibility={'decorative'} className={'px-2 pl-0'} />
          <a href={leadWebsiteUrl} target={'_blank'} rel='noreferrer'>
            {leadWebsiteUrl.replace(/^https?:\/\//, '')}
          </a>
        </li>
      )}
      <li className='flex'>
        <PhoneIcon accessibility={'decorative'} className={'px-2 pl-0'} />
        <a
          href={`tel:${formatPhone(
            restaurantBranding.phone,
            country as CountryIsoCode
          )}`}
        >
          {formatPhone(restaurantBranding.phone, country as CountryIsoCode)}
        </a>
      </li>
    </ul>
  )
}
