import React from 'react'
import { useInvoice } from './InvoiceProvider'

export default function InvoiceHeader() {
  const { invoice } = useInvoice()

  return (
    <div
      className={
        'px-8 py-6 flex items-center justify-between border-gray-30 border-solid border-t-0 border-l-0 border-r-0 border-b'
      }
    >
      <h1 className={'type-headline-4'}>Invoice #{invoice.invoiceNumber}</h1>
    </div>
  )
}
