import React from 'react'
import OrderSummaryTable from './OrderSummaryTable'
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary'

export default function InvoiceSummary() {
  return (
    <div className={'mt-6 bg-white rounded p-8'}>
      <ErrorBoundary
        fallbackMessage={
          'Sorry, we are unable to load the order summary for this invoice.'
        }
      >
        <h3 className={'type-large mb-4'}>Order Summary</h3>
        <OrderSummaryTable />
      </ErrorBoundary>
    </div>
  )
}
