import React, { useCallback } from 'react'
import { Button } from '@toasttab/buffet-pui-buttons'
import { useEvent } from '../EventView/EventProvider'
import { EventStatus } from '../../types/EventTypes'
import { useData } from '../InvoiceView/DataProvider'
import { format, Formats, parseISO } from '@toasttab/buffet-pui-date-utilities'
import { useBuffetContext } from '@toasttab/buffet-pui-context-provider'
import { formatCurrency } from '@toasttab/buffet-pui-number-utilities'
import classNames from 'classnames'
import { useBrandingThemeEnabled } from '../../hooks/useBrandingThemeEnabled'
import { useNavigate } from 'react-router'

interface EventStatusContent {
  title?: string
  description?: React.ReactNode
  cta?: string
}

const useEventStatusContent = (): EventStatusContent => {
  const { event, isOrderVoided } = useEvent()
  const { restaurantBranding, previousPayments, totalAmount } = useData()
  const { locale, currency } = useBuffetContext()

  const toCurrencyStr = (amount: number) =>
    formatCurrency(
      {
        amount,
        currency
      },
      locale
    )

  if (isOrderVoided) {
    return {
      title: 'VOID',
      description: 'The event’s order was voided by the restaurant.'
    }
  }

  if (event.invoiceGuid && event.order?.paidDate) {
    const paid = previousPayments.length > 0
    let amount = toCurrencyStr(
      paid ? previousPayments[previousPayments.length - 1].amount : 11.11
    )
    if (!paid) {
      amount = amount.replace(/1/gi, 'X')
    }
    return {
      title: 'Order paid!',
      description: (
        <>
          The order has been paid. Look for a message in your email with the
          subject line:{' '}
          <em>
            “Receipt for {amount} from {restaurantBranding.name}”
          </em>
          .
        </>
      )
    }
  }

  if (event.invoiceGuid && event.order?.guid) {
    const check = event.order?.checks?.[0]!!
    const amountDue: number = check.amountDue ?? check.totalAmount ?? 0
    const hasNewCharges = check.amountDue != check.totalAmount

    return {
      title: `Payment due`,
      description: `${
        hasNewCharges
          ? 'Your order was updated recently and you have new invoice for'
          : 'You have an invoice for'
      } ${toCurrencyStr(amountDue)}.`,
      cta: 'Pay Invoice'
    }
  }

  switch (event.status) {
    case EventStatus.TENTATIVE:
      return {
        title: 'Estimate ready to view!',
        description: (
          <>
            You have a new estimate from {restaurantBranding.name} for{' '}
            {toCurrencyStr(totalAmount)}.
            {event.signatureRequired
              ? ' Sign the contract at the bottom of this page to confirm this order.'
              : ''}
          </>
        ),
        cta: 'View estimate'
      }

    case EventStatus.CLOSED:
      return {
        title: 'Order closed!',
        description: `This order has been closed.`
      }

    case EventStatus.CONFIRMED:
      return {
        title: 'Estimate approved!',
        description: `You have confirmed your order at ${
          restaurantBranding.name
        } for ${format(parseISO(event.date!), Formats.date.long, locale)}.`
      }

    case EventStatus.REFUNDED:
      return {
        title: 'Refunded',
        description: 'This event was refunded.'
      }

    case EventStatus.DELETED:
      return {
        title: 'Canceled',
        description: 'This event was canceled.'
      }

    case EventStatus.DISQUALIFIED:
      return {
        title: 'Not Available',
        description: 'This event is no longer available.'
      }

    default:
      return {
        title: 'Inquiry submitted!',
        description:
          'Your inquiry has been submitted. We will be in touch soon.'
      }
  }
}

export const EventPortalStatus: React.FC = () => {
  const { event } = useEvent()
  const { token } = useData()
  const { title, description, cta } = useEventStatusContent()
  const brandingThemeEnabled = useBrandingThemeEnabled()
  const navigate = useNavigate()

  const onCtaButtonClick: React.MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    if (event.invoiceGuid && event.order?.guid) {
      navigate(
        `/invoice/${event.invoiceGuid}?event=${event.guid}&order=${event.order.guid}&token=${token}`
      )
      return
    }

    switch (event.status) {
      case EventStatus.TENTATIVE:
        window.location.hash = event.signatureRequired
          ? '#contract'
          : '#order-details'
        break

      default:
        break
    }
  }, [event])

  return (
    <div
      className={'flex flex-col px-4 py-6 gap-4 text-color-ds-on-bg-primary'}
    >
      {title && (
        <h3 className={'type-headline-4 tsw-header-font-family'}>{title}</h3>
      )}
      {description && (
        <p className={'type-default tsw-font-family'}>{description}</p>
      )}
      {cta && (
        <Button
          className={classNames(
            'mt-2',
            brandingThemeEnabled &&
              '!bg-tsw-primary-contrasting-color !border-tsw-primary-contrasting-color ' +
                'hover:saturate-[.9] hover:brightness-90 ' +
                'tsw-font-family !text-tsw-primary-color'
          )}
          onClick={onCtaButtonClick}
        >
          {cta}
        </Button>
      )}
    </div>
  )
}
