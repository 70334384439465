import React from 'react'

interface Props {
  isAvailable: boolean
  text: string
}

const AvailabilityTag = ({ isAvailable, text }: Props) => {
  return (
    <div className='flex mb-3 text-default'>
      <div
        className='w-3 h-3 my-1.5 mr-1.5 rounded-full'
        style={{
          backgroundColor: isAvailable ? '#71AB24' : '#C0C0C0'
        }}
      />
      {`${text} ${isAvailable ? 'available' : 'not available'}`}
    </div>
  )
}

export default AvailabilityTag
