import React from 'react'
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary'
import { useEvent } from './EventProvider'

interface EventTimelineTableProps {
  hideHeading?: boolean
}

const EventTimelineTable: React.FC<EventTimelineTableProps> = ({
  hideHeading
}) => {
  const { event } = useEvent()

  if (!event.timeline?.enabled || event.timeline?.entries?.length === 0)
    return null

  // We get just the time in 24-hour format from the backend API call, so need to reformat it
  const formatTime = (time: string): string => {
    const inputDate = new Date(`2000-01-01T${time}`) // Dummy date to just extract the time from

    return inputDate.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    })
  }

  return (
    <div className={'px-4 md:px-8 pb-8 mt-4'}>
      <ErrorBoundary
        fallbackMessage={
          'Sorry, we are unable to load the timeline for this estimate.'
        }
      >
        <>
          {!hideHeading && (
            <div className={'type-overline text-secondary font-bold pb-2'}>
              Timeline
            </div>
          )}
          <table className={'w-full border-collapse border-0'}>
            <tbody>
              {event.timeline.entries?.map(
                ({ description, startTime, endTime }, i) => {
                  return (
                    <tr
                      key={i}
                      className={`border-b border-DEFAULT border-color-ds ${
                        i === 0 ? 'border-t' : ''
                      }`}
                    >
                      <td
                        style={{ lineHeight: 2 }}
                        className={
                          'w-48 align-top py-2 pr-2 type-overline text-secondary font-700'
                        }
                      >
                        {`${formatTime(startTime)}`}
                        {endTime ? ` - ${formatTime(endTime)}` : ''}
                      </td>
                      <td className={'align-top py-2 pr-2 type-default'}>
                        {description}
                      </td>
                    </tr>
                  )
                }
              )}
            </tbody>
          </table>
        </>
      </ErrorBoundary>
    </div>
  )
}

export default EventTimelineTable
