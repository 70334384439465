import { renderStyleMeta, StyleMetaProps } from '@toasttab/sites-components'
import { brandingPublicAssetURL } from '../api/util'
import React, { useMemo } from 'react'
import { RestaurantBranding } from '../types/InvoiceTypes'
import { useBrandingThemeEnabled } from './useBrandingThemeEnabled'

export const useBrandingStyleMeta = (
  restaurantBranding: RestaurantBranding | undefined,
  isSitesView?: boolean
): React.ReactNode => {
  const brandingThemeEnabled = useBrandingThemeEnabled()

  return useMemo(() => {
    if (!restaurantBranding) {
      return undefined
    }

    // When our lead pages are embedded in Sites, they always need to use Site's brand info
    // regardless of if the Rx has branding turned on
    const brandingStyle: StyleMetaProps = !brandingThemeEnabled
      ? {}
      : isSitesView
      ? {
          primaryColor: restaurantBranding.sitesPrimaryColor,
          backgroundColor: restaurantBranding.sitesBackgroundColor
            ? restaurantBranding.sitesBackgroundColor
            : restaurantBranding.sitesPrimaryColor,
          textColor: restaurantBranding.sitesTextColor,
          fontFamily: restaurantBranding.sitesFontFamily,
          headerFontFamily: restaurantBranding.sitesHeaderFontFamily,
          siteTheme: restaurantBranding.theme
        }
      : {
          primaryColor: restaurantBranding.primaryColor,
          backgroundColor: restaurantBranding.backgroundColor
            ? restaurantBranding.backgroundColor
            : restaurantBranding.primaryColor,
          textColor: restaurantBranding.textColor,
          fontFamily: restaurantBranding.fontFamily,
          headerFontFamily: restaurantBranding.headerFontFamily,
          siteTheme: restaurantBranding.theme
        }

    return renderStyleMeta(brandingStyle, brandingPublicAssetURL)
  }, [brandingThemeEnabled, restaurantBranding])
}
