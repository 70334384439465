import React, { useState } from 'react'
import { CelebrationIllustration } from '@toasttab/buffet-pui-illustrations'
import { useInvoice } from '../InvoiceView/InvoiceProvider'
import { CardSelector } from '@toasttab/buffet-pui-card-selector'
import {
  isItemDiscount,
  rewardToCard,
  rewardToDiscount,
  RewardType
} from './loyalty'
import { useBuffetContext } from '@toasttab/buffet-pui-context-provider'
import { applyCheckRedemption } from '../../api/invoices'
import { useSearchParams } from 'react-router-dom'
import { AppliedDiscount, SelectionType } from '../../types/InvoiceTypes'
import { formatCurrency } from '@toasttab/buffet-pui-number-utilities'
import { useSentry } from 'banquet-runtime-modules'

export const filterForActiveDiscounts = (
  appliedDiscounts: AppliedDiscount[] | undefined
): AppliedDiscount[] => {
  return (
    appliedDiscounts?.filter(
      (discount) =>
        discount.processingState !== 'PENDING_VOID' &&
        discount.processingState !== 'VOID'
    ) ?? []
  )
}

export const isLoyaltyDiscount = (
  appliedDiscount: AppliedDiscount
): boolean => {
  return !!appliedDiscount.loyaltyDetails
}

export default function LoyaltyRedemption() {
  const {
    availableRedemptions = [],
    loyaltyVendor,
    discounts,
    loyaltyConversionRate,
    paymentAmount,
    invoice,
    setInvoiceResponseValues,
    isPaid,
    isDeposit
  } = useInvoice()

  const activeCheckDiscounts = filterForActiveDiscounts(
    invoice.order.checks[0].appliedDiscounts
  )
  let itemDiscounts: AppliedDiscount[] = []
  invoice.order.checks[0].selections?.forEach((s) =>
    s.appliedDiscounts?.forEach((d) => itemDiscounts.push(d))
  )

  const activeItemDiscounts = filterForActiveDiscounts(itemDiscounts)

  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')

  const [error, setError] = useState<string | undefined>()

  const { currency, locale } = useBuffetContext()

  const [toRedeem, setToRedeem] = useState<number | undefined>()

  const [saving, setIsSaving] = useState(false)

  const itemLoyaltyDiscounts = activeItemDiscounts.filter((d) =>
    isLoyaltyDiscount(d)
  )
  const checkLoyaltyDiscounts = activeCheckDiscounts.filter((d) =>
    isLoyaltyDiscount(d)
  )

  const { captureException } = useSentry()

  if (isPaid || isDeposit) {
    return null
  }

  if (itemLoyaltyDiscounts.length > 0 || checkLoyaltyDiscounts.length > 0) {
    const alreadyAppliedRewards =
      itemLoyaltyDiscounts
        .map((d) => d.discountAmount ?? 0)
        .reduce((a, b) => a + b, 0) +
      checkLoyaltyDiscounts
        .map((d) => d.discountAmount ?? 0)
        .filter((n) => n)
        .reduce((a, b) => a + b, 0)

    if (alreadyAppliedRewards === 0) {
      return null
    }

    return (
      <>
        <div className={'text-center'}>
          <CelebrationIllustration
            className='w-32 mt-4 mb-2'
            resizeToContainer
          />
          <div className={'pb-6 mt-2 type-headline-5'}>
            You’ll save{' '}
            {formatCurrency(
              { amount: alreadyAppliedRewards, currency },
              locale
            )}{' '}
            on this order!
          </div>
        </div>
      </>
    )
  } else if (
    activeCheckDiscounts.length > 0 ||
    activeItemDiscounts.length > 0
  ) {
    return null
  }

  if (error) {
    return (
      <>
        <p className={'pb-3 text-center type-default text-error mt-2'}>
          {error}
        </p>
      </>
    )
  }

  const discountMap = new Map(discounts?.map((d) => [d.guid, d]))

  const switchReward = (key: number) => {
    if (toRedeem === key) {
      setToRedeem(undefined)
    } else {
      setToRedeem(key)
    }
  }

  const filteredRedemptions = availableRedemptions.filter(
    (r) =>
      r.availability === 'CURRENT_CHECK' &&
      (r.referenceId === RewardType.LOYALTY_DOLLARS_AMOUNTS ||
        r.integrationData?.selectionType === SelectionType.CHECK)
  )

  const redemptionViews = filteredRedemptions?.map((r, i) => {
    return { key: i, redemption: r }
  })

  const rewardToDiscountMap = new Map(
    redemptionViews?.map((r) => [
      r.key,
      rewardToDiscount(
        r.redemption,
        loyaltyVendor!!,
        discountMap.get(r.redemption.itemId)!!
      )
    ])
  )

  const options = redemptionViews
    .filter((r) => rewardToDiscountMap.get(r.key))
    .map((r) =>
      rewardToCard(r, paymentAmount, currency, locale, loyaltyConversionRate)
    )
    .filter((v) => v)
    .map((v) => v!!)

  if (options.length === 0) {
    // No rewards but they're still an enrolled loyalty member -
    // show point balance (only exists for Toast loyalty)
    const availablePoints = availableRedemptions.find(
      (r) => r.referenceId === 'LOYALTY_POINTS_AMOUNT'
    )?.quantity

    return (
      <>
        <div className='space-y-1 text-default'>
          {availablePoints && availablePoints === 0 && (
            <>
              <div className='font-semibold'>
                You don't have any points (yet!)
              </div>
              <div className='type-subhead'>
                Keep ordering to earn points and unlock rewards
              </div>
            </>
          )}
          {availablePoints && availablePoints > 0 && (
            <>
              <div className='font-semibold'>{`You have ${availablePoints} points!`}</div>
              <div className='type-subhead'>No rewards at this time.</div>
            </>
          )}
          {!availablePoints && <div>No rewards at this time.</div>}
        </div>
      </>
    )
  }

  return (
    <>
      <div className={'text-center'}>
        <CelebrationIllustration className='w-32 mt-4 mb-2' resizeToContainer />
        <div className={'type-headline-5'}>You have rewards!</div>
        <div className={'text-secondary'}>
          {options.length > 1
            ? 'Select 1 reward to redeem'
            : 'Select to redeem'}
        </div>
        <div className={'pr-4 pl-4 pb-4 mt-2'}>
          {options.map((o) => {
            const key = o.value
            const reward = redemptionViews[key].redemption!!

            if (isItemDiscount(reward)) {
              return null
            }

            return (
              <div key={o.value} className={'mb-2'}>
                <CardSelector
                  disabled={saving}
                  checked={toRedeem === key}
                  onClick={() => {
                    setIsSaving(true)
                    switchReward(key)

                    const discount = rewardToDiscountMap.get(key)!!

                    applyCheckRedemption(
                      discount,
                      invoice.restaurantGuid,
                      token!!
                    )
                      .then((response) => setInvoiceResponseValues(response))
                      .catch((e) => {
                        captureException(e)
                        setError('Unexpected error redeeming loyalty reward')
                      })
                      .finally(() => setIsSaving(false))
                  }}
                >
                  {o.contents}
                </CardSelector>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}
