import React, { ReactElement } from 'react'
import {
  ChecksEntity,
  MenuItemMap,
  PublicSettings,
  RestaurantBranding
} from '../../types/InvoiceTypes'
import type { I18n } from '../../types/RestaurantTypes'
import { ErrorPage500 } from '@toasttab/buffet-pui-error-pages'
import { DataContextType } from '../../types/DataProviderTypes'
import { useRestaurant } from '../../api/restaurant'
import { BuffetContextProvider } from '@toasttab/buffet-pui-context-provider'
import { paymentCountsTowardsBalance } from '../../utils/payments'

// @ts-ignore doesn't like empty default context
export const DataContext = React.createContext<DataContextType>({})

export const useData = () => React.useContext(DataContext)

interface DataProviderProps {
  token: string
  restaurantGuid: string
  menuItemMap?: MenuItemMap
  totalAmount: number
  check: ChecksEntity
  invoiceSettings?: PublicSettings
  restaurantBranding: RestaurantBranding
  loading: ReactElement
}

const DataProvider: React.FC<React.PropsWithChildren<DataProviderProps>> = ({
  children,
  token,
  restaurantGuid,
  menuItemMap,
  check,
  totalAmount,
  invoiceSettings,
  restaurantBranding,
  loading
}) => {
  const { restaurant, error } = useRestaurant(restaurantGuid)

  if (error) {
    return <ErrorPage500 />
  }

  if (!restaurant) {
    return loading
  }

  const { currency, locale } = restaurant.i18n ? restaurant.i18n : ({} as I18n)

  const previousPayments =
    check?.payments?.filter((payment) =>
      paymentCountsTowardsBalance(payment)
    ) ?? []

  return (
    <BuffetContextProvider currency={currency} locale={locale}>
      <DataContext.Provider
        value={{
          check,
          restaurant,
          menuItemMap,
          token,
          totalAmount,
          // @ts-ignore gets angry about undefined
          invoiceSettings,
          restaurantBranding,
          previousPayments
        }}
      >
        {children}
      </DataContext.Provider>
    </BuffetContextProvider>
  )
}

export default DataProvider
