import React from 'react'
import { useQueryParams } from '../../utils/utils'
import LeadFormView from './LeadFormView'
import { useEventType } from '../../api/invoices'
import { LeadForm } from './LeadForm'
import { Helmet } from 'react-helmet-async'
import AllPagesTag from '../GooglePixels/AllPagesTag'
import { useBrandingStyleMeta } from '../../hooks/useBrandingStyleMeta'

const LeadViewRouter: React.FC = () => {
  const {
    rx: restaurantGuid,
    ot: eventTypeGuid,
    form: useEmbeddableForm,
    isSitesView
  } = useQueryParams()
  const { eventType, error } = useEventType(restaurantGuid, eventTypeGuid)

  const brandingStyleMeta = useBrandingStyleMeta(
    eventType?.restaurantBranding,
    !!isSitesView && isSitesView !== 'false'
  )

  return eventType && restaurantGuid && eventTypeGuid ? (
    <>
      {brandingStyleMeta && <Helmet>{brandingStyleMeta}</Helmet>}
      <AllPagesTag managementSetGuid={eventType.managementSetGuid} />
      {useEmbeddableForm ? (
        <LeadForm eventType={eventType} />
      ) : (
        <LeadFormView eventType={eventType} error={error} />
      )}
    </>
  ) : null
}

export default LeadViewRouter
