import { getPayForCheckMutation } from './graphqlQueries'
import {
  AppliedDiscount,
  Invoice,
  InvoiceResponse,
  Order
} from '../types/InvoiceTypes'
import {
  EventResponse,
  PublicEventTypeResponse,
  PublicLocationsResponse
} from '../types/EventTypes'
import { CreateLead, FileResponse, LeadResponse } from '../types/LeadTypes'
import React, { useEffect, useState } from 'react'
import {
  apiGet,
  apiPost,
  fetchGraphql,
  apiEmptyPost,
  apiFilePost,
  apiPatch
} from './util'
import { AccrualRequest, EnrollmentRequest } from '../types/DataProviderTypes'
import { CustomerLoyaltyConfig } from '../components/Loyalty/loyalty'
import _ from 'lodash'
import { Address } from '@toasttab/buffet-patterns-address-lookup'

async function invoiceApiGet(path: string, headers = {}) {
  return apiGet(`invoice-service/v1${path}`, headers)
}

export async function invoiceApiPost(
  path: string,
  body?: any,
  restaurantGuid?: string
) {
  const headers = restaurantGuid
    ? {
        'toast-restaurant-external-id': restaurantGuid
      }
    : {}

  return apiPost(`invoice-service/v1${path}`, JSON.stringify(body), headers)
}

export async function invoiceApiPatch(
  path: string,
  body?: any,
  restaurantGuid?: string
) {
  const headers = restaurantGuid
    ? {
        'toast-restaurant-external-id': restaurantGuid
      }
    : {}
  return apiPatch(`invoice-service/v1${path}`, JSON.stringify(body), headers)
}

export interface FetchInvoiceParams {
  token: string
  lookup3pAccount: boolean
  isEventInvoice?: boolean
}

export async function fetchInvoice({
  token,
  lookup3pAccount,
  isEventInvoice
}: FetchInvoiceParams): Promise<InvoiceResponse> {
  const searchParams: Record<string, string> = {
    token,
    lookup3pAccount: `${lookup3pAccount}`,
    isEventInvoice: `${!!isEventInvoice}`
  }
  return invoiceApiGet(`/public/invoices?${new URLSearchParams(searchParams)}`)
}

export async function resendInvoiceEmail(token: string): Promise<Response> {
  return apiEmptyPost(
    `invoice-service/v1/public/invoices/resend?token=${token}`
  )
}

export async function resendEstimateEmail(token: string): Promise<Response> {
  return apiEmptyPost(`invoice-service/v1/public/events/resend?token=${token}`)
}

export async function payInvoice(
  invoice: Invoice,
  order: Order,
  creditCardData: any,
  tipAmount: number,
  paymentAmount?: number | undefined
) {
  const res = await fetchGraphql(getPayForCheckMutation(), {
    input: {
      checkGuid: order.checks[0].guid,
      orderGuid: order.guid,
      restaurantGuid: invoice.restaurantGuid,
      newCardInput: creditCardData,
      email: invoice.customer.email,
      tipAmount,
      amount: paymentAmount // if undefined consumer-app-bff just pays the remaining balance
    }
  })

  if (!res.data) {
    throw Error(
      'Payment failed due to an error. Try again later or contact the restaurant.'
    )
  }

  if (res.data.payForCheck.__typename === 'PayForCheckError') {
    throw res.data.payForCheck
  }
}

export async function fetchEvent(token: string): Promise<EventResponse> {
  return invoiceApiGet(`/public/events?token=${token}`)
}

export async function postConfirmEvent(
  token: string,
  signature: string | null
): Promise<EventResponse> {
  return invoiceApiPost(`/public/events/confirm?token=${token}`, { signature })
}

export async function postCreateLead(
  request: CreateLead
): Promise<LeadResponse> {
  return invoiceApiPost(`/public/leads`, request)
}

async function getEventType(
  restaurantTypeGuid: string,
  eventTypeGuid: string
): Promise<PublicEventTypeResponse> {
  return invoiceApiGet(`/public/eventTypes/${eventTypeGuid}`, {
    'Toast-Restaurant-External-ID': restaurantTypeGuid
  })
}

async function getLocations(
  managementSetGuid: string
): Promise<PublicLocationsResponse[]> {
  const res = await invoiceApiGet(`/public/leads/locations`, {
    'Toast-Management-Set-GUID': managementSetGuid
  })
  return res.locations
}

// distance between two lat/long coords
// https://stackoverflow.com/questions/27928/calculate-distance-between-two-latitude-longitude-points-haversine-formula
function distance(lat1: number, lon1: number, lat2: number, lon2: number) {
  const r = 6371 // km
  const p = Math.PI / 180

  const a =
    0.5 -
    Math.cos((lat2 - lat1) * p) / 2 +
    (Math.cos(lat1 * p) *
      Math.cos(lat2 * p) *
      (1 - Math.cos((lon2 - lon1) * p))) /
      2

  const km = 2 * r * Math.asin(Math.sqrt(a))
  return km * 0.621371 // convert to miles
}

export const useLocationOptions = (
  managementSetGuid: string | undefined,
  address: Address | undefined,
  enabled: boolean
) => {
  const [locations, setLocations] = useState<
    PublicLocationsResponse[] | undefined
  >(undefined)
  const [error, setError] = useState<Error | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!managementSetGuid || !enabled) return
    setIsLoading(true)
    getLocations(managementSetGuid)
      .then((response) => setLocations(response))
      .catch((err) => {
        setLocations(undefined)
        setError(err)
      })
      .finally(() => setIsLoading(false))
  }, [managementSetGuid])

  const locationsWithDistance =
    locations?.map((location) => {
      if (!address?.latitude || !address?.longitude) {
        return location
      }
      if (!location.branding.latitude || !location.branding.longitude) {
        return {
          ...location,
          distance: null
        }
      }
      return {
        ...location,
        distance: distance(
          address.latitude,
          address.longitude,
          location.branding.latitude,
          location.branding.longitude
        )
      }
    }) || []

  const sortedLocations = _.sortBy(locationsWithDistance, (location) => {
    // i'm not sure if it's ever relevant in practice, but lat/long is nullable in the types so...
    // undefined means the user hasn't entered an address, so we want it to sort in original order returned by backend
    // null means the restaurant has no lat/long, so we want it to be at the end of the sort order
    if (location.distance === undefined) {
      return 0
    }
    if (location.distance === null) {
      return 25000
    }
    return location.distance
  })

  return { locations: sortedLocations, isLoading, error }
}

export const useEventType = (
  restaurantTypeGuid?: string,
  eventTypeGuid?: string
) => {
  const [eventType, setEventType] = useState<PublicEventTypeResponse | null>(
    null
  )
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    if (!eventTypeGuid || !restaurantTypeGuid) {
      return
    }

    getEventType(restaurantTypeGuid, eventTypeGuid)
      .then((response) => setEventType(response))
      .catch((err) => {
        console.error('Failed to fetch event type', err.message)
        setError(err)
      })
  }, [restaurantTypeGuid, eventTypeGuid])

  return { eventType, error }
}

export const enrollCustomerForLoyalty = (
  request: EnrollmentRequest,
  restaurantGuid: string
): Promise<CustomerLoyaltyConfig> => {
  return invoiceApiPost(`/public/loyalty/enroll`, request, restaurantGuid)
}

export const applyCheckRedemption = (
  request: AppliedDiscount,
  restaurantGuid: string,
  token: string
): Promise<InvoiceResponse> => {
  return invoiceApiPost(
    `/public/invoices/redeem?token=${token}`,
    request,
    restaurantGuid
  )
}

export const accrueLoyaltyOnOrder = (
  request: AccrualRequest,
  restaurantGuid: string
): Promise<CustomerLoyaltyConfig> => {
  return invoiceApiPost(
    `/api/service/invoice-service/v1/public/loyalty/accrue`,
    request,
    restaurantGuid
  )
}

export const saveFileToRx = async (
  restaurantGuid: string,
  file: File
): Promise<FileResponse> => {
  const formData = new FormData()
  const fileName = file.name.replace(/ /g, '_')
  formData.append('file', file, fileName)

  return apiFilePost(`invoice-service/v1/public/files`, formData, {
    'toast-restaurant-external-id': restaurantGuid
  })
}

export const handleLoyaltyEnrollment = (
  loyaltyEnrollmentRequest: EnrollmentRequest | undefined,
  invoice: Invoice,
  isEnrolled: boolean | undefined,
  setEnrolledInLoyalty: React.Dispatch<React.SetStateAction<boolean>>,
  setLoyaltyError: React.Dispatch<React.SetStateAction<string | null>>,
  captureException: (error: Error) => void
): void => {
  if (loyaltyEnrollmentRequest) {
    enrollCustomerForLoyalty(loyaltyEnrollmentRequest, invoice.restaurantGuid)
      .then(() => setEnrolledInLoyalty(true))
      .catch((err) => {
        console.log(err)
        captureException(err)
        setLoyaltyError(
          'Something went wrong. Failed to enroll in loyalty program'
        )
      })
  } else if (isEnrolled && !invoice.order.checks[0]!!.appliedLoyaltyInfo) {
    accrueLoyaltyOnOrder(
      { orderGuid: invoice.order.guid },
      invoice.restaurantGuid
    ).catch((err) => {
      console.log(err)
      captureException(err)
      setLoyaltyError(
        'Something went wrong. Failed to accrue loyalty for order'
      )
    })
  }
}
