import {
  getCurrentEnvironment,
  ToastEnvironment
} from '@toasttab/current-environment'
import { useScript } from 'usehooks-ts'
import { SpiSdk, SpiSdkLoadingState } from '../types/SPI'
import { useSentry } from 'banquet-runtime-modules'

const getSpiSdkSrc = () => {
  const env = getCurrentEnvironment(window.location.hostname)
  switch (env) {
    case ToastEnvironment.PREPROD:
      return 'https://payments.preprod.eng.toasttab.com/assets/loader.js'
    case ToastEnvironment.SANDBOX:
      return 'https://payments.sandbox.eng.toasttab.com/assets/loader.js'
    case ToastEnvironment.DEV:
    case ToastEnvironment.TEST:
      return 'https://dev.eng.toastteam.com/loader.js'
    case ToastEnvironment.PROD:
    default:
      return 'https://payments.toasttab.com/assets/loader.js'
  }
}

export const useSpiSdk = () => {
  const { captureMessage } = useSentry()
  const res = useScript(getSpiSdkSrc(), { removeOnUnmount: false })
  if (res === 'error') {
    console.log('Failed to load Toast SPI SDK!')
    captureMessage('Failed to load Toast SPI SDK!' + res)
  }
  return res
}

export const useGetSpiSdk = (): SpiSdk | undefined => {
  const isLoaded = useSpiSdk()
  return isLoaded === SpiSdkLoadingState.READY ? window.Toast : undefined
}
