import React from 'react'
import { CardContainer } from '@toasttab/buffet-pui-card'
import { LoyaltyDisplayIcon } from '@toasttab/buffet-pui-icons'
import { useInvoice } from '../InvoiceView/InvoiceProvider'

export default function LoyaltyConfirmation() {
  const { enrolledInLoyalty, isPaid } = useInvoice()

  if (!enrolledInLoyalty || !isPaid) {
    return <></>
  }

  return (
    <div>
      <CardContainer
        className={'bg-primary-0 text-center'}
        verticalEdge={'flush'}
      >
        <LoyaltyDisplayIcon />
        <div className={'text-primary-75 type-headline-5 font-bold mb-4 mt-2'}>
          You earned points with this order!
        </div>
        <div className={'mb-2'}>Check your loyalty account for details</div>
      </CardContainer>
    </div>
  )
}
