import { useSentry } from 'banquet-runtime-modules'

export function useConfigureSentryRx() {
  const { hub } = useSentry()

  return ({ guid, name }: { guid: string; name: string }) => {
    hub.configureScope((scope) => {
      scope.setTag('restaurantGuid', guid)
      scope.setTag('restaurantName', name)
    })
  }
}

export function useConfigureSentryInvoice() {
  const { hub } = useSentry()

  return ({ guid }: { guid: string }) => {
    hub.configureScope((scope) => {
      scope.setTag('invoiceGuid', guid)
    })
  }
}

export function useConfigureSentryEvent() {
  const { hub } = useSentry()

  return ({ guid }: { guid: string }) => {
    hub.configureScope((scope) => {
      scope.setTag('eventGuid', guid)
    })
  }
}
