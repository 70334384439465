import React from 'react'
import { BanquetFeatureFlagsProvider } from '@toasttab/banquet-feature-flags'
import { useInvoice } from './InvoiceView/InvoiceProvider'

export const InvoiceFlagProvider = ({
  children
}: React.PropsWithChildren<{}>) => {
  const invoice = useInvoice()

  return (
    <BanquetFeatureFlagsProvider
      user={invoice ? { key: invoice.invoice.restaurantGuid } : undefined}
      deferInitialization
    >
      {children}
    </BanquetFeatureFlagsProvider>
  )
}
