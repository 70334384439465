import React, { useEffect } from 'react'
import { RestaurantBranding } from '../types/InvoiceTypes'
import { useSearchParams } from 'react-router-dom'
import {
  defaultStrings,
  getCountry,
  checkCountryIsoCode,
  CountryIsoCode
} from '@toasttab/buffet-pui-country-utilities'

export const useQueryParams = () => {
  const [urlSearchParams] = useSearchParams()
  return Object.fromEntries(urlSearchParams.entries())
}

export const printAddressBranded = ({
  restaurantBranding,
  singleLine
}: {
  restaurantBranding: RestaurantBranding
  singleLine: boolean
}) => {
  const countryInfo = restaurantBranding.country
    ? getCountry(
        checkCountryIsoCode(restaurantBranding.country as CountryIsoCode),
        defaultStrings
      )
    : null
  const secondLine = intlCountries.includes(restaurantBranding.country || '')
    ? `${restaurantBranding.city}, ${restaurantBranding.zip}`
    : `${restaurantBranding.city}, ${restaurantBranding.state} ${restaurantBranding.zip}`
  return (
    <span>
      <span className={singleLine ? 'inline' : 'block'}>{`${
        restaurantBranding.address1
      } ${restaurantBranding.address2 || ''} `}</span>
      {secondLine}
      {countryInfo && <span>{`, ${countryInfo.name}`}</span>}
    </span>
  )
}

// A list of countries considered "International" for use in presenting Adyen for payment
export const intlCountries = ['CA', 'IE', 'GB']

export const taxIdentificationNames = {
  CA: 'Business Number',
  IE: 'VAT Number',
  GB: 'VAT Number',
  US: 'Tax Number'
}

export const useTitle = (title: string) => {
  useEffect(() => {
    const originalTitle = document.title
    document.title = title
    return () => {
      document.title = originalTitle
    }
  })
}
