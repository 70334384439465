import { App } from './app/App'
import { singleSpaCssLifecycles } from '@toasttab/banquet-single-spa-css'
import { banquetSingleSpaReact } from 'banquet-runtime-modules'
import './index.css'

const reactLifecycles = banquetSingleSpaReact({
  cssScope: 'nvs-inv-pay',
  singleSpaCssLifecycles,
  rootComponent: App,

  sentry: {
    publicKey: '84f6403cac364a9db9645b41cec8a45e@o37442',
    projectId: '6071217',
    releaseVersion: process.env.PKG_VERSION
  }
})

export const bootstrap = reactLifecycles.bootstrap
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
