import {
  CountryIsoCode,
  checkCountryIsoCode,
  defaultStrings,
  getCountry
} from '@toasttab/buffet-pui-country-utilities'
import { Address } from '../types/CateringLocationTypes'

/*
 * Formats an address on a single line. Assumes address line 1 exists. Country is
 * not included by default.
 * Example: 444 Search Road, Apt 1, Lake Lure, NC
 * Example: 123 St Stephen's Green, Floor 2, Dublin, Dublin, Ireland
 */
export const formatAddress = (
  address: Address,
  includeCountry: boolean = false
) => {
  let str = address.address1
  const countryInfo = address.country
    ? getCountry(
        checkCountryIsoCode(address.country as CountryIsoCode),
        defaultStrings
      )
    : null
  if (address.address2) {
    str += ', ' + address.address2
  }
  if (address.city) {
    str += ', ' + address.city
  }
  if (address.state) {
    str += ', ' + address.state
  }
  if (address.zip) {
    str += ', ' + address.zip
  }
  if (countryInfo && includeCountry) {
    str += ', ' + countryInfo.name
  }
  return str
}

/*
 * Gets a "miles away" formatted string for the given distance.
 * If distance is < 100, rounds to nearest tenth (8.6 miles)
 * If distance is >= 100, rounds to nearest whole number (120 miles)
 * If distance is >= 1000, thousands separator is used (locale-dependent) (1,514 miles)
 * If distance is 0, returns no string
 */
export const getFormattedMilesAway = (distanceMiles?: number | null) => {
  if (distanceMiles === undefined || distanceMiles === null) return undefined
  const distancedRounded =
    distanceMiles < 100
      ? Math.round(distanceMiles * 10) / 10
      : Math.round(distanceMiles)

  if (distancedRounded === 0) return undefined
  return `${distancedRounded.toLocaleString()} ${
    distancedRounded === 1 ? 'mile' : 'miles'
  } away`
}
