import React, { ReactNode } from 'react'
import { HelperText, HelperTextProps } from '@toasttab/buffet-pui-text-base'

interface RequiredEntryProps extends HelperTextProps {
  showError: boolean
  errorMessage?: string | ReactNode
  id?: string
  className?: string
}

const RequiredEntry = (props: React.PropsWithChildren<RequiredEntryProps>) => {
  return (
    <div id={props.id} className={props.className}>
      {props.children}
      {props.showError && (
        <HelperText invalid errorText={props.errorMessage} {...props} />
      )}
    </div>
  )
}

export default RequiredEntry
