import {
  ChecksEntity,
  PaymentEntity,
  PaymentStatus,
  SelectionsEntity
} from '../types/InvoiceTypes'
import { LineItem } from '../types/SPI'

export const getCheckBalance = (check?: ChecksEntity): number => {
  if (!check || !check.payments) return 0
  let amountDue =
    Math.round(check.totalAmount * 100) - Math.round(check.tipAmount * 100)
  check.payments.forEach((payment) => {
    if (paymentCountsTowardsBalance(payment)) {
      amountDue = amountDue - (Math.round(payment.amount * 100) ?? 0)
    }
  })
  return amountDue / 100
}

export const paymentCountsTowardsBalance = (
  payment: PaymentEntity
): boolean => {
  if (
    payment.paymentStatus === PaymentStatus.DENIED ||
    payment.paymentStatus === PaymentStatus.ERROR ||
    payment.paymentStatus === PaymentStatus.ERROR_NETWORK ||
    payment.paymentStatus === PaymentStatus.OPEN ||
    payment.paymentStatus === PaymentStatus.PROCESSING_VOID ||
    payment.paymentStatus === PaymentStatus.VOIDED ||
    payment.paymentStatus === PaymentStatus.VOIDED_AT_RISK ||
    payment.paymentStatus === PaymentStatus.CANCELLED ||
    payment.paymentStatus === PaymentStatus.PROCESSING
  ) {
    return false
  }

  return true
}

export const isItemFullyRefunded = (selection: SelectionsEntity) => {
  if (!selection.refundDetails?.refundAmount) return false
  return selection.refundDetails.refundAmount === selection.price
}

export const getItemRefundQty = (selection: SelectionsEntity) => {
  if (!selection.refundDetails?.refundAmount) return 0
  return Math.round(
    selection.refundDetails.refundAmount /
      (selection.price / selection.quantity)
  )
}

export const getItemQtyAfterRefund = (lineItem: SelectionsEntity) => {
  return lineItem.quantity - getItemRefundQty(lineItem)
}

export const getPaymentRefund = (payment: PaymentEntity) => {
  if (!payment.refund) return 0
  return payment.refund.refundAmount + payment.refund.tipRefundAmount
}

type TaxInfo = {
  amount: number
  name: string
  rate: number
}

/**
 * collateTaxesFromCheck
 * @param check {ChecksEntity} - A check containing selections and service charges
 * @returns TaxInfo[] - An array of tax information grouped by percent
 */
export const collateTaxesFromCheck = (check: ChecksEntity): TaxInfo[] => {
  // Find all taxes on selections
  const taxes = check.selections
    ?.filter((lineItem) => !lineItem.voided)
    .reduce<Record<string, TaxInfo>>((acc, item) => {
      if (!item.appliedTaxes?.length) {
        return acc
      }
      for (const tax of item.appliedTaxes) {
        if (!tax) {
          continue
        }
        const rate = tax.rate || 0
        const id = `${tax.name} ${rate}`
        const name = tax.name
        const amount = tax?.taxAmount || 0
        if (acc[id]) {
          acc[id].amount += amount
        } else {
          acc[id] = {
            amount,
            rate,
            name
          }
        }
      }
      return acc
    }, {})

  // Apply any service charge taxes
  if (check?.appliedServiceCharges && check?.appliedServiceCharges.length) {
    for (const serviceCharge of check?.appliedServiceCharges) {
      for (const tax of serviceCharge.appliedTaxes) {
        const rate = tax.rate || 0
        const id = `${tax.name} ${rate}`
        const name = tax.name
        const amount = tax?.taxAmount || 0
        if (taxes[id]) {
          taxes[id].amount += amount
        } else {
          taxes[id] = {
            amount,
            rate,
            name
          }
        }
      }
    }
  }

  // Convert to a sorted array from highest rate to lowest
  return Object.values(taxes)
    .reduce<TaxInfo[]>((acc, tax) => {
      acc.push(tax)
      return acc
    }, [])
    .sort((a, b) => b.rate - a.rate)
}

export const getSPILineItems = (
  paymentAmount: number,
  tipAmount: number
): LineItem[] => {
  return [
    {
      label: 'Subtotal',
      amount: paymentAmount || 0
    },
    {
      label: 'Tip',
      amount: tipAmount
    }
  ]
}
