import React, { useMemo } from 'react'
import { format, Formats, parseISO } from '@toasttab/buffet-pui-date-utilities'
import { useEvent } from '../EventView/EventProvider'
import { useBuffetContext } from '@toasttab/buffet-pui-context-provider'
import { CalendarTodayIcon, PrepTimeIcon } from '@toasttab/buffet-pui-icons'

const EN_DASH = '–'

interface EventPortalSubheader {
  eventName: string
}

export const EventPortalSubheader: React.FC<EventPortalSubheader> = ({
  eventName
}) => {
  const { event } = useEvent()
  const { locale } = useBuffetContext()

  const {
    formattedStartDate,
    formattedStartTime,
    formattedEndDate,
    formattedEndTime
  } = useMemo(() => {
    const date: Date = parseISO(event.date!)
    const endDate = event.endDate ? parseISO(event.endDate) : undefined

    return {
      formattedStartDate: format(date, Formats.date.long, locale),
      formattedStartTime: format(date, Formats.time.short, locale),
      formattedEndDate: endDate
        ? format(endDate, Formats.date.long, locale)
        : undefined,
      formattedEndTime: endDate
        ? format(endDate, Formats.time.short, locale)
        : undefined
    }
  }, [event.date, locale])

  return (
    <div className={'flex flex-col gap-y-2'}>
      <div
        className={
          'flex flex-col md:flex-row flex-wrap gap-x-8 gap-y-2 mb-4 tsw-font-family text-color-ds order-last md:order-first'
        }
      >
        <span className={'flex items-center gap-2'}>
          <CalendarTodayIcon accessibility={'decorative'} />{' '}
          {formattedStartDate}
          {formattedEndDate && formattedEndDate !== formattedStartDate
            ? ` ${EN_DASH} ${formattedEndDate}`
            : ''}
        </span>
        <span className={'flex items-center gap-2'}>
          <PrepTimeIcon accessibility={'decorative'} /> {formattedStartTime}
          {formattedEndTime ? ` ${EN_DASH} ${formattedEndTime}` : ''}
        </span>
      </div>
      <h2
        className={
          'type-headline-3 md:type-headline-1 font-semibold transition-[font-size] tsw-header-font-family text-color-ds'
        }
      >
        {eventName}
      </h2>
    </div>
  )
}
