import React from 'react'
import CreditCardForm from './CreditCardForm'
import { useData } from './DataProvider'
import { useInvoice } from './InvoiceProvider'
import { intlCountries } from '../../utils/utils'
import type { I18n } from '../../types/RestaurantTypes'
import { formatCurrency } from '@toasttab/buffet-pui-number-utilities'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { useFlags } from '@toasttab/banquet-feature-flags'
import SPICreditCardForm from './SPICreditCardForm'
import { AdyenPayment } from '@toasttab/do-secundo-adyen-payment'
import { postPayment } from '../../api/paymentIntents'
import { useSentry } from 'banquet-runtime-modules'

export default function InvoicePay() {
  const { restaurant } = useData()
  const { restaurantBranding } = useData()
  const {
    isPaid,
    isVoid,
    isDeposit,
    paymentAmount,
    invoice,
    isTestMode,
    checkBalance,
    tipAmount,
    paymentOrder
  } = useInvoice()
  const { previousPayments } = useData()
  const [loyaltyError, setLoyaltyError] = React.useState<string | null>(null)
  const [paymentError, setPaymentError] = React.useState<string | null>(null)

  const { nvsSpi } = useFlags()
  const hasSpi = Boolean(nvsSpi)
  const { captureMessage } = useSentry()
  if (isPaid || isVoid) {
    // if an error occurred in the cc form but the payment went through
    // (ex: loyalty enrollment failed), this will display that error without
    // prompting the customer to pay again
    return (
      <>
        {loyaltyError && (
          <p className={'pb-3 text-center type-default text-error mt-2'}>
            {loyaltyError}
          </p>
        )}
      </>
    )
  }

  const { country, currency, locale } = restaurant.i18n
    ? restaurant.i18n
    : ({} as I18n)
  const isIntlRx = intlCountries.includes(country)
  const MIN_ADYEN_AMOUNT = 0.1

  const amountToPay = isDeposit ? paymentAmount : checkBalance

  return (
    <div className={'px-8 py-6'}>
      <h3 className={'type-large mb-3'}>
        {isDeposit && !previousPayments.length
          ? 'Pay this deposit'
          : 'Pay this invoice'}
      </h3>
      {isTestMode && (
        <Alert className={'w-full'} variant={'warning'}>
          <span>
            The restaurant is in test mode and will not receive any payments.
            Please contact the restaurant for more details.
          </span>
        </Alert>
      )}
      {!isIntlRx ? (
        <>
          {!hasSpi && (
            <CreditCardForm
              restaurant={restaurant}
              restaurantBranding={restaurantBranding}
              setLoyaltyError={setLoyaltyError}
              loyaltyError={loyaltyError}
            />
          )}
          {hasSpi && (
            <SPICreditCardForm
              restaurant={restaurant}
              restaurantBranding={restaurantBranding}
              setLoyaltyError={setLoyaltyError}
              loyaltyError={loyaltyError}
              currency={currency}
            />
          )}

          <p className={'pt-4 text-center type-subhead text-secondary'}>
            Information will be processed pursuant to Toast’s{' '}
            <a
              className={'text-link'}
              href={'https://pos.toasttab.com/terms-of-service'}
              target={'_blank'}
              rel='noreferrer'
            >
              Terms of Service
            </a>{' '}
            and{' '}
            <a
              className={'text-link'}
              href={'https://pos.toasttab.com/privacy'}
              target={'_blank'}
              rel='noreferrer'
            >
              Privacy Statement
            </a>
          </p>
        </>
      ) : amountToPay > MIN_ADYEN_AMOUNT ? (
        <>
          <AdyenPayment
            payment={{
              checkAmount: amountToPay,
              tipAmount: tipAmount,
              emailAddress: invoice.customer.email
            }}
            config={{
              currency: currency,
              locale: locale,
              country: country,
              guid: invoice.restaurantGuid,
              sourceApp: 'INVOICING'
            }}
            onPaymentCompleted={async (paymentIntentID) => {
              postPayment(
                paymentOrder.guid,
                {
                  paymentGuid: paymentIntentID,
                  paidDate: new Date().toISOString(),
                  amount: isDeposit ? paymentAmount : checkBalance,
                  tipAmount: tipAmount,
                  paymentTypeEnum: 'CREDIT',
                  cardProcessorType: 'semi-integrated'
                },
                invoice.restaurantGuid
              ).catch((error) => {
                captureMessage('Failed to link Adyen Payment:' + error)
                setPaymentError('Something went wrong.')
              })
            }}
            onPaymentError={() => {
              setPaymentError(
                'There was an issue processing your payment. Please try again.'
              )
            }}
          />
          <p className={'pt-4 text-center type-subhead text-secondary'}>
            I understand my information will be used to process this payment and
            for other purposes outlined in Toast’s{' '}
            <a
              className={'text-link'}
              href={'https://pos.toasttab.com/privacy'}
              target={'_blank'}
              rel='noreferrer'
            >
              Privacy Statement
            </a>
            , and will also be disclosed to the merchant.
          </p>
          {paymentError && (
            <p className={'pb-3 text-center type-default text-error mt-2'}>
              {paymentError}
            </p>
          )}
        </>
      ) : (
        <p className='text-error text-center pt-4'>
          Minimum payment:{' '}
          {formatCurrency({ amount: amountToPay, currency }, locale)}
        </p>
      )}
    </div>
  )
}
