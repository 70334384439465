import { apiGet } from '../../api/util'
import { useEffect, useState } from 'react'

export interface GoogleAdsConversionTag {
  conversionTagId: string
  eventLabel: string
  eventType: ConversionTagEventType
  conversionName: string
}

export type ConversionTagEventType = 'SUBMIT_LEAD_FORM' | 'PURCHASE'

const getGoogleAdsConversionTag = async (
  managementSetGuid: string
): Promise<GoogleAdsConversionTag[]> => {
  return await apiGet(`brands/v1/google-ads/public/conversion-tags/catering`, {
    'Toast-Management-Set-GUID': managementSetGuid
  })
}

export const useGoogleAdsConversionTags = (
  managementSetGuid: string | undefined
) => {
  const [tags, setTags] = useState<GoogleAdsConversionTag[] | undefined>()

  useEffect(() => {
    if (managementSetGuid) {
      getGoogleAdsConversionTag(managementSetGuid)
        .then((tags) => {
          setTags(tags)
        })
        .catch(() => {
          setTags(undefined)
        })
    }
  }, [managementSetGuid])

  const leadsTags = tags?.filter((tag) => tag.eventType === 'SUBMIT_LEAD_FORM')

  return {
    data: leadsTags && leadsTags.length > 0 ? leadsTags[0] : undefined
  }
}

export interface MetaAdsConversionTag {
  pixelId: string
}

const getMetaAdsConversionTag = async (
  managementSetGuid: string
): Promise<MetaAdsConversionTag> => {
  return await apiGet(`brands/v1/ads-manager/public/meta-ads/pixel`, {
    'Toast-Management-Set-GUID': managementSetGuid
  })
}

export const useMetaAdsConversionTags = (
  managementSetGuid: string | undefined
) => {
  const [tag, setTag] = useState<MetaAdsConversionTag | undefined>()

  useEffect(() => {
    if (managementSetGuid) {
      getMetaAdsConversionTag(managementSetGuid)
        .then((tag) => {
          setTag(tag)
        })
        .catch(() => {
          setTag(undefined)
        })
    }
  }, [managementSetGuid])

  return {
    data: tag
  }
}
