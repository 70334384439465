import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import ExpiredView from '../ExpiredView'
import NotFoundView from '../NotFoundView/NotFoundView'
import EventRoute from '../EventView/EventRoute'
import InvoiceRoute from '../InvoiceView/InvoiceRoute'
import LocationPickerView from '../LocationPickerView/LocationPickerView'
import EstimateExpiredView from '../EstimateExpiredView'
import LeadRoute from '../LeadView/LeadRoute'
import { SessionExpiredModal } from '../Session/SessionExpiredModal'
import { EventPortalRoute } from '../EventPortalView/EventPortalRoute'

// Required for any pages that make a call to the graphql API
const SessionWrapper = ({ children }: React.PropsWithChildren) => (
  <>
    <SessionExpiredModal />
    {children}
  </>
)

export const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='invoice/expired' element={<ExpiredView />} />
        <Route
          path='invoice/estimateExpired'
          element={<EstimateExpiredView />}
        />
        <Route path='invoice/lead' element={<LeadRoute />} />
        <Route
          path='invoice/portal/:id'
          element={
            <SessionWrapper>
              <EventPortalRoute />
            </SessionWrapper>
          }
        />
        <Route
          path='invoice/event/:id'
          element={
            <SessionWrapper>
              <EventRoute />
            </SessionWrapper>
          }
        />
        <Route
          path='invoice/:id'
          element={
            <SessionWrapper>
              <InvoiceRoute />
            </SessionWrapper>
          }
        />
        <Route path='invoice/' element={<NotFoundView />} />
        <Route
          path='catering/locations/:mgmtGuid'
          element={<LocationPickerView />}
        />
      </Routes>
    </BrowserRouter>
  )
}

export default Router
