import React from 'react'
import { BanquetFeatureFlagsProvider } from '@toasttab/banquet-feature-flags'
import LeadViewRouter from './LeadViewRouter'
import { useQueryParams } from '../../utils/utils'

export default function LeadRoute() {
  const params = useQueryParams()
  return (
    <BanquetFeatureFlagsProvider user={{ key: params.rx }} deferInitialization>
      <LeadViewRouter />
    </BanquetFeatureFlagsProvider>
  )
}
