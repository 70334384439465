import React from 'react'
import InvoiceHeader from './InvoiceHeader'
import InvoiceRxInfo from './InvoiceRxInfo'
import InvoiceOverview from './InvoiceOverview'
import InvoiceMemo from './InvoiceMemo'
import InvoicePay from './InvoicePay'
import InvoicePaymentConfirmation from './InvoicePaymentConfirmation'
import InvoiceSummary from '../InvoiceSummary/InvoiceSummary'
import InvoiceVoidView from './InvoiceVoidView'
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary'
import { useData } from './DataProvider'
import { useInvoice } from './InvoiceProvider'
import LoyaltyConfirmation from '../Loyalty/LoyaltyConfirmation'
import Rewards from '../Loyalty/Rewards'

export default function InvoiceView() {
  const { restaurantBranding } = useData()
  const { invoice, isVoid } = useInvoice()

  React.useEffect(() => {
    document.title = `Invoice #${invoice.invoiceNumber} - ${restaurantBranding.name}`
  }, [invoice, restaurantBranding])

  return (
    <>
      <div className={'bg-white rounded'}>
        <ErrorBoundary fallback={null}>
          <InvoiceHeader />
        </ErrorBoundary>
        <ErrorBoundary
          fallbackMessage={
            'Sorry, we are unable to load the restaurant details.'
          }
          fallbackClassName={'px-8 py-6'}
        >
          <InvoiceRxInfo />
        </ErrorBoundary>
        <ErrorBoundary
          fallbackMessage={'Sorry, we are unable to load the invoice overview.'}
          fallbackClassName={'bg-gray-0 px-8 py-6'}
        >
          <InvoiceOverview />
        </ErrorBoundary>

        <ErrorBoundary
          fallback={
            <p className={'type-large text-secondary'}>
              This invoice was paid successfully.
            </p>
          }
          fallbackClassName={'bg-gray-0 px-8 py-6'}
        >
          <InvoicePaymentConfirmation />
        </ErrorBoundary>

        <ErrorBoundary
          fallback={
            <p className={'type-large text-secondary'}>
              This invoice was voided by the restaurant.
            </p>
          }
          fallbackClassName={'px-8 py-8 bg-gray-0'}
        >
          <InvoiceVoidView />
        </ErrorBoundary>

        <ErrorBoundary
          fallbackMessage={'Sorry, we are unable to load the invoice’s memo.'}
          fallbackClassName={'px-8 py-6'}
        >
          <InvoiceMemo />
        </ErrorBoundary>
        <Rewards />
      </div>
      <div className={'bg-white rounded mt-6'}>
        <ErrorBoundary
          fallbackMessage={
            'Sorry, we are unable to load the credit card payment form.'
          }
          fallbackClassName={'px-8 py-6'}
        >
          <InvoicePay />
        </ErrorBoundary>
      </div>
      {!isVoid && <InvoiceSummary />}
      <LoyaltyConfirmation />
    </>
  )
}
