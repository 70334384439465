import React, { useMemo } from 'react'
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary'
import EventTimelineTable from '../EventView/EventTimelineTable'
import OrderSummaryTable from '../InvoiceSummary/OrderSummaryTable'
import EventContract from '../EventView/EventContract'
import EventSignature from '../EventView/EventSignature'
import { EventPortalSubheader } from './EventPortalSubheader'
import { useEvent } from '../EventView/EventProvider'
import { useEventType } from '../../api/invoices'
import { ThemedLayout } from '../shared/ThemedLayout/ThemedLayout'
import { WrapInCards } from '../shared/Card/WrapInCards'
import { ThemedLayoutMainContent } from '../shared/ThemedLayout/ThemedLayoutMainContent'
import { ThemedLayoutSideContent } from '../shared/ThemedLayout/ThemedLayoutSideContent'
import EventFormFieldsTable from '../EventView/EventFormFieldsTable'
import { LeadContactInfo } from '../shared/Lead/LeadContactInfo'
import { Card } from '../shared/Card/Card'
import { Helmet } from 'react-helmet-async'
import { EventPortalStatus } from './EventPortalStatus'
import { Badge } from '@toasttab/buffet-pui-badge'
import { EventPortalSection } from './EventPortalSection'
import classNames from 'classnames'
import { useBrandingThemeEnabled } from '../../hooks/useBrandingThemeEnabled'
import ConfirmEstimateButton from '../EventView/ConfirmEstimateButton'
import { useWindowSize } from 'usehooks-ts'

export const EventPortalView: React.FC = () => {
  const { event, isConfirmed, isOrderVoided } = useEvent()
  const { eventType } = useEventType(event.restaurantGuid, event.eventTypeGuid)
  const brandingThemeEnabled = useBrandingThemeEnabled()
  const { width } = useWindowSize()
  const isMdWidth = width >= 768

  const restaurantBranding = eventType?.restaurantBranding

  const headerImageUrl = eventType?.leadHeaderImageUrl
  const logoImageUrl = restaurantBranding?.logo
  const restaurantName = restaurantBranding?.name

  const hasTimeline =
    event.timeline?.enabled && (event.timeline?.entries?.length ?? 0) > 0

  const eventName = useMemo<string>(
    () =>
      event.name && event.name.length > 0
        ? event.name
        : `${event.customer.firstName} ${event.customer.lastName}’s Event`,
    [event.name, event.customer]
  )

  const subheaderElement = <EventPortalSubheader eventName={eventName} />

  return (
    <ThemedLayout
      subheader={
        <div className={'md:hidden mt-6'} aria-hidden={isMdWidth}>
          {subheaderElement}
        </div>
      }
      name={restaurantName}
      logoImageUrl={logoImageUrl}
      headerImageUrl={headerImageUrl}
    >
      <Helmet>
        <title>
          {`${eventName}${restaurantName ? ` at ${restaurantName}` : ''}`}
        </title>
      </Helmet>
      <ThemedLayoutMainContent className={'mb-60 md:mb-0'}>
        <header className={'hidden md:block'} aria-hidden={!isMdWidth}>
          {subheaderElement}
        </header>
        <WrapInCards brandingEnabled={brandingThemeEnabled}>
          <EventPortalSection title={'Event details'}>
            <EventFormFieldsTable showEventType />
          </EventPortalSection>
          {hasTimeline && (
            <EventPortalSection title={'Timeline'}>
              <EventTimelineTable hideHeading />
            </EventPortalSection>
          )}
          {!isOrderVoided && (
            <EventPortalSection title={'Order details'}>
              <div className={'px-4 md:px-8 pb-8 mt-4'}>
                <ErrorBoundary
                  fallbackMessage={
                    'Sorry, we are unable to load the order summary.'
                  }
                >
                  <OrderSummaryTable />
                </ErrorBoundary>
              </div>
            </EventPortalSection>
          )}
          {!isOrderVoided && event.contract && (
            <EventPortalSection
              title={'Contract'}
              additionalHeaderContent={
                isConfirmed && (
                  <Badge
                    color={'success'}
                    className={classNames(
                      brandingThemeEnabled &&
                        '!bg-color-ds-primary-subtle text-color-ds-on-bg-primary-subtle'
                    )}
                  >
                    Approved
                  </Badge>
                )
              }
              collapsible={isConfirmed}
              collapsedInitially={isConfirmed}
            >
              <EventContract hideHeading cardDisabled />
              {event?.signatureRequired ? (
                <EventSignature brandingEnabled={brandingThemeEnabled} />
              ) : (
                !isConfirmed && (
                  <div className={'px-8 pb-8 mt-4'}>
                    <ConfirmEstimateButton
                      brandingEnabled={brandingThemeEnabled}
                    />
                  </div>
                )
              )}
            </EventPortalSection>
          )}
        </WrapInCards>
      </ThemedLayoutMainContent>
      <ThemedLayoutSideContent
        className={classNames(!!headerImageUrl && '-mt-20')}
      >
        <Card
          brandingEnabled={brandingThemeEnabled}
          shadowDisabled={brandingThemeEnabled}
          className={classNames('!bg-color-ds-primary')}
        >
          <ErrorBoundary
            fallbackMessage={'Sorry, we are unable to load the event overview.'}
            fallbackClassName={'bg-gray-0 px-8 py-6'}
          >
            <EventPortalStatus />
          </ErrorBoundary>
        </Card>
        <Card
          brandingEnabled={brandingThemeEnabled}
          shadowDisabled={brandingThemeEnabled}
          className={classNames('!bg-color-ds-primary-subtle')}
        >
          <EventPortalSection
            title={'Links'}
            className={'mt-4 px-4 md:px-8 pb-8'}
          >
            <ErrorBoundary
              fallbackMessage={
                'Sorry, we are unable to load the restaurant details.'
              }
              fallbackClassName={'px-8 py-6'}
            >
              <LeadContactInfo
                eventType={eventType}
                className={'text-color-ds-on-bg-primary-subtle'}
              />
            </ErrorBoundary>
          </EventPortalSection>
        </Card>
      </ThemedLayoutSideContent>
    </ThemedLayout>
  )
}
