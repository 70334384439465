import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalHeader } from '@toasttab/buffet-pui-modal'
import { ScreenSize, useScreenSize } from '@toasttab/use-screen-size'
import { DateTime } from '../../api/util'
import { Button } from '@toasttab/buffet-pui-buttons'

const utcDateTimeToJsDate = (dateTime: DateTime) => {
  return new Date(
    Date.UTC(
      dateTime.date.year,
      dateTime.date.month - 1,
      dateTime.date.day,
      dateTime.time.hour,
      dateTime.time.minute,
      dateTime.time.second
    )
  )
}

export const SessionExpiredModal = () => {
  const [showModal, setShowModal] = useState(false)

  const sessionExpiresAt =
    window.__TOAST_BANQUET_INITIAL_DATA__?.session?.expiresAt

  useEffect(() => {
    let timerId: any
    const expireTime = sessionExpiresAt
      ? utcDateTimeToJsDate(sessionExpiresAt.dateTime)
      : undefined

    if (!expireTime || expireTime.getTime() < new Date().getTime()) {
      setShowModal(true)
    } else {
      timerId = setTimeout(() => {
        setShowModal(true)
      }, 1000 * 60 * 60) // 1 hour
    }

    return () => clearTimeout(timerId)
  }, [sessionExpiresAt])

  const screenSize = useScreenSize()
  const isMobile = screenSize <= ScreenSize.SM

  return (
    <Modal
      size='md'
      isOpen={showModal}
      position={isMobile ? 'pin-bottom' : 'pin-center'}
    >
      <ModalHeader>
        {sessionExpiresAt ? 'Session expired' : 'An error occurred'}
      </ModalHeader>
      <ModalBody>
        Please refresh the page to renew your session.
        <Button
          className='mt-4 mb-1'
          variant='secondary'
          onClick={() => window.location.reload()}
        >
          Refresh page
        </Button>
      </ModalBody>
    </Modal>
  )
}
