import React from 'react'

const StreetGridIcon = () => (
  <svg viewBox='0 0 433 260' fill='none' xmlns='http://www.w3.org/2000/svg'>
    {/* eslint-disable-next-line max-len */}
    <path
      d='M433 65.8957V49.9318H374.043V0H358.032V49.9318H253.069V0H237.058V49.9318H75.1671V0H59.1558V49.9318H8V65.8957H59.1558V127.978H8V143.942H59.1558V210.458H8V226.422H145.439V260H161.45V226.422H237.058V260H253.069V226.422H433V210.458H253.069V143.942H433V127.978H374.043V65.8957L433 65.8957ZM237.058 65.8957V85.4072H75.1671V65.8957H237.058ZM75.1671 101.371H237.058V127.978H75.1671V101.371ZM75.1671 210.458V143.942H145.439V210.458L75.1671 210.458ZM237.058 210.458H161.45V143.942H237.058V210.458ZM358.032 127.978H253.069V65.8957H358.032V127.978Z'
      fill='#F0F0F0'
    />
    {/* eslint-disable-next-line max-len */}
    <path
      d='M67 61.4219C67 79.8803 33.5 121 33.5 121C33.5 121 0 79.8803 0 61.4219C0 52.5578 3.52943 44.0569 9.8119 37.789C16.0944 31.5212 24.6152 28 33.5 28C42.3847 28 50.9056 31.5212 57.1881 37.789C63.4705 44.0569 67 52.5578 67 61.4219Z'
      fill='rgb(43, 79, 185)'
    />
    {/* eslint-disable-next-line max-len */}
    <path
      d='M49 59.5C49 62.5656 48.1203 65.5624 46.472 68.1113C44.8238 70.6603 42.4811 72.647 39.7403 73.8201C36.9994 74.9933 33.9834 75.3002 31.0736 74.7022C28.1639 74.1041 25.4912 72.6279 23.3934 70.4601C21.2956 68.2924 19.867 65.5306 19.2882 62.5239C18.7094 59.5172 19.0065 56.4007 20.1418 53.5684C21.2771 50.7362 23.1997 48.3154 25.6664 46.6122C28.1332 44.9091 31.0333 44 34 44C35.9701 43.9991 37.921 44.3993 39.7413 45.178C41.5616 45.9566 43.2156 47.0983 44.6086 48.5378C46.0017 49.9773 47.1065 51.6863 47.86 53.5673C48.6135 55.4483 49.0009 57.4642 49 59.5Z'
      fill='white'
    />
    <path
      d='M33.5 143C37.0898 143 40 140.09 40 136.5C40 132.91 37.0898 130 33.5 130C29.9102 130 27 132.91 27 136.5C27 140.09 29.9102 143 33.5 143Z'
      fill='rgb(43, 79, 185)'
    />
    <path
      d='M245 62C247.209 62 249 60.2091 249 58C249 55.7909 247.209 54 245 54C242.791 54 241 55.7909 241 58C241 60.2091 242.791 62 245 62Z'
      fill='rgb(43, 79, 185)'
    />
    <path
      d='M366 141C369.314 141 372 138.314 372 135C372 131.686 369.314 129 366 129C362.686 129 360 131.686 360 135C360 138.314 362.686 141 366 141Z'
      fill='rgb(43, 79, 185)'
    />
    <path
      d='M153 225C156.314 225 159 222.314 159 219C159 215.686 156.314 213 153 213C149.686 213 147 215.686 147 219C147 222.314 149.686 225 153 225Z'
      fill='rgb(43, 79, 185)'
    />
  </svg>
)

export default StreetGridIcon
