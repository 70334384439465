import {
  CreatePaymentIntent,
  PaymentIntentResponse,
  PostPaymentRequest,
  UpdatePaymentIntent
} from '../types/SPI'
import { invoiceApiPatch, invoiceApiPost } from './invoices'

export async function createPaymentIntent(
  request: CreatePaymentIntent,
  restaurantGuid: string
): Promise<PaymentIntentResponse> {
  return invoiceApiPost(`/public/payments`, request, restaurantGuid)
}

export async function updatePaymentIntent(
  paymentIntentId: string,
  request: UpdatePaymentIntent,
  restaurantGuid: string
): Promise<PaymentIntentResponse> {
  return invoiceApiPatch(
    `/public/payments/${paymentIntentId}`,
    request,
    restaurantGuid
  )
}

export async function postPayment(
  orderGuid: string,
  request: PostPaymentRequest,
  restaurantGuid: string
): Promise<Response> {
  return invoiceApiPost(
    `/public/payments/orders/${orderGuid}/payment`,
    request,
    restaurantGuid
  )
}
