const transactionErrorStrings = [
  'Card not authorized. Please try a different card.',
  'Please ensure the credit card information you entered is correct and try again',
  '*REQUEST DENIED*',
  'TRANS DENIED',
  'TRANS NOT ALLOW',
  'DECLINED',
  'CARD EXPIRED',
  'CVD ERROR',
  'POLICY DENIAL',
  'INVALID EXP DATE',
  'INVALID CARD NO',
  'INVALID MERC NO',
  'INV DOLLAR AMT',
  'INV TRANS',
  'EXCEEDS MAX AMT',
  'LIFECYCLE DENIAL',
  'DO NOT HONOR',
  'SECURITY VIOLATION',
  'RE-ENTER TRANSACTION',
  'PICK UP CARD',
  'BAD GENERAL ACCT',
  'FORMAT - BAD CARD',
  "AUTH TRN NO AL'W",
  'AUTH DECLINED',
  'CALL VOICE OPER'
]

export const shouldSilenceError = (message: string): boolean => {
  return transactionErrorStrings.includes(message.trim())
}
