import React from 'react'
import EventFormFieldsTable from './EventFormFieldsTable'
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary'
import InvoiceRxInfo from '../InvoiceView/InvoiceRxInfo'
import EventOverview from './EventOverview'
import OrderSummaryTable from '../InvoiceSummary/OrderSummaryTable'
import { useData } from '../InvoiceView/DataProvider'
import EventContract from './EventContract'
import { useEvent } from './EventProvider'
import EventSignature from './EventSignature'
import EventTimelineTable from './EventTimelineTable'

export default function EventView() {
  const { restaurantBranding } = useData()
  const { event, isConfirmed, isOrderVoided } = useEvent()

  React.useEffect(() => {
    document.title = `Confirm Order - ${restaurantBranding.name}`
  }, [restaurantBranding])

  const isMarginNeeded = Boolean(event?.signatureRequired && !isConfirmed)

  return (
    <div
      className={`bg-white rounded md:mb-0 ${isMarginNeeded ? 'mb-60' : ''}`}
    >
      <ErrorBoundary
        fallbackMessage={'Sorry, we are unable to load the restaurant details.'}
        fallbackClassName={'px-8 py-6'}
      >
        <InvoiceRxInfo />
      </ErrorBoundary>
      <ErrorBoundary
        fallbackMessage={'Sorry, we are unable to load the event overview.'}
        fallbackClassName={'bg-gray-0 px-8 py-6'}
      >
        <EventOverview />
      </ErrorBoundary>
      <EventFormFieldsTable />
      <EventTimelineTable />
      {!isOrderVoided && (
        <>
          <div className={'px-4 md:px-8 pb-8 mt-4'}>
            <ErrorBoundary
              fallbackMessage={
                'Sorry, we are unable to load the order summary.'
              }
            >
              <OrderSummaryTable />
            </ErrorBoundary>
          </div>
          <EventContract />
          <EventSignature />
        </>
      )}
    </div>
  )
}
