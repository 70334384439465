import React from 'react'
import { useInvoice } from '../InvoiceView/InvoiceProvider'
import LoyaltyAccountLookup from './LoyaltyAccountLookup'

export default function Rewards() {
  const { loyaltyVendor, enrolledInLoyalty } = useInvoice()

  if (!loyaltyVendor || loyaltyVendor === 'NONE') {
    return null
  }

  if (loyaltyVendor === 'TOAST' && !enrolledInLoyalty) {
    return null
  }

  return (
    <div className={'px-8 py-6'}>
      <h3 className={'type-large mb-3'}>{'Rewards'}</h3>
      <LoyaltyAccountLookup />
    </div>
  )
}
