import { FileChip } from './FileChip'
import { Button } from '@toasttab/buffet-pui-buttons'
import { Chips } from '@toasttab/buffet-pui-chips'
import {
  FileErrorList,
  FileSelectionList,
  FileSelector,
  FileSelectorButton
} from '@toasttab/buffet-pui-file-selector'
import { AddIcon, ArrowCircleUpIcon } from '@toasttab/buffet-pui-icons'
import { CreatingIllustration } from '@toasttab/buffet-pui-illustrations'
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from '@toasttab/buffet-pui-modal'
import { HelperText } from '@toasttab/buffet-pui-text-base'
import React from 'react'
import { FileResponse } from '../../types/LeadTypes'
import LoadingButton from '../LoadingView/LoadingButton'
import { useSentry } from 'banquet-runtime-modules'

export interface BaseFileInputProps {
  testId?: string
  disabled?: boolean
  className?: string
  accept?: string
  multiple?: boolean
  buttonText?: string
}

export interface FileInputProps extends BaseFileInputProps {
  value: FileResponse[]
  onChange: (files: FileResponse[]) => void
  onSubmit: (files: File[]) => Promise<FileResponse[]>
  helperText?: string
}

export default function FileInput({
  testId: _testId,
  value,
  onChange,
  onSubmit,
  disabled,
  className,
  accept,
  buttonText: _buttonText,
  multiple,
  helperText
}: FileInputProps) {
  const [isOpen, setIsOpen] = React.useState(false)
  const [files, setFiles] = React.useState<File[]>([])
  const [error, setError] = React.useState(null)
  const buttonText = _buttonText || 'Add image'
  const { captureException } = useSentry()

  const testId = _testId || 'FileInput'

  const deleteFileByIndex = (index: number) => {
    onChange([...value.slice(0, index), ...value.slice(index + 1)])
  }

  return (
    <div className={className}>
      {value.length > 0 && (
        <div data-testid={testId} className='mb-2 text-secondary'>
          <Chips testId={`${testId}-chips`}>
            {value.map((file, index) => {
              return (
                <FileChip
                  aria-label='Selected files'
                  key={file.guid}
                  onDelete={() => deleteFileByIndex(index)}
                  href={file.url}
                  label={file.filename}
                  ariaLabel={'Remove ' + file.filename}
                  testId={`${testId}-chip-${index}`}
                  disabled={Boolean(disabled)}
                />
              )
            })}
          </Chips>
        </div>
      )}
      <Button
        testId={`${testId}_Button`}
        onClick={() => {
          setIsOpen(true)
        }}
        iconLeft={<AddIcon />}
        size='auto'
        variant={Button.Variant?.secondary}
        disabled={(!multiple && value.length > 0) || Boolean(disabled)}
      >
        {buttonText}
      </Button>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        testId={`${testId}_Modal`}
        parentSelector={() => {
          const element = document.querySelector('#banquetPortalsContainer')
          element?.setAttribute('nvs-inv-pay', 'true')
          return element
        }}
      >
        <ModalHeader>{buttonText}</ModalHeader>
        <ModalBody className={'mt-2'}>
          {helperText && (
            <div className={'mb-2'}>
              <HelperText helperText={helperText} />
            </div>
          )}
          <FileSelector
            maxSize={10 * 1024 * 1024}
            accept={accept}
            dragDropText={'or drag or drop images'}
            onChange={(event) => {
              setFiles(event.files)
            }}
            multiple={multiple || false}
          >
            <div className={'w-56 mt-4'}>
              <CreatingIllustration resizeToContainer />
            </div>
            <FileSelectorButton
              icon={<ArrowCircleUpIcon />}
              testId={`${testId}_FileSelectorButton`}
            >
              Upload Images
            </FileSelectorButton>
            <FileErrorList testId={`${testId}_FileErrorList`} />
            <FileSelectionList testId={`${testId}_FileSelectionList`} />
          </FileSelector>
          {error && <p className={'text-error mt-2'}>{error}</p>}
        </ModalBody>
        <ModalFooter>
          <>
            <Button variant='link' onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            <LoadingButton
              variant={Button.Variant?.primary}
              disabled={files.length === 0}
              onClick={async () => {
                try {
                  const res = await onSubmit(files)
                  onChange([...value, ...res])
                  setIsOpen(false)
                } catch (err: any) {
                  const error = err as Response
                  const res = await error?.json?.()
                  captureException(
                    new Error('Failed to upload file: ' + res?.message)
                  )
                  setError(
                    res?.message || 'An error occurred, please try again.'
                  )
                }
              }}
            >
              Upload
            </LoadingButton>
          </>
        </ModalFooter>
      </Modal>
    </div>
  )
}
