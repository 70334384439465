import React from 'react'
import { useBuffetContext } from '@toasttab/buffet-pui-context-provider'
import { parseISO, format, Formats } from '@toasttab/buffet-pui-date-utilities'
import LabelItem from '../LabelItem/LabelItem'
import ConfirmEstimateButton from './ConfirmEstimateButton'
import { useEvent } from './EventProvider'
import { useData } from '../InvoiceView/DataProvider'

export default function EventOverview() {
  const { event, isConfirmed, isOrderVoided } = useEvent()
  const { restaurantBranding } = useData()
  const { locale } = useBuffetContext()

  if (isOrderVoided) {
    return (
      <div className={'px-8 space-y-6 py-6 bg-gray-0'}>
        <h2
          className={
            'type-headline-1 font-normal text-primary-100 text-center w-full mb-4'
          }
        >
          VOID
        </h2>
        <p className={'text-center type-large text-secondary'}>
          The event’s order was voided by the restaurant.
        </p>
      </div>
    )
  }

  if (isConfirmed) {
    return (
      <div className={'px-8 space-y-6 py-6 bg-gray-0'}>
        <h2
          className={
            'type-headline-1 font-normal text-primary-100 text-center w-full mb-4'
          }
        >
          Estimate approved!
        </h2>
        <p className={'text-center type-large text-secondary'}>
          You have confirmed your order at {restaurantBranding.name} for{' '}
          {format(parseISO(event.date!), Formats.date.short, locale)}.
        </p>
      </div>
    )
  }

  return (
    <div className={'px-8 space-y-6 py-6 bg-gray-0'}>
      <div>
        {event?.signatureRequired && (
          <a
            href={'#confirmation-button'}
            className={'text-primary-75 font-semibold mb-4 block'}
          >
            Sign the contract at the bottom of this page to confirm this order.
          </a>
        )}
        <div className={'space-x-8 flex'}>
          <LabelItem label={'Estimate For'}>
            {`${event.customer.firstName} ${event.customer.lastName}`}
          </LabelItem>
          <LabelItem label={'Order Date'}>
            {format(parseISO(event.date!), Formats.date.short, locale)}
          </LabelItem>
        </div>
        {!event?.signatureRequired && <ConfirmEstimateButton />}
      </div>
    </div>
  )
}
