import { FormField } from '../../types/EventTypes'
import { ReactElement } from 'react'
import RequiredEntry from './RequiredEntry'
import React from 'react'

export const CustomRequiredEntry = ({
  children,
  errors,
  formField
}: {
  children: ReactElement
  errors?: any
  formField: FormField
}) => {
  if (!formField.guid) {
    return null
  }

  return (
    <RequiredEntry
      id={formField.guid}
      showError={Boolean(errors && formField.guid in errors)}
      errorMessage={errors && errors[formField.guid]}
    >
      {children}
    </RequiredEntry>
  )
}
