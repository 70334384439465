import {
  FormField,
  FormFieldType,
  PublicEventTypeResponse
} from '../../types/EventTypes'
import { RenderFormField } from './RenderFormField'
import { LeadFormValue } from '../../types/LeadTypes'
import { CustomRequiredEntry } from './CustomRequiredEntry'
import React from 'react'

export function areAllRequiredFieldsPopulated(
  formFields: FormField[],
  formValues?: LeadFormValue[],
  showNonInternalFields?: boolean | false
): boolean {
  const requiredFields = showNonInternalFields
    ? formFields.filter(
        (f) => f.required && !f.internal && f.type !== FormFieldType.EMPLOYEE
      )
    : formFields.filter(
        (f) =>
          f.requireOnLead && f.showOnLead && f.type !== FormFieldType.EMPLOYEE
      )

  if (!formValues) {
    return requiredFields.length === 0
  }

  const formValuesMap = new Map(
    formValues.map((obj) => [obj.eventTypeFormFieldGuid, obj])
  )
  return requiredFields.filter((f) => !formValuesMap.has(f.guid)).length === 0
}

export default function BeoForm(props: {
  eventType: PublicEventTypeResponse
  formValues: LeadFormValue[]
  setFormValues: (v: LeadFormValue[]) => void
  errors?: any
}) {
  const { eventType, formValues, setFormValues, errors } = props

  const onChange = (value: string, formFieldGuids: string[]) => {
    if (!formValues) {
      return
    }

    const valueIndex = formValues?.findIndex(
      (formValue) => formFieldGuids[0] === formValue.eventTypeFormFieldGuid
    )

    if (valueIndex < 0 && formValues) {
      setFormValues([
        ...formValues,
        {
          eventTypeFormFieldGuid: formFieldGuids[0],
          value
        }
      ])
      return
    }

    const newFormValues = [...formValues]
    newFormValues[valueIndex].value = value
    setFormValues(newFormValues)
  }

  return (
    <div>
      {eventType.formFields.map((formField) => {
        if (!formField.guid) {
          return null
        }

        const value = formValues?.find(
          (formValue) => formField.guid === formValue.eventTypeFormFieldGuid
        )?.value

        if (!value && formField.deleted) {
          return null
        }

        if (!eventType.showNonInternalFields && !formField.showOnLead) {
          return null
        }

        return (
          <div className='mb-4' key={formField.guid}>
            <CustomRequiredEntry formField={formField} errors={errors}>
              <RenderFormField
                key={formField.guid}
                type={formField.type}
                guids={[formField.guid]}
                options={formField.options}
                value={value}
                onChange={onChange}
                label={formField.label}
                requiredOnLead={
                  eventType.showNonInternalFields
                    ? formField.required
                    : formField.requireOnLead
                }
              />
            </CustomRequiredEntry>
          </div>
        )
      })}
    </div>
  )
}
