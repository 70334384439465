import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useGoogleAdsConversionTags, useMetaAdsConversionTags } from './ads'

interface SubmissionTagProps {
  managementSetGuid: string
}

const SubmissionTag: React.FC<SubmissionTagProps> = ({ managementSetGuid }) => {
  const { data: googleData } = useGoogleAdsConversionTags(managementSetGuid)
  const { data: metaData } = useMetaAdsConversionTags(managementSetGuid)

  if (googleData?.conversionTagId || metaData?.pixelId) {
    return (
      <>
        {googleData?.conversionTagId && (
          <Helmet>
            <script
              async
              src={`https://www.googletagmanager.com/gtag/js?id=${googleData.conversionTagId}`}
            ></script>
            <script>
              {`gtag('event', 'conversion', {
             'send_to': '${googleData.conversionTagId}/${googleData.eventLabel}',
             'value': 1.0,
             'currency': 'USD'
            });
          `}
            </script>
          </Helmet>
        )}
        {metaData?.pixelId && (
          <Helmet>
            <script>
              {`fbq(
            'track', 'Purchase', {
              source_type: 'catering',
              action_source: 'website',
              partner_agent: 'Toast',
              value: 1.0,
              currency: 'USD'
            }
           );`}
            </script>
          </Helmet>
        )}
      </>
    )
  }

  return null
}

export default SubmissionTag
