import React from 'react'
import { useInvoice } from './InvoiceProvider'

export default function InvoiceMemo() {
  const { invoice, isPaid, isVoid } = useInvoice()

  if (!invoice.message || isPaid || isVoid) return null

  return (
    <div className={'px-8 py-6'}>
      <div className={'p-3 bg-gray-0 text-center text-default'}>
        {invoice.message}
      </div>
    </div>
  )
}
