import React, { useState } from 'react'
import { useEvent } from './EventProvider'
import { TextInput } from '@toasttab/buffet-pui-text-input'
import ConfirmEstimateButton from './ConfirmEstimateButton'
import RequiredEntry from '../LeadView/RequiredEntry'
import { HelperText } from '@toasttab/buffet-pui-text-base'
import classNames from 'classnames'

interface EventSignatureProps {
  brandingEnabled?: boolean
}

const EventSignature: React.FC<EventSignatureProps> = ({ brandingEnabled }) => {
  const { event, isConfirmed } = useEvent()
  const [signature, setSignature] = useState('')
  const [error, setError] = useState('')

  if (!event?.signatureRequired || isConfirmed) return null

  const linkClassName = classNames(
    'font-semibold',
    brandingEnabled ? 'text-color-ds-action' : 'text-primary-75'
  )

  return (
    <div className={'px-8 pb-8 mt-4'} id={'confirmation-button'}>
      <div
        className={classNames(
          'md:mb-4 fixed bottom-0 left-0 md:relative w-full shadow-xl md:shadow-none md:bg-transparent p-4 md:p-0',
          brandingEnabled ? 'bg-color-ds-surface-secondary' : 'bg-white'
        )}
      >
        <div className={'mb-4'}>
          <RequiredEntry showError={Boolean(error)} errorMessage={error}>
            <TextInput
              required
              placeholder={'Type full name here'}
              label={'Signature'}
              value={signature}
              onChange={(e) => {
                setSignature(e.target.value)
                if (e.target.value) {
                  setError('')
                }
              }}
              containerClassName={'[&_label]:text-color-ds'}
            />
          </RequiredEntry>
        </div>
        <ConfirmEstimateButton
          brandingEnabled={brandingEnabled}
          signature={signature}
          setValidationError={setError}
        />
        <HelperText
          helperText={
            <div>
              By accepting this estimate, you agree to Toast's{' '}
              <a
                href={'https://pos.toasttab.com/terms-of-service'}
                target={'_blank'}
                rel='noreferrer'
                className={linkClassName}
              >
                Guest Terms of Service
              </a>{' '}
              and acknowledge that your information will be processed pursuant
              to Toast's{' '}
              <a
                href='https://pos.toasttab.com/privacy'
                target={'_blank'}
                rel='noreferrer'
                className={linkClassName}
              >
                Privacy Statement
              </a>
              .
            </div>
          }
        />
      </div>
    </div>
  )
}

export default EventSignature
