import {
  AppliedDiscount,
  AvailableRedemption,
  DiscountAmountType,
  SelectionType,
  DiscountsEntity
} from '../../types/InvoiceTypes'
import { v4 as uuidv4 } from 'uuid'
import React from 'react'
import { formatCurrency } from '@toasttab/buffet-pui-number-utilities'
import { Currency, Locale } from '@toasttab/buffet-pui-types'
import { RewardCard } from './RewardCard'

export interface CustomerLoyaltyConfig {
  enrolled: boolean
}

export enum RewardType {
  LOYALTY_POINTS_AMOUNT = 'LOYALTY_POINTS_AMOUNT',
  LOYALTY_DOLLARS_AMOUNTS = 'LOYALTY_DOLLARS_AMOUNTS',
  BIRTHDAY_REWARD = 'BIRTHDAY_REWARD',
  BIRTHDAY_REWARDS = 'BIRTHDAY_REWARDS',
  ITEM_REWARDS = 'ITEM_REWARDS'
}

export const rewardToDiscount = (
  r: AvailableRedemption,
  vendor: string,
  discount: DiscountsEntity
): AppliedDiscount | undefined => {
  if (isCheckDiscount(r)) {
    return buildCheckDiscount(r, vendor, discount)
  } else if (isItemDiscount(r)) {
    return undefined
  }

  return undefined
}

const buildCheckDiscount = (
  r: AvailableRedemption,
  vendor: string,
  discount: DiscountsEntity
): AppliedDiscount | undefined => {
  let amount: number | undefined
  let percent: number | undefined

  if (discount.type === DiscountAmountType.OPEN_FIXED) {
    if (vendor === 'TOAST') {
      amount = r.quantity
    } else if (vendor === 'INTEGRATION') {
      amount = r.integrationData!!.amount
    } else {
      return undefined
    }
    amount = r.quantity
  } else if (discount.type === DiscountAmountType.OPEN_PERCENT) {
    if (vendor === 'TOAST') {
      percent = r.quantity
    } else if (vendor === 'INTEGRATION') {
      percent = r.integrationData!!.amount
    } else {
      return undefined
    }
  }

  return {
    guid: uuidv4(),
    discount: {
      guid: r.itemId,
      entityType: 'Discount'
    },
    loyaltyDetails: {
      referenceId: r.referenceId,
      vendor: vendor
    },
    name: discount.name,
    discountAmount: amount,
    discountPercent: percent
  }
}

export const isCheckDiscount = (r: AvailableRedemption): Boolean => {
  return (
    r.referenceId === RewardType.LOYALTY_DOLLARS_AMOUNTS ||
    r.integrationData?.selectionType === SelectionType.CHECK
  )
}

export const isItemDiscount = (r: AvailableRedemption): Boolean => {
  return (
    r.referenceId === RewardType.ITEM_REWARDS ||
    r.integrationData?.selectionType === SelectionType.ITEM
  )
}

export interface RedemptionView {
  key: number
  redemption: AvailableRedemption
}

export const rewardToCard = (
  redemptionView: RedemptionView,
  balanceDue: number,
  currency: Currency,
  local: Locale,
  conversionRate: number | undefined
) => {
  let r = redemptionView.redemption
  let title: string | undefined
  let subtitle: string | undefined
  if (
    r.referenceId === RewardType.LOYALTY_DOLLARS_AMOUNTS ||
    r.integrationData?.selectionType === 'CHECK'
  ) {
    const qty = Math.min(r.quantity, balanceDue)

    if (r.integrationData) {
      title = r.name
    } else {
      title = formatCurrency({ amount: qty, currency }, local) + ' off'

      if (conversionRate) {
        const pointsCost = Math.round(qty / conversionRate)

        if (pointsCost > 1) {
          subtitle = pointsCost + ' points'
        } else if (pointsCost === 1) {
          subtitle = pointsCost + ' point'
        }
      }
    }
  } else if (
    r.referenceId === RewardType.BIRTHDAY_REWARD ||
    r.referenceId === RewardType.BIRTHDAY_REWARDS
  ) {
    // not supported for 3p
    if (!balanceDue || balanceDue < r.quantity || r.integrationData) {
      return undefined
    }

    if (!balanceDue || balanceDue < r.quantity) {
      return undefined
    }

    title =
      formatCurrency({ amount: r.quantity, currency }, local) +
      ' off Birthday Treat'
  } else if (r.referenceId === RewardType.ITEM_REWARDS) {
    title = r.name
  }

  if (!title && !subtitle) {
    return undefined
  }

  return {
    value: redemptionView.key,
    contents: <RewardCard title={title} subtitle={subtitle} />
  }
}
