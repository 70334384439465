import { useInvoice } from '../InvoiceView/InvoiceProvider'
import LoyaltyRedemption from './LoyaltyRedemption'
import React from 'react'
import { Button } from '@toasttab/buffet-pui-buttons'
import { InfoTooltip, TooltipTextLayout } from '@toasttab/buffet-pui-tooltip'
import { useRestaurant } from '../../api/restaurant'

export const formatPhoneNumber = (phone: string | null): string | null => {
  if (phone && phone.length === 10) {
    return `(${phone.substring(0, 3)}) ${phone.substring(
      3,
      6
    )}-${phone.substring(6)}`
  }
  return ''
}

export default function LoyaltyAccountLookup() {
  const {
    loyaltyVendor,
    invoice,
    setOptedInTo3pAccountLookup,
    optedInTo3pAccountLookup,
    enrolledInLoyalty,
    loading
  } = useInvoice()

  const { restaurant } = useRestaurant(invoice.restaurantGuid)
  const rxName = restaurant?.name

  if (!loyaltyVendor || loyaltyVendor === 'NONE') {
    return null
  }

  if (loyaltyVendor === 'TOAST' || enrolledInLoyalty) {
    return <LoyaltyRedemption />
  }

  if (optedInTo3pAccountLookup && !loading && !enrolledInLoyalty) {
    return (
      <div className='space-y-1'>
        <TooltipTextLayout
          className='font-semibold text-error'
          helperIconButton={
            <InfoTooltip placement='auto'>
              <div className='type-subhead'>
                {`Email: ${invoice.customer.email}`}
                <br />
                {`Phone: ${formatPhoneNumber(invoice.customer.phoneNumber)}`}
              </div>
            </InfoTooltip>
          }
        >
          No loyalty account found
        </TooltipTextLayout>
        {rxName && (
          <div className='type-subhead text-default'>
            {`Please confirm that the email address or phone number of your 
          ${rxName} rewards program matches the contact information on your invoice. 
          If not, create a new rewards account or update the information on
          your existing one.`}
          </div>
        )}
      </div>
    )
  }

  return (
    <div className='space-y-1'>
      <p className={'font-semibold text-default'}>Get rewarded!</p>
      <p className={'type-subhead'}>
        Lookup your account to earn points on this order and apply the discounts
        you've earned.
      </p>
      <Button
        testId='add-promo-code-button'
        variant='secondary'
        onClick={() => {
          setOptedInTo3pAccountLookup(true)
        }}
        className='my-4 w-52'
        disabled={loading}
      >
        Lookup My Rewards
      </Button>
      <p
        className={'type-caption'}
        data-testid='legal-links'
        role='group'
        aria-label='legal information'
      >
        By looking up rewards, you give Toast permission to share your contact
        information with this restaurant's rewards provider with the sole
        purpose of looking up your account.
      </p>
    </div>
  )
}
