import React, { useEffect } from 'react'
import { fetchInvoice } from '../../api/invoices'
import {
  ApplicableDiscount,
  AvailableRedemption,
  DiscountsEntity,
  Invoice,
  InvoiceOrdersType,
  InvoiceResponse,
  MenuItemMap,
  PublicSettings,
  RestaurantBranding
} from '../../types/InvoiceTypes'
import { ErrorPage500 } from '@toasttab/buffet-pui-error-pages'
import { useNavigate } from 'react-router'
import { useConfigureSentryInvoice } from '../Sentry/configureSentry'
import {
  ErrorInterface,
  InvoiceContextType
} from '../../types/DataProviderTypes'
import DataProvider from './DataProvider'
import InvoiceLoadingView from '../LoadingView/InvoiceLoadingView'
import { getCheckBalance } from '../../utils/payments'

// @ts-ignore doesn't like empty default context
export const InvoiceContext = React.createContext<InvoiceContextType>({})

export const useInvoice = () => React.useContext(InvoiceContext)

interface InvoiceProviderProps {
  token: string
  orderGuid?: string
  eventGuid?: string
}

const InvoiceProvider: React.FC<
  React.PropsWithChildren<InvoiceProviderProps>
> = ({ children, token, orderGuid, eventGuid }) => {
  const navigate = useNavigate()
  const [invoice, setInvoice] = React.useState<Invoice | null>(null)
  const [error, setError] = React.useState<ErrorInterface | null>(null)
  const [tipAmount, setTipAmount] = React.useState(0)
  const [paymentSuccess, setPaymentSuccess] = React.useState(false)
  const [enrolledInLoyalty, setEnrolledInLoyalty] = React.useState(false)
  const [menuItemMap, setMenuItemMap] = React.useState<MenuItemMap | undefined>(
    undefined
  )
  const [invoiceSettings, setInvoiceSettings] = React.useState<
    PublicSettings | undefined
  >(undefined)
  const [restaurantBranding, setRestaurantBranding] = React.useState<
    RestaurantBranding | undefined
  >(undefined)
  const [availableRedemptions, setAvailableRedemptions] = React.useState<
    AvailableRedemption[] | undefined
  >()
  const [applicableDiscounts, setApplicableDiscount] = React.useState<
    ApplicableDiscount[] | undefined
  >()
  const [discounts, setDiscounts] = React.useState<
    DiscountsEntity[] | undefined
  >()
  const [isTestMode, setIsTestMode] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const configureSentryInvoice = useConfigureSentryInvoice()

  const [
    optedInTo3pAccountLookup,
    setOptedInTo3pAccountLookup
  ] = React.useState(false)

  function setInvoiceResponseValues(response: InvoiceResponse) {
    if (response.status === 'EXPIRED') {
      navigate(`/invoice/expired?token=${token}`)
    }

    if (response.status === 'INVALID') {
      setError({ status: 500, message: 'Token is invalid' })
    }

    if (response.invoice && response.restaurantBranding) {
      configureSentryInvoice({ guid: response.invoice.invoiceGuid })
      setInvoice(response.invoice)
      setMenuItemMap(response.menuItemMap)
      setInvoiceSettings(response.invoiceSettings)
      setRestaurantBranding(response.restaurantBranding)
      setAvailableRedemptions(response.availableRedemptions)
      setApplicableDiscount(response.applicableDiscounts)
      setDiscounts(response.discounts)
      setIsTestMode(response.isTestMode)
    }
  }

  React.useEffect(() => {
    setLoading(true)

    fetchInvoice({
      token,
      lookup3pAccount: optedInTo3pAccountLookup,
      isEventInvoice: !!eventGuid
    })
      .then((response) => setInvoiceResponseValues(response))
      .catch((err) => {
        console.error('Failed to load invoice', err.message)
        setError(err)
      })
      .finally(() => setLoading(false))
    // eslint-disable-next-line
  }, [token, navigate, optedInTo3pAccountLookup])

  // Primary check (technically the same as the balance order?)
  const check = invoice?.order.checks[0]

  useEffect(() => {
    if (!enrolledInLoyalty && invoiceSettings?.loyaltySettings?.enrolled) {
      setEnrolledInLoyalty(true)
    }
  }, [enrolledInLoyalty, invoiceSettings?.loyaltySettings?.enrolled])

  if (error) {
    return <ErrorPage500 />
  }

  if (!invoice || !restaurantBranding) {
    return <InvoiceLoadingView />
  }

  // Invoice's DEPOSIT order or BALANCE order, depending on orderGuid in URL
  const invoiceOrder =
    invoice?.invoiceOrders?.find((i) => i.orderGuid === orderGuid) || null

  const paymentScheduleItem = invoice.paymentSchedule.find((i) => !i.payment)

  // Current payment (deposit or balance)
  const paymentOrder = invoiceOrder?.order
  const paymentCheck = paymentOrder?.checks[0]

  if (!invoiceOrder || !paymentOrder || !check || !paymentCheck) {
    return <ErrorPage500 />
  }

  const checkBalance = getCheckBalance(check)

  const totalAmount =
    invoice.status === 'PAID'
      ? checkBalance
      : (Math.round(checkBalance * 100) + Math.round(tipAmount * 100)) / 100

  // Gets deposit amount if deposit order (no tips for deposits)
  // ELSE, Balance due + tip amount entered
  const paymentAmount = invoiceOrder?.amount
    ? paymentCheck!!.totalAmount
    : paymentScheduleItem?.requestedAmount || totalAmount

  const isVoid = invoice.status === 'VOID'

  const isDeposit =
    invoiceOrder.type === InvoiceOrdersType.DEPOSIT ||
    Boolean(paymentScheduleItem?.requestedAmount)

  const isPaid =
    paymentSuccess ||
    paymentCheck.paymentStatus === 'PAID' ||
    paymentCheck.paymentStatus === 'CLOSED'

  const loyaltyVendor = invoiceSettings?.loyaltySettings?.vendor

  const loyaltyConversionRate =
    invoiceSettings?.loyaltySettings?.effectiveConversionRate

  return (
    <InvoiceContext.Provider
      value={{
        invoice,
        eventGuid,
        paymentOrder,
        invoiceOrder,
        isPaid,
        isVoid,
        setIsPaid: setPaymentSuccess,
        paymentAmount,
        tipAmount,
        setTipAmount,
        isDeposit,
        enrolledInLoyalty,
        setEnrolledInLoyalty,
        availableRedemptions,
        applicableDiscounts,
        discounts,
        loyaltyVendor,
        loyaltyConversionRate,
        setInvoiceResponseValues,
        isTestMode,
        optedInTo3pAccountLookup,
        setOptedInTo3pAccountLookup,
        checkBalance,
        loading
      }}
    >
      <DataProvider
        token={token}
        restaurantGuid={invoice.restaurantGuid}
        menuItemMap={menuItemMap}
        totalAmount={totalAmount}
        check={check}
        invoiceSettings={invoiceSettings}
        restaurantBranding={restaurantBranding}
        loading={<InvoiceLoadingView />}
      >
        {children}
      </DataProvider>
    </InvoiceContext.Provider>
  )
}

export default InvoiceProvider
