import * as React from 'react'
import Router from '../components/Router/Router'
import { ErrorBoundary } from '../components/ErrorBoundary/ErrorBoundary'
import { ErrorPage500 } from '@toasttab/buffet-pui-error-pages'
import { HelmetProvider } from 'react-helmet-async'

export function App() {
  React.useEffect(() => {
    document.title = 'Toast Invoices'
  }, [])

  return (
    <>
      <ErrorBoundary fallback={<ErrorPage500 />}>
        <HelmetProvider>
          <Router />
        </HelmetProvider>
      </ErrorBoundary>

      {/* Need this div for modals to render correctly */}
      <div id={'banquetPortalsContainer'} />
    </>
  )
}
