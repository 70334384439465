import React from 'react'
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary'
import { useEvent } from './EventProvider'
import classNames from 'classnames'

interface EventContractProps {
  hideHeading?: boolean
  cardDisabled?: boolean
}

const EventContract: React.FC<EventContractProps> = ({
  hideHeading,
  cardDisabled
}) => {
  const { event } = useEvent()

  if (!event.contract) return null

  return (
    <div className={classNames('px-4 md:px-8 pb-8', hideHeading && ' mt-4')}>
      <ErrorBoundary
        fallbackMessage={
          'Sorry, we are unable to load the order summary for this estimate.'
        }
      >
        {!hideHeading && (
          <p className={'type-overline text-secondary font-700 mb-3'}>
            Contract terms
          </p>
        )}
        <div className={classNames(!cardDisabled && 'bg-gray-0 p-3 rounded')}>
          {/* This is sanitized on the backend before it's returned here */}
          <div dangerouslySetInnerHTML={{ __html: event.contract }} />
        </div>
      </ErrorBoundary>
    </div>
  )
}

export default EventContract
