import { IconButton } from '@toasttab/buffet-pui-buttons'
import { CloseIcon } from '@toasttab/buffet-pui-icons'
import cx from 'classnames'
import * as React from 'react'

interface Props {
  label: React.ReactNode
  href: string
  onDelete: () => void
  testId?: string | number
  className?: string
  disabled?: boolean
  ariaLabel?: string
}

export const FileChip = ({
  label,
  href,
  onDelete,
  testId = 'filechip',
  className,
  disabled,
  ariaLabel,
  ...chipProps
}: Props) => {
  const aLabel = typeof label === 'string' ? label : ''

  return (
    <div
      data-testid={testId}
      className={cx(
        'group rounded inline-flex items-center justify-between',
        className
      )}
      {...chipProps}
    >
      <div
        className={cx(
          'type-default text-default text-left px-1',
          'group-hover',
          {
            'text-disabled': disabled
          }
        )}
      >
        <a
          href={href}
          target={'_blank'}
          rel={'noreferrer'}
          className={cx(
            'text-link type-default text-left px-1 ',
            'group-hover:text-link-hover',
            {
              'text-disabled': disabled
            }
          )}
        >
          {label}
        </a>
      </div>
      <IconButton
        icon={
          <CloseIcon aria-label={ariaLabel ? ariaLabel : `Remove ${aLabel}`} />
        }
        className={cx('text-secondary pl-1')}
        data-testid={`${testId}-delete-button`}
        aria-hidden='true'
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          event.stopPropagation()
          onDelete()
        }}
        disabled={disabled}
      />
    </div>
  )
}
