import React from 'react'
import { useBuffetContext } from '@toasttab/buffet-pui-context-provider'
import { formatCurrency } from '@toasttab/buffet-pui-number-utilities'
import InvoiceTotalRow from './InvoiceTotalRow'
import { useData } from '../InvoiceView/DataProvider'
import { computeDiscountAmount, getDiscountLabel } from './discount-utils'

export default function DiscountTotalRows() {
  const { check } = useData()
  const { currency, locale } = useBuffetContext()

  const itemTotal = check.selections.reduce(
    (acc, val) => acc + val.preDiscountPrice,
    0
  )

  const itemDiscountAmt = check.selections.reduce(
    (acc, val) =>
      acc +
      computeDiscountAmount(val.preDiscountPrice, val?.appliedDiscounts?.[0]),
    0
  )

  if (!check?.appliedDiscounts?.length && itemDiscountAmt === 0) return null

  return (
    <>
      <InvoiceTotalRow
        label={'Item Total'}
        value={formatCurrency({ amount: itemTotal, currency }, locale)}
      />
      {itemDiscountAmt > 0 && (
        <InvoiceTotalRow
          label={'Item Discounts'}
          value={formatCurrency({ amount: -itemDiscountAmt, currency }, locale)}
        />
      )}
      {check.appliedDiscounts.map((appliedDiscount) => {
        const label = getDiscountLabel(appliedDiscount)

        const discountAmt = computeDiscountAmount(itemTotal, appliedDiscount)

        return (
          <InvoiceTotalRow
            key={appliedDiscount.guid}
            label={label}
            value={formatCurrency({ amount: -discountAmt, currency }, locale)}
          />
        )
      })}
    </>
  )
}
