import React from 'react'
import { Helmet } from 'react-helmet-async'

interface BodyBackgroundColorProps {
  colorVar: string
  withoutHelmet?: boolean
}

export const BodyBackgroundColor: React.FC<BodyBackgroundColorProps> = React.memo(
  ({ colorVar, withoutHelmet }) => {
    const content = (
      <style type={'text/css'}>
        {`body { background: var(--${colorVar},var(--tsw-background-color)); }`}
      </style>
    )

    return withoutHelmet ? content : <Helmet>{content}</Helmet>
  }
)
