import {
  CurrentLocationIcon,
  WarningOutlineIcon
} from '@toasttab/buffet-pui-icons'
import React, { useState } from 'react'
import { TailSpin } from 'react-loader-spinner'
import { Location as LatLng } from '@toasttab/buffet-patterns-address-lookup'

interface Props {
  currentLocation: LatLng | undefined
  setCurrentLocation(loc: LatLng | undefined): void
}

export const CurrentLocationButton = ({
  currentLocation,
  setCurrentLocation
}: Props) => {
  const [currentLocationLoading, setCurrentLocationLoading] = useState(false)
  const [currentLocationError, setCurrentLocationError] = useState(false)

  const onFinishedLoadingCurrentLocation = (otherActions: () => void) =>
    setTimeout(() => {
      setCurrentLocationLoading(false)
      otherActions()
    }, 500)

  const onClickUseCurrentLocation = () => {
    if (currentLocation || currentLocationError) return
    setCurrentLocationLoading(true)
    setCurrentLocationError(false)

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          onFinishedLoadingCurrentLocation(() => {
            setCurrentLocation({
              latitude: pos.coords.latitude,
              longitude: pos.coords.longitude
            })
          })
        },
        () => {
          onFinishedLoadingCurrentLocation(() => {
            setCurrentLocationError(true)
            setCurrentLocation(undefined)
          })
        }
      )
    } else {
      onFinishedLoadingCurrentLocation(() => {
        setCurrentLocationError(true)
        setCurrentLocation(undefined)
      })
    }
  }
  return (
    <div
      className={`type-caption mb-2 flex ${
        currentLocation || currentLocationLoading || currentLocationError
          ? 'text-secondary'
          : 'text-link hover:text-link-hover hover:cursor-pointer'
      }`}
      onClick={onClickUseCurrentLocation}
    >
      {currentLocationError ? (
        <>
          <WarningOutlineIcon
            className='mr-1'
            size='xs'
            accessibility='decorative'
          />
          {'Unable to access current location'}
        </>
      ) : (
        <>
          {currentLocationLoading && (
            <TailSpin
              wrapperClass='mr-2'
              color='#666666'
              width='16px'
              height='16px'
            />
          )}
          {!currentLocationLoading && (
            <CurrentLocationIcon
              className='mr-1'
              size='xs'
              accessibility='decorative'
            />
          )}
          {currentLocation
            ? 'Using your current location'
            : 'Use my current location'}
        </>
      )}
    </div>
  )
}
