import React from 'react'
import { ErrorPage500 } from '@toasttab/buffet-pui-error-pages'
import { useNavigate } from 'react-router'
import { useConfigureSentryEvent } from '../Sentry/configureSentry'
import {
  GuestOrderStatus,
  MenuItemMap,
  RestaurantBranding
} from '../../types/InvoiceTypes'
import { Event, EventStatus } from '../../types/EventTypes'
import { fetchEvent } from '../../api/invoices'
import { ErrorInterface, EventContextType } from '../../types/DataProviderTypes'
import DataProvider from '../InvoiceView/DataProvider'
import EventLoadingView from '../LoadingView/EventLoadingView'
import { BanquetFeatureFlagsProvider } from '@toasttab/banquet-feature-flags'

// @ts-ignore doesn't like empty default context
export const EventContext = React.createContext<EventContextType>({})

export const useEvent = () => React.useContext(EventContext)

interface EventProviderProps {
  token: string
  loadingViewComponent?: React.ComponentType
}

const EventProvider: React.FC<React.PropsWithChildren<EventProviderProps>> = ({
  children,
  token,
  loadingViewComponent: LoadingViewComponent = EventLoadingView
}) => {
  const navigate = useNavigate()
  const [event, setEvent] = React.useState<Event | null>(null)
  const [error, setError] = React.useState<ErrorInterface | null>(null)
  const [menuItemMap, setMenuItemMap] = React.useState<MenuItemMap | undefined>(
    undefined
  )
  const [restaurantBranding, setRestaurantBranding] = React.useState<
    RestaurantBranding | undefined
  >(undefined)
  const configureSentryEvent = useConfigureSentryEvent()

  React.useEffect(() => {
    fetchEvent(token)
      .then(({ status, event, menuItemMap, restaurantBranding }) => {
        if (status === 'EXPIRED') {
          navigate(`/invoice/estimateExpired?token=${token}`)
        }

        if (status === 'INVALID') {
          setError({ status: 500, message: 'Token is invalid' })
        }

        if (event && restaurantBranding) {
          configureSentryEvent({ guid: event.guid })
          setEvent(event)
          setMenuItemMap(menuItemMap)
          setRestaurantBranding(restaurantBranding)
        }
      })
      .catch((err) => {
        console.error('Failed to load event', err.message)
        setError(err)
      })
  }, [token, navigate])

  if (error) {
    return <ErrorPage500 />
  }

  const check = event?.order?.checks?.[0] || null

  if (!event || !restaurantBranding) {
    return <LoadingViewComponent />
  }

  const isConfirmed = event.status !== EventStatus.TENTATIVE
  const setIsConfirmed = () => {
    setEvent({
      ...event,
      status: EventStatus.CONFIRMED
    })
  }

  const isOrderVoided: boolean | undefined = event.order?.guestOrderStatus
    ? event.order.guestOrderStatus === GuestOrderStatus.VOIDED
    : undefined

  const totalAmount = check?.amountDue ?? check?.totalAmount ?? 0

  return (
    <EventContext.Provider
      value={{
        event,
        isConfirmed,
        isOrderVoided,
        setIsConfirmed
      }}
    >
      <BanquetFeatureFlagsProvider
        user={{ key: event.restaurantGuid }}
        deferInitialization
      >
        <DataProvider
          token={token}
          restaurantGuid={event.restaurantGuid}
          menuItemMap={menuItemMap}
          totalAmount={totalAmount}
          check={check!!}
          restaurantBranding={restaurantBranding}
          loading={<LoadingViewComponent />}
        >
          {children}
        </DataProvider>
      </BanquetFeatureFlagsProvider>
    </EventContext.Provider>
  )
}

export default EventProvider
