import React from 'react'
import { useInvoice } from './InvoiceProvider'

export default function InvoiceVoidView() {
  const { invoice, isPaid, isVoid } = useInvoice()

  if (isPaid || !isVoid) {
    return null
  }

  return (
    <>
      <div className={'px-8 py-8 bg-gray-0'}>
        <h2
          className={
            'type-headline-1 font-normal text-primary-100 text-center w-full mb-4'
          }
        >
          VOID
        </h2>
        <p className={'text-center type-large text-secondary'}>
          Invoice #{invoice.invoiceNumber} has been voided by the restaurant.
        </p>
      </div>
    </>
  )
}
