import React from 'react'
import { Button } from '@toasttab/buffet-pui-buttons'
import { CCData } from './CCDataType'
import { handleLoyaltyEnrollment, payInvoice } from '../../api/invoices'
import { RestaurantBranding } from '../../types/InvoiceTypes'
import { Restaurant } from '../../types/RestaurantTypes'
import { AutorenewIcon } from '@toasttab/buffet-pui-icons'
import { shouldSilenceError } from '../../utils/logging'
import LoyaltyEnrollment from '../Loyalty/LoyaltyEnrollment'
import { EnrollmentRequest } from '../../types/DataProviderTypes'
import { useInvoice } from './InvoiceProvider'
import { useData } from './DataProvider'
import { useSentry } from 'banquet-runtime-modules'

function getIframeSrc(restaurant: any, restaurantBranding: any) {
  const iframeSrc = `https://${window.location.hostname}/${restaurantBranding.shortUrl}/v3/ccform`
  const iframeUrl = new URL(`${iframeSrc}?color=%23183da3`)

  if (restaurant.creditCardConfig.amexAccepted) {
    iframeUrl.searchParams.set('amexAccepted', 'true')
  }

  return iframeUrl.toString()
}

export default function CreditCardForm(props: {
  restaurant: Restaurant
  restaurantBranding: RestaurantBranding
  setLoyaltyError: React.Dispatch<React.SetStateAction<string | null>>
  loyaltyError: string | null
}) {
  const {
    restaurant,
    restaurantBranding,
    setLoyaltyError,
    loyaltyError
  } = props
  const {
    invoice,
    setIsPaid,
    tipAmount,
    paymentOrder,
    setEnrolledInLoyalty,
    paymentAmount,
    isDeposit
  } = useInvoice()

  const { invoiceSettings } = useData()

  const [height, setHeight] = React.useState(218)
  const [ccData, setCcData] = React.useState<CCData | null>(null)
  const [paymentError, setPaymentError] = React.useState<string | null>(null)
  const [loading, setLoading] = React.useState(false)
  const [loyaltyValid, setLoyaltyValid] = React.useState(true)
  const [
    loyaltyEnrollmentRequest,
    setLoyaltyEnrollmentRequest
  ] = React.useState<EnrollmentRequest | undefined>()
  const { captureException } = useSentry()

  const receiveMessage = React.useCallback((event: any) => {
    if (event.data) {
      let data =
        typeof event.data === 'string'
          ? JSON.parse(event.data)
          : JSON.stringify(event.data)
      if (data && (data.name === 'CC_VALID' || data.name === 'CC_INVALID')) {
        setCcData(data.data)
      } else if (data && data.name === 'IFRAME_HEIGHT') {
        setHeight(data.data)
      }
    }
  }, [])

  React.useEffect(() => {
    window.addEventListener('message', receiveMessage, false)

    return () => window.removeEventListener('message', receiveMessage)
  }, [receiveMessage])

  const handleSubmit = () => {
    setLoading(true)
    setPaymentError(null)
    setLoyaltyError(null)

    handleLoyaltyEnrollment(
      loyaltyEnrollmentRequest,
      invoice,
      invoiceSettings.loyaltySettings?.enrolled,
      setEnrolledInLoyalty,
      setLoyaltyError,
      captureException
    )

    payInvoice(
      invoice,
      paymentOrder,
      ccData,
      tipAmount,
      // if this is deposit we need to pass the deposit amount
      // else we pass undefined so that it pays the remaining balance instead
      isDeposit ? paymentAmount : undefined
    )
      .then(() => setIsPaid(true))
      .catch((err: Error) => {
        if ('message' in err) {
          setPaymentError(err.message)
          if (!shouldSilenceError(err.message)) {
            captureException(err)
          }
        } else {
          captureException(err)
          setPaymentError('Something went wrong.')
        }
      })
      .finally(() => setLoading(false))
  }

  return (
    <>
      <iframe
        className={'w-full'}
        scrolling='no'
        src={getIframeSrc(restaurant, restaurantBranding)}
        height={`${height}px`}
        title='check-credit-card-input'
      />
      <LoyaltyEnrollment
        setLoyaltyValid={setLoyaltyValid}
        setLoyaltyEnrollmentRequest={setLoyaltyEnrollmentRequest}
      />
      <Button
        className={'w-full'}
        disabled={!ccData || loading || !loyaltyValid}
        id='submitPayment'
        type='submit'
        onClick={handleSubmit}
        iconLeft={
          loading ? (
            <AutorenewIcon aria-label='loading' className='animate-spin' />
          ) : undefined
        }
      >
        Pay now
      </Button>
      {paymentError && (
        <p className={'pb-3 text-center type-default text-error mt-2'}>
          {paymentError}
        </p>
      )}
      {loyaltyError && (
        <p className={'pb-3 text-center type-default text-error mt-2'}>
          {loyaltyError}
        </p>
      )}
    </>
  )
}
