import React, { useEffect, useMemo, useState } from 'react'
import { DividingLine } from '@toasttab/buffet-pui-config-templates'
import LocationMap, {
  getLocationPanelId,
  MapLocation
} from '../Map/LocationMap'
import {
  Address,
  AddressLookup,
  Location as LatLng,
  mapboxPublicToken
} from '@toasttab/buffet-patterns-address-lookup'
import CateringOOLocationSelect from './CateringOOLocationSelect'
import LeadLocationSelect from './LeadLocationSelect'
import { useParams } from 'react-router'
import { useLocationOptions } from '../../api/invoices'
import { useCateringOOLocations } from '../../api/catering-locations'
import ViewToggle from './ViewToggle/ViewToggle'
import SectionError from '../ErrorBoundary/SectionError'
import { EmptyState } from '@toasttab/buffet-pui-empty-state'
import { LookupInfoIcon } from '@toasttab/buffet-pui-icons'
import StreetGridIcon from './StreetGridIcon'
import { useTitle } from '../../utils/utils'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import AllPagesTag from '../GooglePixels/AllPagesTag'
import { CurrentLocationButton } from './CurrentLocationButton'
import { useCityLookup } from '../../api/city-lookup'

type ViewState = 'ORDER' | 'INQUIRE'

const ListError = () => {
  return (
    <SectionError message='Sorry, we are unable to load the restaurant list.' />
  )
}

const EmptyListState = ({ viewState }: { viewState: ViewState }) => {
  return (
    <EmptyState icon={<LookupInfoIcon accessibility='decorative' />}>
      {`Currently this restaurant group has no locations accepting catering ${
        viewState === 'ORDER' ? 'online orders' : 'inquiries'
      }.`}
      <div className='mt-4'>
        <StreetGridIcon />
      </div>
    </EmptyState>
  )
}

const LocationPickerView: React.FC = () => {
  const { mgmtGuid } = useParams()
  useTitle('Toast Catering')

  const [addressSearch, setAddressSearch] = useState<Address>()
  const [lookupAddress, setLookupAddress] = useState<Address>()

  useEffect(() => {
    if (addressSearch?.latitude && addressSearch?.longitude) {
      setLookupAddress(addressSearch)
    }
  }, [addressSearch])

  const [currentLocation, setCurrentLocation] = useState<LatLng>()
  useEffect(() => {
    if (currentLocation) setLookupAddress(currentLocation)
  }, [currentLocation])

  const { cityFromUrl, cityFromUrlLoading } = useCityLookup()
  useEffect(() => {
    if (cityFromUrl) setLookupAddress(cityFromUrl)
  }, [cityFromUrl])

  const [selectedGuid, setSelectedGuid] = useState<string>()

  const {
    locations: leadsLocations,
    isLoading: isLeadsLoading,
    error: leadsError
  } = useLocationOptions(mgmtGuid, lookupAddress, !cityFromUrlLoading)

  const {
    locations: cooLocations,
    isLoading: isCooLoading,
    error: cooError
  } = useCateringOOLocations(mgmtGuid, lookupAddress, !cityFromUrlLoading)

  const [viewState, setViewState] = useState<ViewState | undefined>('ORDER')

  const isLoading = isLeadsLoading || isCooLoading
  const hasLeadsLocations = (leadsLocations ?? [])?.length > 0
  const hasCooLocations = (cooLocations ?? [])?.length > 0

  useEffect(() => {
    // If the location picker does not contain COO locations results,
    // initialize on the Inquiries view, else default to Order view.
    if (hasLeadsLocations && !hasCooLocations) {
      setViewState('INQUIRE')
    } else if (hasCooLocations) {
      setViewState('ORDER')
    }
  }, [hasLeadsLocations, hasCooLocations])

  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false)
  useEffect(() => {
    let timeoutId = undefined
    if (isLoading) {
      timeoutId = setTimeout(() => {
        setShowLoadingSpinner(true)
      }, 1000)
    } else {
      setShowLoadingSpinner(false)
    }
    return () => clearTimeout(timeoutId)
  }, [isLoading])

  const mapLocations: MapLocation[] | undefined = useMemo(() => {
    if (viewState === 'ORDER') {
      return cooLocations?.map((loc) => {
        return {
          guid: loc.restaurantGuid,
          name: loc.locationName ?? loc.restaurantName,
          latitude: loc.address.latitude,
          longitude: loc.address.longitude
        }
      })
    } else if (viewState === 'INQUIRE') {
      return leadsLocations?.map((loc) => {
        return {
          guid: loc.guid,
          name: loc.branding.name,
          latitude: loc.branding.latitude,
          longitude: loc.branding.longitude
        }
      })
    }
  }, [viewState, cooLocations, leadsLocations])

  return (
    <main className={'h-full'}>
      {mgmtGuid && <AllPagesTag managementSetGuid={mgmtGuid} />}
      <div className={'location-picker-sidebar py-12 px-6'}>
        <h1 className={'type-headline-3 m-0 mb-6'}>Welcome!</h1>
        {hasLeadsLocations && hasCooLocations && (
          <ViewToggle
            value={viewState === 'ORDER' ? 'left' : 'right'}
            onChange={setViewState}
            left={{
              name: 'Order online',
              value: 'ORDER'
            }}
            right={{
              name: 'Inquire',
              value: 'INQUIRE'
            }}
          />
        )}
        <div className='flex justify-end w-full'>
          <CurrentLocationButton
            currentLocation={currentLocation}
            setCurrentLocation={setCurrentLocation}
          />
        </div>
        <AddressLookup
          placeholder='Enter an address to find your closest location'
          value={addressSearch}
          onChange={setAddressSearch}
          lookupServiceAccessToken={mapboxPublicToken}
          proximity={currentLocation}
        />
        <LocationMap
          locations={mapLocations}
          selectedGuid={selectedGuid}
          onSelectedGuidChange={(guid: string | undefined) => {
            setSelectedGuid(guid)
            if (guid) {
              const id = getLocationPanelId(guid)
              const el = document.getElementById(id)
              el?.scrollIntoView({ behavior: 'smooth' })
            }
          }}
          mapCenter={cityFromUrl}
        />
        <DividingLine />
        {showLoadingSpinner && (
          <div className='flex items-center justify-center w-full h-full'>
            <MerryGoRound />
          </div>
        )}
        {viewState === 'ORDER' && (
          <>
            {cooError ? (
              <ListError />
            ) : cooLocations && cooLocations.length === 0 ? (
              <EmptyListState viewState='ORDER' />
            ) : cooLocations && cooLocations.length > 0 ? (
              <CateringOOLocationSelect
                address={lookupAddress}
                locations={cooLocations}
                setSelectedGuid={setSelectedGuid}
                selectedGuid={selectedGuid}
              />
            ) : null}
          </>
        )}
        {viewState === 'INQUIRE' && (
          <>
            {leadsError ? (
              <ListError />
            ) : leadsLocations && leadsLocations.length === 0 ? (
              <EmptyListState viewState='INQUIRE' />
            ) : leadsLocations && leadsLocations.length > 0 ? (
              <LeadLocationSelect
                locations={leadsLocations}
                setSelectedGuid={setSelectedGuid}
                selectedGuid={selectedGuid}
              />
            ) : null}
          </>
        )}
      </div>
    </main>
  )
}

export default LocationPickerView
