import React from 'react'
import { Navigate, useSearchParams } from 'react-router-dom'
import Main from '../Main/Main'
import { EventPortalView } from './EventPortalView'
import EventProvider, { useEvent } from '../EventView/EventProvider'
import { useFlags } from '@toasttab/banquet-feature-flags'
import { ErrorPage404 } from '@toasttab/buffet-pui-error-pages'
import { EventPortalLoadingView } from './EventPortalLoadingView'
import { useEventType } from '../../api/invoices'
import { useBrandingStyleMeta } from '../../hooks/useBrandingStyleMeta'
import { Helmet } from 'react-helmet-async'

const GatedEventPortalView: React.FC = () => {
  const { nvsEventPortal } = useFlags()
  const { event } = useEvent()
  const { eventType } = useEventType(event.restaurantGuid, event.eventTypeGuid)
  const brandingStyleMeta = useBrandingStyleMeta(eventType?.restaurantBranding)

  return nvsEventPortal && eventType ? (
    <>
      <Helmet>{brandingStyleMeta}</Helmet>
      <EventPortalView />
    </>
  ) : nvsEventPortal === false ? (
    <ErrorPage404 />
  ) : (
    <EventPortalLoadingView />
  )
}

export const EventPortalRoute: React.FC = () => {
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')

  if (!token) {
    return <Navigate to={'/invoice'} />
  }

  return (
    <Main maxWidth={'none'}>
      <EventProvider
        token={token}
        loadingViewComponent={EventPortalLoadingView}
      >
        <GatedEventPortalView />
      </EventProvider>
    </Main>
  )
}
