import React from 'react'
import cx from 'classnames'
import { Skeleton } from '@toasttab/buffet-pui-loading-indicators'

export enum LabelItemAlign {
  /* eslint-disable no-unused-vars */
  Left = 'left',
  Right = 'right'
  /* eslint-enable no-unused-vars */
}

export default function LabelItem(props: {
  label: string
  align?: LabelItemAlign
  children: React.ReactNode
  loadingChildren?: React.ReactNode
  loading?: boolean
}) {
  const {
    label,
    align = LabelItemAlign.Left,
    children,
    loading = false,
    loadingChildren = (
      <Skeleton className={'mt-1'} height={'16px'} width={'180px'} />
    )
  } = props

  return (
    <div className={cx({ 'text-right': align === LabelItemAlign.Right })}>
      <div className={'type-overline text-secondary'}>{label}</div>
      <div
        className={cx('type-default', 'flex', {
          'justify-end': align === LabelItemAlign.Right
        })}
      >
        {loading ? loadingChildren : children}
      </div>
    </div>
  )
}
