import React from 'react'
import { navigateToCateringOO } from '../../api/catering-locations'
import { Button } from '@toasttab/buffet-pui-buttons'
import { Address } from '@toasttab/buffet-patterns-address-lookup'
import AvailabilityTag from './AvailabilityTag'
import LocationCard from './LocationCard'
import { Location } from '../../types/CateringLocationTypes'
import { getLocationPanelId } from '../Map/LocationMap'
import { Tooltip } from '@toasttab/buffet-pui-tooltip'

interface Props {
  locations?: Location[]
  address?: Address
  setSelectedGuid: (guid: string) => void
  selectedGuid: string | undefined
}

const CateringOOLocationSelect = ({
  locations,
  address,
  setSelectedGuid,
  selectedGuid
}: Props) => {
  return (
    <>
      {locations?.map((location) => {
        const isAvailable =
          Boolean(location.shortUrl) &&
          location.enabled &&
          (location.takeoutEnabled || location.deliveryEnabled)
        return (
          <LocationCard
            key={location.restaurantGuid}
            id={getLocationPanelId(location.restaurantGuid)}
            isSelected={selectedGuid === location.restaurantGuid}
            onClick={() => setSelectedGuid(location.restaurantGuid)}
            restaurantName={location.restaurantName}
            locationName={location.locationName}
            distanceMiles={location.distanceMiles}
            address={location.address}
            availabilityIndicator={
              <AvailabilityTag
                text={
                  location.removeCateringName
                    ? 'Online ordering'
                    : 'Catering online ordering'
                }
                isAvailable={isAvailable}
              />
            }
          >
            {isAvailable && (
              <div className='order-buttons-container'>
                {location.takeoutEnabled && (
                  <Button
                    className='order-button'
                    onClick={() => {
                      navigateToCateringOO(
                        location.shortUrl,
                        'TAKE_OUT',
                        address
                      )
                    }}
                  >
                    Order pickup
                  </Button>
                )}
                {location.deliveryEnabled && (
                  <Tooltip placement='top'>
                    <Tooltip.Trigger>
                      <Button
                        className='order-button'
                        disabled={!location.inRangeForDelivery}
                        onClick={() => {
                          navigateToCateringOO(
                            location.shortUrl,
                            'DELIVERY',
                            address
                          )
                        }}
                      >
                        Order delivery
                      </Button>
                    </Tooltip.Trigger>
                    {!location.inRangeForDelivery && (
                      <Tooltip.Content>
                        The address entered is out of range for delivery at this
                        location
                      </Tooltip.Content>
                    )}
                  </Tooltip>
                )}
              </div>
            )}
          </LocationCard>
        )
      })}
    </>
  )
}

export default CateringOOLocationSelect
