import { Button } from '@toasttab/buffet-pui-buttons'
import { AutorenewIcon, EmailIcon } from '@toasttab/buffet-pui-icons'
import React, { useCallback, useState } from 'react'
import { Navigate, useSearchParams } from 'react-router-dom'
import { resendInvoiceEmail } from '../../api/invoices'
import Main from '../Main/Main'

const ExpiredView: React.FC = () => {
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>(undefined)

  const handleResend = useCallback(() => {
    if (!token) {
      return
    }

    setLoading(true)
    setError(undefined)

    resendInvoiceEmail(token)
      .catch((error) => setError(error))
      .finally(() => setLoading(false))
  }, [token])

  if (!token) {
    return <Navigate to={'/invoice'} />
  }

  return (
    <Main>
      <div className={'p-8 flex items-center justify-center'}>
        <div className='text-center max-w-sm'>
          <h1 className={'type-headline-1 text-link mb-4'}>Whoops!</h1>
          <p className='text-secondary mb-8'>
            This link has expired. In order to pay this invoice online, click
            the button below and open the new email.
          </p>
          <Button
            onClick={handleResend}
            iconLeft={
              loading ? (
                <AutorenewIcon className='animate-spin' />
              ) : (
                <EmailIcon />
              )
            }
          >
            Send new link
          </Button>
          {error && (
            <div className='text-error'>
              {error.message ?? 'Oops, something went wrong!'}
            </div>
          )}
        </div>
      </div>
    </Main>
  )
}

export default ExpiredView
