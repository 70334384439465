import React from 'react'
import { Button } from '@toasttab/buffet-pui-buttons'
import { SentIllustration } from '@toasttab/buffet-pui-illustrations'
import SubmissionTag from '../GooglePixels/SubmissionTag'

export default function LeadSubmitted(props: {
  onBack: () => void
  managementSetGuid: string | undefined
}) {
  return (
    <>
      {props.managementSetGuid && (
        <SubmissionTag managementSetGuid={props.managementSetGuid} />
      )}
      <div className='text-center'>
        <SentIllustration className='w-1/3 mb-4' resizeToContainer />
        <div>
          <h3 className='headline-5 font-bold type-default'>All set!</h3>
        </div>
        <p className='mb-4 text-secondary type-default'>
          Your inquiry has been submitted. We will be in touch soon!
        </p>
      </div>
      <Button onClick={props.onBack} className={'w-full'} size={'lg'}>
        Back
      </Button>
    </>
  )
}
