import { useEffect, useState } from 'react'
import { fetchPublicUrl, formatQueryParams } from './util'
import { useLocation } from 'react-router'
import {
  Location,
  mapboxPublicToken
} from '@toasttab/buffet-patterns-address-lookup'

type MapboxResponse = {
  features: MapboxFeature[]
}

type MapboxFeature = {
  center?: number[]
}

const mapboxCitySearch = async (search: string): Promise<MapboxResponse> => {
  const searchTerm = encodeURIComponent(search)

  const params = {
    access_token: mapboxPublicToken,
    types: 'place,region',
    country: 'us,ca'
  }
  return fetchPublicUrl(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${searchTerm}.json${formatQueryParams(
      params
    )}`
  )
}

interface LocationPageQueryParams {
  area?: string
}

export const useCityLookup = () => {
  const { search } = useLocation()

  const [cityLocation, setCityLocation] = useState<Location>()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const urlQuery: LocationPageQueryParams = Object.fromEntries(
      new URLSearchParams(search)
    )
    if (!urlQuery.area) return

    setLoading(true)
    mapboxCitySearch(urlQuery.area)
      .then((res) => {
        // results are ordered by relevance. take the first one
        const matchCoordinates = res.features[0]?.center
        if (matchCoordinates) {
          setCityLocation({
            latitude: matchCoordinates[1],
            longitude: matchCoordinates[0]
          })
        }
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return { cityFromUrl: cityLocation, cityFromUrlLoading: loading }
}
