import React, { useCallback } from 'react'
import cx from 'classnames'
import './styles.css'

type Side = 'right' | 'left'

type Option<T> = {
  name: string
  subLabel?: string
  icon?: React.ReactElement
  value: T
  disabled?: boolean
}

type Props<T> = { [key in Side]: Option<T> } & {
  value: Side
  onChange: (value: T) => void
  className?: string
}

const ViewToggle = <T,>(props: Props<T>) => {
  const selected =
    props.value ||
    (!props.left.disabled ? 'left' : !props.right.disabled ? 'right' : null)

  const hasDisabled = props.left.disabled || props.right.disabled

  const select = useCallback(
    (side: Side) => () => {
      if (!props[side].disabled && selected !== side) {
        props.onChange(props[side].value)
      }
    },
    [props, selected]
  )

  return (
    <div
      className={cx('toggle', 'mb-4', selected, props.className, {
        hasDisabled
      })}
    >
      <div className={cx('switch')} />
      <button
        type='button'
        className={cx('option', {
          selected: selected === 'left',
          disabled: props.left.disabled
        })}
        disabled={props.left.disabled}
        onClick={select('left')}
      >
        {props.left.icon ? props.left.icon : null}
        <div className='optionText'>
          <div>{props.left.name}</div>
          {props.left.subLabel ? <div>{props.left.subLabel}</div> : null}
        </div>
      </button>
      <button
        type='button'
        className={cx('option', {
          selected: selected === 'right',
          disabled: props.right.disabled
        })}
        disabled={props.right.disabled}
        onClick={select('right')}
      >
        {props.right.icon ? props.right.icon : null}
        <div className='optionText'>
          <div>{props.right.name}</div>
          {props.right.subLabel ? <div>{props.right.subLabel}</div> : null}
        </div>
      </button>
    </div>
  )
}

export default ViewToggle
