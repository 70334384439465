import React from 'react'
import { useBuffetContext } from '@toasttab/buffet-pui-context-provider'
import { formatCurrency } from '@toasttab/buffet-pui-number-utilities'
import { useData } from './DataProvider'
import { useInvoice } from './InvoiceProvider'
import { useNavigate } from 'react-router'
import { Button } from '@toasttab/buffet-pui-buttons'

export default function InvoicePaymentConfirmation() {
  const {
    invoice,
    isPaid,
    isVoid,
    isDeposit,
    paymentAmount,
    eventGuid
  } = useInvoice()
  const { check, token } = useData()
  const { currency, locale } = useBuffetContext()
  const navigate = useNavigate()

  if (!isPaid || isVoid) {
    return null
  }

  return (
    <>
      <div className={'px-8 py-8 bg-gray-0 text-center'}>
        <h2
          className={
            'type-headline-1 font-normal text-primary-100 text-center w-full mb-4'
          }
        >
          Thank you!
        </h2>
        {isDeposit ? (
          <p className={'type-large text-secondary'}>
            Deposit for invoice #{invoice.invoiceNumber} has been has been paid
            and receipt email sent. You will be notified when it is time to make
            further payments.
          </p>
        ) : (
          <p className={'type-large text-secondary'}>
            Invoice #{invoice.invoiceNumber} for{' '}
            {formatCurrency(
              {
                amount: paymentAmount > 0 ? paymentAmount : check.totalAmount,
                currency
              },
              locale
            )}{' '}
            has been paid and email receipt sent.
          </p>
        )}
        {eventGuid && (
          <Button
            variant={'link'}
            onClick={() =>
              navigate(`/invoice/event/${eventGuid}?token=${token}`)
            }
          >
            Return to Event page
          </Button>
        )}
      </div>
    </>
  )
}
