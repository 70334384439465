import { useEffect, useState } from 'react'
import { useConfigureSentryRx } from '../components/Sentry/configureSentry'
import { Restaurant } from '../types/RestaurantTypes'
import { getRestaurantQuery } from './graphqlQueries'
import { fetchGraphql } from './util'

export async function fetchRestaurant(restaurantGuid: string) {
  const res = await fetchGraphql(getRestaurantQuery(), {
    restaurantId: restaurantGuid
  })
  return res.data.restaurantV2
}

export const useRestaurant = (restaurantGuid?: string) => {
  const [restaurant, setRestaurant] = useState<Restaurant | null>(null)
  const [error, setError] = useState<Error | null>(null)
  const configureSentryRx = useConfigureSentryRx()

  useEffect(() => {
    if (!restaurantGuid) {
      return
    }
    fetchRestaurant(restaurantGuid)
      .then((restaurant) => {
        configureSentryRx({ guid: restaurant.guid, name: restaurant.name })
        setRestaurant(restaurant)
      })
      .catch((err) => {
        console.error('Failed to load restaurant', err.message)
        setError(err)
      })
  }, [restaurantGuid])

  return {
    restaurant,
    error
  }
}
