import React, { Component, ReactNode } from 'react'
import SectionError from './SectionError'
import { useSentry } from 'banquet-runtime-modules'
import { EventHint } from '@sentry/types'

interface ErrorBoundaryProps {
  children: ReactNode
  fallback?: ReactNode | null
  fallbackMessage?: string // pass a custom message to the default fallback component
  fallbackClassName?: string // pass a custom className to the default fallback component
}

interface ErrorBoundaryInnerProps extends ErrorBoundaryProps {
  captureException: (exception: Error, hint?: EventHint | undefined) => string
}

class ErrorBoundaryInner extends Component<
  ErrorBoundaryInnerProps,
  { hasError: boolean }
> {
  state = {
    hasError: false
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error) {
    this.props.captureException(error)
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback !== undefined ? (
        this.props.fallback
      ) : (
        <SectionError
          message={this.props.fallbackMessage}
          className={this.props.fallbackClassName}
        />
      )
    }
    return this.props.children || null
  }
}

export const ErrorBoundary = (props: ErrorBoundaryProps) => {
  const { captureException } = useSentry()
  return (
    // @ts-ignore
    <ErrorBoundaryInner {...props} captureException={captureException}>
      {props.children}
    </ErrorBoundaryInner>
  )
}
