import React from 'react'
import { Navigate, useSearchParams } from 'react-router-dom'
import EventProvider from './EventProvider'
import EventView from './EventView'
import Main from '../Main/Main'

export default function EventRoute() {
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')

  if (!token) {
    return <Navigate to={'/invoice'} />
  }

  return (
    <Main>
      <EventProvider token={token!!}>
        <EventView />
      </EventProvider>
    </Main>
  )
}
