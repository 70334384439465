import React from 'react'
import { useData } from '../InvoiceView/DataProvider'
import { AutorenewIcon } from '@toasttab/buffet-pui-icons'
import { Button } from '@toasttab/buffet-pui-buttons'
import { postConfirmEvent } from '../../api/invoices'
import { useEvent } from './EventProvider'
import classNames from 'classnames'

interface ConfirmEstimateButtonProps {
  signature?: string | null
  setValidationError?: (error: string) => void
  brandingEnabled?: boolean
}

const ConfirmEstimateButton: React.FC<ConfirmEstimateButtonProps> = ({
  signature = null,
  setValidationError = () => null,
  brandingEnabled
}) => {
  const { token } = useData()
  const { setIsConfirmed, event } = useEvent()
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(false)

  return (
    <>
      <Button
        className={classNames(
          'w-full',
          !event?.signatureRequired && 'mt-4',
          brandingEnabled &&
            '!bg-tsw-primary-color !border-tsw-primary-color ' +
              'hover:saturate-[.9] hover:brightness-90 ' +
              'tsw-font-family !text-tsw-contrasting-color'
        )}
        id='submitPayment'
        onClick={async () => {
          if (event?.signatureRequired && !signature) {
            setValidationError('A signature is required.')
            return
          }
          try {
            setLoading(true)
            await postConfirmEvent(token, signature)
            setIsConfirmed(true)
          } catch (err) {
            console.error(err)
            setError(true)
          } finally {
            setLoading(false)
          }
        }}
        disabled={loading}
        iconLeft={
          loading ? <AutorenewIcon className='animate-spin' /> : undefined
        }
      >
        {event.signatureRequired
          ? 'Accept estimate and contract'
          : 'Confirm estimate'}
      </Button>
      {error && (
        <p className='text-error'>
          Something went wrong. Reload the page and try again or reach out to
          the restaurant for assistance.
        </p>
      )}
    </>
  )
}

export default ConfirmEstimateButton
