import {
  Customer,
  MenuItemMap,
  Order,
  RestaurantBranding
} from './InvoiceTypes'

export enum EventStatus {
  /* eslint-disable no-unused-vars */
  DRAFT = 'DRAFT',
  TENTATIVE = 'TENTATIVE',
  CONFIRMED = 'CONFIRMED',
  CLOSED = 'CLOSED',
  DELETED = 'DELETED',
  REFUNDED = 'REFUNDED',
  DISQUALIFIED = 'DISQUALIFIED'
  /* eslint-enable no-unused-vars */
}

export interface FormValue {
  guid?: string
  eventTypeFormFieldGuid: string
  value: string
  formField: FormField
}

export interface FormField {
  createdDate: string
  deleted: boolean
  eventTypeGuid: string
  guid: string
  internal: Boolean
  label: string
  modifiedDate: string
  options: string[]
  priority: boolean
  required: boolean
  type: FormFieldType
  showOnLead: boolean
  requireOnLead: boolean
}

export interface Event {
  guid: string
  name: string | null
  contract?: string
  restaurantGuid: string
  eventTypeGuid: string
  invoiceGuid?: string
  draftOrderGuid?: string
  customer: Customer
  customerGuid: string
  order?: Order
  date?: string
  endDate?: string
  status: EventStatus
  estimate?: string
  formValues: FormValue[]
  signatureRequired?: boolean
  createdDate: string
  modifiedDate: string
  timeline?: EventTimeline
}

export enum FormFieldType {
  /* eslint-disable no-unused-vars */
  TEXT = 'TEXT',
  TEXT_AREA = 'TEXT_AREA',
  YES_NO = 'YES_NO',
  SELECT = 'SELECT',
  MULTI_SELECT = 'MULTI_SELECT',
  EMPLOYEE = 'EMPLOYEE',
  TIME = 'TIME',
  GUEST_COUNT = 'GUEST_COUNT'
  /* eslint-enable no-unused-vars */
}

export interface EventResponse {
  status: 'VALID' | 'INVALID' | 'EXPIRED'
  event?: Event
  menuItemMap?: MenuItemMap
  restaurantBranding?: RestaurantBranding
}

export interface PublicEventTypeResponse {
  guid: string
  name: string
  formFields: FormField[]
  restaurantBranding: RestaurantBranding
  restaurantTimeZoneId?: string
  restaurantCountry?: string
  leadTitle?: string
  leadDescription?: string
  leadHeaderImageUrl?: string
  leadWebsiteUrl?: string
  leadMinLeadTime?: number
  includeEndDate?: boolean
  leadLocationPickerEnabled?: boolean
  allowImageUpload?: boolean
  imageFieldName?: string
  imageSubtitle?: string
  showNonInternalFields?: boolean
  requireTime?: boolean
  managementSetGuid: string
}

export interface PublicLocationsResponse {
  guid: string
  branding: RestaurantBranding
  orderTypes: PublicEventTypeResponse[]
  distance: number | undefined | null
}

export interface EventTimeline {
  enabled: boolean
  entries?: EventTimelineEntry[]
}

export interface EventTimelineEntry {
  description: string
  startTime: string
  endTime?: string | undefined | null
}
