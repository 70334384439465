import React from 'react'
import { ErrorPage500 } from '@toasttab/buffet-pui-error-pages'
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary'
import classNames from 'classnames'
import { BodyBackgroundColor } from '../shared/BodyBackgroundColor'

interface MainProps {
  maxWidth?: '2xl' | '6xl' | 'none'
}

const Main: React.FC<React.PropsWithChildren<MainProps>> = ({
  maxWidth = '2xl',
  children
}) => {
  return (
    <>
      <BodyBackgroundColor colorVar={'color-gray-30'} />
      <ErrorBoundary fallback={<ErrorPage500 />}>
        <div style={{ minHeight: '100vh' }}>
          <div
            className={
              'w-full h-full flex justify-center px-6 py-4 md:p-8 transition-[padding]'
            }
            style={{ minHeight: '100vh' }}
          >
            <main
              className={classNames('w-full', {
                'max-w-6xl': maxWidth === '6xl',
                'max-w-2xl': maxWidth === '2xl'
              })}
            >
              {children}
            </main>
          </div>
        </div>
      </ErrorBoundary>
    </>
  )
}

export default Main
