import React from 'react'
import { Card, CardProps } from './Card'

interface WrapInCards extends CardProps {
  children?: Iterable<React.ReactNode>
}

export const WrapInCards: React.FC<WrapInCards> = ({
  children,
  ...cardProps
}) => {
  return (
    <>
      {React.Children.map(
        children,
        (child) => child && <Card {...cardProps}>{child}</Card>
      )}
    </>
  )
}
