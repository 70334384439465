import React, { ReactNode } from 'react'
import { useInvoice } from '../InvoiceView/InvoiceProvider'

export default function InvoiceTotalRow(props: {
  label: ReactNode
  value: ReactNode
  className?: string
}) {
  const { invoice } = useInvoice()
  const { label, value, className } = props
  return (
    <tr>
      <td
        colSpan={invoice && invoice.hideUnitInfo ? 1 : 2}
        className={`text-left pr-2 ${className}`}
      >
        {label}
      </td>
      <td className={`text-right pl-2 ${className}`}>{value}</td>
    </tr>
  )
}
