import { Button, ButtonProps } from '@toasttab/buffet-pui-buttons'
import React, { MouseEvent, useCallback, useState } from 'react'
import { AutorenewIcon, IconProps } from '@toasttab/buffet-pui-icons'

const ProgressIcon: React.FC<IconProps> = (props) => (
  <AutorenewIcon className='animate-spin text-secondary' {...props} />
)

interface Props {
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

const LoadingButton: React.FC<Omit<ButtonProps, 'onClick'> & Props> = (
  props
) => {
  const [isLoading, setLoading] = useState(false)

  const handleClick = useCallback(
    async (event: MouseEvent<HTMLButtonElement>) => {
      if (!props.onClick) {
        return
      }

      setLoading(true)
      try {
        await props.onClick(event)
      } finally {
        setLoading(false)
      }
    },
    [props]
  )

  return (
    <Button
      {...props}
      disabled={isLoading || props.disabled}
      onClick={handleClick}
      iconLeft={isLoading ? <ProgressIcon /> : props.iconLeft}
    >
      {props.children}
    </Button>
  )
}

export default LoadingButton
