import React, { useMemo } from 'react'
import classNames from 'classnames'
import { ExpandIcon } from '@toasttab/buffet-pui-icons'
import { Disclosure, useDisclosure } from '@toasttab/buffet-pui-disclosure'

interface EventPortalSectionProps {
  title: string
  className?: string
  collapsible?: boolean
  collapsedInitially?: boolean
  additionalHeaderContent?: React.ReactNode
}

export const EventPortalSection: React.FC<
  React.PropsWithChildren<EventPortalSectionProps>
> = ({
  title,
  className,
  additionalHeaderContent,
  collapsible,
  collapsedInitially,
  children
}) => {
  const elementId = useMemo<string>(
    () => title.toLowerCase().replace(/ /g, '-'),
    [title]
  )

  const EventPortalSectionHeader: React.FC = () => {
    const { isOpen, id, toggleProps } = useDisclosure()
    return (
      <header
        className={classNames(
          'flex flex-row gap-2 px-4 md:px-8 mt-8',
          collapsible && 'cursor-pointer',
          !isOpen && 'mb-6'
        )}
        onClick={collapsible ? toggleProps.onClick : undefined}
        aria-labelledby={`${id}-title`}
      >
        <h3
          id={elementId}
          className={
            'grow type-default md:type-headline-5 font-semibold tsw-header-font-family text-color-ds-action'
          }
        >
          {title}
        </h3>
        {additionalHeaderContent}
        {collapsible && (
          <div className='text-secondary'>
            <ExpandIcon
              isExpanded={isOpen}
              aria-label={isOpen ? 'Collapse' : 'Expand'}
            />
          </div>
        )}
      </header>
    )
  }

  return (
    <Disclosure initialIsOpen={!collapsedInitially}>
      <EventPortalSectionHeader />
      <Disclosure.Content className={classNames('tsw-font-family', className)}>
        {children}
      </Disclosure.Content>
    </Disclosure>
  )
}
