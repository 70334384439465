import React from 'react'
import classNames from 'classnames'

interface ThemedLayoutMainContentProps {
  className?: string
}

export const ThemedLayoutMainContent: React.FC<
  React.PropsWithChildren<ThemedLayoutMainContentProps>
> = ({ className, children }) => {
  return (
    <article
      className={classNames(
        'grow flex flex-col gap-6 gap-y-4 md:gap-y-6',
        className
      )}
    >
      {children}
    </article>
  )
}
